<mat-card appearance="outline" class="task _select"
          *ngIf="task"
          [id]="task.vid"
          [ngClass]="{
               '_done': task.isTaskRejected,
               '_disabled': disabled === true
               }">
    <div class="task__description"  (click)="onTaskInEditClick($event, 'open')">
        <div class="task__description-toolbar">
            <div class="task__number" *ngIf="!isTemplate" [innerHTML]="task.vid?.toString() | highlight : searchTerm"></div>
<!--            <div class="task__important">-->
<!--                <mat-icon class="important" *ngIf="task.flagImportance">outlined_flag</mat-icon>-->
<!--            </div>-->
            <app-task-manager-status class="task__status"
                                     [ngClass]="{'_short': !isKanban}"
                                     [disabled]="disabled"
                                     [isText]="false"
                                     [data]="task"></app-task-manager-status>
        </div>

        <div class="task__description-main">
            <div class="task__title">
                <span [innerHTML]="task.title | highlight : searchTerm"></span>
            </div>
        </div>

    </div>
    <ng-container *ngIf="canRemove">
        <mat-icon class="icon _remove" (click)="onTaskInEditClick($event,'remove')">close</mat-icon>
    </ng-container>
    <ng-container *ngIf="add">
        <mat-icon class="icon _add">add</mat-icon>
    </ng-container>
</mat-card>
