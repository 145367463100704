import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {Member} from '../../../task-manager/models/member';
import {ConfigService} from '../../../../services/config.service';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class UserComponent {

    public user: Member = null;
    @Input() public more = false;
    @Input() public title = '';
    @Input() public responsive = false;
    @Input() public small = false;

    constructor(
        private config: ConfigService,
        private cdr: ChangeDetectorRef
    ) {
    }

    @Input() set data(data: Member) {
        this.user = data;
        this.cdr.detectChanges();
    }


}
