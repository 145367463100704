import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Rights} from '../../../models/user';
import {PositionFolder} from '../models/position-folder';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';
import {UserInfoService} from '../../../services/user-info.service';
import {AuthService} from '../../../services/auth.service';
import {NotifierService} from 'gramli-angular-notifier';
import {PositionShort} from '../models/position-short';
import {Position} from '../models/position';

@Injectable({
    providedIn: 'root'
})
export class PositionsService {
    public positionCreated$ = new Subject<Position>();
    public reloadList$ = new Subject();
    public groups$ = new BehaviorSubject<PositionFolder[]>(null);
    public positions$ = new BehaviorSubject<PositionShort[]>(null);
    public positionFolders$ = new BehaviorSubject<PositionFolder[]>(null);
    public rightsList$ = new BehaviorSubject<Rights>(null);
    public openedPositionId$ = new BehaviorSubject(null);
    public openedGroup$ = new BehaviorSubject(null);
    public openedRole$ = new BehaviorSubject(null);

    constructor(
        readonly http: HttpClient,
        public config: ConfigService,
        public userInfo: UserInfoService,
        public auth: AuthService,
        public notifierService: NotifierService
    ) {
    }

    deleteGroup(id: number) {
        return this.http.delete(
            `${this.config.positionFolders}/${id}/`,
            this.auth.authorize(),
        );
    }
}
