<ng-container *ngIf="!flat_text">
    <span class="txt">
    <mat-icon class="icon" *ngIf="icon">{{icon}}</mat-icon>
    <mat-icon class="icon" *ngIf="radius_icon" [svgIcon]="radius_icon"></mat-icon>{{ text }}</span>
    <a class="link" (click)="onClick.emit()">{{ linkText }}</a>
</ng-container>

<span class="txt" *ngIf="flat_text" [ngClass]="{'_sub': subtext}">
    <mat-icon class="icon" *ngIf="icon">{{icon}}</mat-icon>
    <mat-icon class="icon" *ngIf="radius_icon" [svgIcon]="radius_icon"></mat-icon>
    <span class="txt-inner">
        {{ text }}
        <a class="link" (click)="onClick.emit()">{{ linkText }}</a>

        <span class="subtext" *ngIf="subtext">
            {{ subtext }}
        </span>
    </span>
</span>
