import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Tag} from '../../../../task-manager/models/tag';
import {TagsFormComponent} from '../tags-form/tags-form.component';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ApiBoardsService} from '../../../../task-manager/services/api-boards.service';
import {GlobalNotificationCenterService} from '../../../../../services/global-notification-center.service';
import {TagsService} from '../../../../task-manager/services/tags.service';
import {BoardsService} from '../../../../task-manager/services/boards.service';

const Color = require('color');

@Component({
    selector: 'app-tags-list',
    templateUrl: './tags-list.component.html',
    styleUrls: ['./tags-list.component.scss']
})
export class TagsListComponent implements OnInit, OnDestroy {


    @Input() public tags: Tag[] = [];
    @Input() public selectedTags: Tag[] = [];
    @Input() public boardId = null;
    @Input() public editable = false;
    @Input() public disabled = false;
    @Input() public removable = false;
    @Input() public selected = false;
    @Input() public isGroup = false;
    @Output() public readonly removeTag: EventEmitter<any> = new EventEmitter();
    @Output() public readonly addTag: EventEmitter<any> = new EventEmitter();

    public isDeleteConfirm = false;
    public tagToDelete = null;
    // Триггер смерти компонента
    private destroyed = new Subject<void>();

    constructor(
        private dialog: MatDialog,
        private api: ApiBoardsService,
        private notiService: GlobalNotificationCenterService,
        private tagsService: TagsService,
        private boardsService: BoardsService
    ) {
    }

    ngOnInit() {
        if (this.selected) {
            this.boardId = this.boardsService.openedBoardId$.getValue();
        }
    }

    editTag(tagId) {
        const data = {
            tagId,
            isGroup: this.isGroup,
            boardId: this.boardId
        };

        const dialogForm = this.dialog.open(TagsFormComponent, {data});

        dialogForm.afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe(result => {
                this.dialog.ngOnDestroy();
            });
    }

    deleteTag() {
        this.isDeleteConfirm = false;
        this.api.deleteTag(this.tagToDelete)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.tagsService.onTagRemove$.next(this.tagToDelete);
            }, err => {
                this.notiService.handleFullError(err);
            });
    }

    remove(tag) {
        const param = {
            boardId: this.boardId,
            tag
        };
        this.removeTag.next(tag);
        this.tagsService.onTagRemove$.next(param);
    }

    clickTag(tag: Tag) {
        if (this.selected) {
            if (this.isTagSelected(tag)) {
                this.selectedTags = this.selectedTags.filter(el => el.id !== tag.id);
                const data = {
                    tag,
                    boardId: this.boardId
                };
                this.tagsService.onTagRemove$.next(data);
                this.removeTag.emit(tag);
            } else {
                this.selectedTags.push(tag);
                this.tagsService.onTagSelect$.next(tag);
                this.addTag.emit(tag);
            }
        }
    }

    isTagSelected(tag: Tag) {
        if (!this.selected) {
            return false;
        }
        return this.selectedTags.some(el => el.id === tag.id);
    }

    getItemColor(colorName) {
        return this.tagsService.getTagBackground(colorName);
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    getFgColor(color: string) {
        return this.tagsService.getTagColor(color);
    }
}
