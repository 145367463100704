<div class="chats" *ngIf="!isShowLoader">
    <ng-container>
        <ul class="chats__list">
            <li class="chats-inner" [ngClass]="{'_float-date-visible': isFloatDateVisible}" (scroll)="onScroll($event)" #chatList>
                <ng-container  *ngFor="let chatMsg of chatData">

                    <app-chat-msg
                                  [id]="'id' + chatMsg.id"
                                  inViewport (inViewportAction)="onIntersection($event)"
                                  [inViewportOptions]="{ threshold: [0], rootMargin: '100%' }"
                                  class="chat"
                                  [chatMsg]="chatMsg"
                                  [currentUserId]="myId"
                                  [isAttachmentLoader]="isAttachmentLoader"
                                  [isEdit]="isEdit"
                                  [isTaskEntity]="entity == 'task_manager'"
                                  (editChatMsg)="editChatMsg($event)"
                                  (cancelChatMsg)="confirmForCancel($event)"
                                  (deleteChatMsg)="confirmForDelete($event)"
                                  (addImageToTask)="addImageToTask.emit($event)"
                                  (showReplyMessage)="showReplyMessage($event)"
                                  (dblclick)="onReplyClick(chatMsg)"
                    >
                    </app-chat-msg>
                </ng-container>
            </li>
        </ul>
        <span *ngIf="chatData && !chatData.length" class="chats__empty">
             Начните обсуждение
        </span>

        <form class="form"
              #messageForm="ngForm"
              (ngSubmit)="onSend()"
              (paste)="onPaste($event)">

            <ng-container *ngIf="isEdit">
                <app-chat-reply [reply]="editableMessage" [edit]="true" class="reply"></app-chat-reply>
                <mat-icon class="reply-remove" (click)="closeEditMessage()">close</mat-icon>
            </ng-container>

            <ng-container *ngIf="reply">
                <app-chat-reply [reply]="reply" class="reply"></app-chat-reply>
                <mat-icon class="reply-remove" (click)="clearReply()">close</mat-icon>
            </ng-container>

            <div class="send-preview__queue">
                <ng-container *ngFor="let previewMsg of previewQueue">
                    <div class="send-preview">
                        <ng-container *ngIf="previewMsg.type === 'image'">
                            <img class="send-preview__image" [src]="previewMsg?.imgSrc" />
                        </ng-container>
                        <ng-container *ngIf="previewMsg.type !== 'image'">
                            <img class="send-preview__image" src="/assets/file_extension/file.png" />
                        </ng-container>

                        <div class="send-preview__header">
                            <div class="send-preview__filename">{{previewMsg.fileName}}</div>
                        </div>

                        <button mat-icon-button class="send-preview__delete" (click)="removePreview(previewMsg.id)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </ng-container>
            </div>

            <div class="form__inner">
                <button class="button attach-btn" mat-icon-button type="button" [ngClass]="{'_hidden': isEdit}">
                    <mat-icon class="material-icons icon" svgIcon="radius_files" [matMenuTriggerFor]="menu">
                    </mat-icon>
                </button>

                <mat-menu #menu="matMenu" class="more-menu">
                    <a class="link more-menu__link" (click)="onAddTaskClick()" *ngIf="documentEntity !== 'payment_application'">
                        <mat-icon class="icon _add" svgIcon="radius_boards_bold"></mat-icon>
                        Ссылка на задачу
                    </a>
                    <label class="link more-menu__link user__avatar-label _empty fileupload"
                           *ngIf="!avatar"
                           [matTooltip]="chatService.attachmentsDraft$.value ? 'Дождитесь отправки предыдущего медиафайла' : ''"
                           [ngClass]="{'_disable': chatService.attachmentsDraft$.value}">
                        <input type="file" (change)="onChangePhoto($event)" #fotoInput multiple
                               class="user__avatar-input" accept="image/*, video/*" [disabled]="chatService.attachmentsDraft$.value">
                        <mat-icon class="icon _add" svgIcon="radius_insert_image">
                        </mat-icon>
                        Фото/видео
                    </label>

                    <label class="link more-menu__link user__avatar-label _empty fileupload"
                           *ngIf="!avatar"
                           [matTooltip]="chatService.attachmentsDraft$.value ? 'Дождитесь отправки предыдущего файла' : ''"
                           [ngClass]="{'_disable': chatService.attachmentsDraft$.value}"
                    >
                        <input type="file" #fileInput (change)="onChangeFile($event)" multiple
                               class="user__avatar-input" [disabled]="chatService.attachmentsDraft$.value">
                        <mat-icon class="icon _add" svgIcon="radius_insert_file">
                        </mat-icon>
                        Файл
                    </label>
                </mat-menu>

                <label class="label textarea-label">
                   <textarea class="input textarea"
                             #input id="msgInput" focus  matTooltip="Заполните это поле"
                             [(ngModel)]="message" name="message" placeholder="Сообщение..."
                             (keydown)="submitOnEnter($event, messageForm)"
                             (keyup)="checkKeyUp($event)"
                             (input)="textareaResize()"> </textarea>
                </label>

                <button class="button" mat-icon-button type="button" (click)="toggleEmojiPicker(input)">
                    <mat-icon class="material-icons" [svgIcon]="'radius_emoji'"></mat-icon>
                </button>
                <ng-container *ngIf="isEmojiPickerShown">
                    <div class="emoji-picker-bg-overlay" (click)="toggleEmojiPicker(input)"></div>
                    <emoji-mart [isNative]="true" [i18n]="EMOJI_I18N" class="emoji-picker" [showPreview]="false" (emojiClick)="addEmoji($event)" (close)="restoreFocus(input)"></emoji-mart>
                </ng-container>
                <button class="send" [ngClass]="{'_active': isSendBtnActive()}" mat-icon-button type="submit" (click)="messageForm.ngSubmit.emit()"
                        [disabled]="!isSendBtnActive()">
                    <mat-icon class="icon _send" *ngIf="!isEdit">
                        send
                    </mat-icon>

                    <mat-icon class="icon _done" *ngIf="isEdit" [svgIcon]="'radius_done'"></mat-icon>
                </button>
            </div>

            <app-chat-users-link [text]="message"
                                 (addUserLink)="onAddUserLink($event)"
                                 (membersLength)="onChangeMembersLink($event)"></app-chat-users-link>

        </form>
    </ng-container>

    <span *ngIf="isNeedRestart" class="chats__empty">
        Возникла проблема с соединением, попробуйте перезагрузить страницу
    </span>

</div>

<app-spinner [isSpinnerOn]="isShowLoader"></app-spinner>

