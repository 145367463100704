import {Component, Inject, OnInit} from '@angular/core';
import {FiltersService} from '../../services/filters.service';
import {BoardsService} from '../../services/boards.service';
import {UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfigBoardsService} from '../../services/config-boards.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
    selector: 'app-task-create-helper',
    templateUrl: './task-create-helper.component.html',
    styleUrls: ['./task-create-helper.component.scss']
})
export class TaskCreateHelperComponent implements OnInit {
    // Модель формы
    public data = {};
    public formGroup: UntypedFormGroup = null;
    public title = 'Добавить следующие параметры при создании задачи?';
    public importance = this.config.IMPORTANCE_TYPE;
    public sharedBoard = false;

    constructor(
        private filtersService: FiltersService,
        private boardsService: BoardsService,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public dataMore: any,
        public config: ConfigBoardsService,
        public auth: AuthService
    ) {

    }

    ngOnInit(): void {
        this.formGroup = new UntypedFormGroup({});

        if (this.dataMore && this.dataMore['sharedBoard']) {
            this.sharedBoard = true;
            this.title = 'Добавить следующих участников при добавлении доски?';

            this.data['performers'] = this.dataMore.performers.map(el => {
                el['selected'] = true;
                return el;
            });
            this.data['performers'] = this.data['performers'].filter(el => el && el.id && el.id !== 0);

            if (this.dataMore['responsible']) {
                this.dataMore['responsible']['selected'] = true;
                this.data['responsible'] = this.dataMore['responsible'];
                this.data['performers'].unshift(this.dataMore['responsible'])
            }

            this.data['responsible'] = this.dataMore['responsible'] ? this.dataMore['responsible'] : null;

            this.data['observers'] = this.dataMore.observers.map(el => {
                el['selected'] = true;
                return el;
            });
            this.data['observers'] = this.data['observers']?.filter(el => el && el.id && el.id !== 0);

            if (this.data['performers'] && this.data['performers']?.length &&
                this.data['observers']?.length && this.data['observers'] ) {
                this.data['observers'] = this.data['observers']?.filter(el => !this.data['performers']?.some(p => p.id == el.id));
            }
            return;
        }


        const filters = this.filtersService.tasksFilters$.value;
        if (filters) {
            this.data['performers'] = filters.performers.map(id => {
                const user = this.filtersService.getPerformerById(id);
                if (user) {
                    user['selected'] = true;
                    return user;
                }
            });

            this.data['performers'] = this.data['performers'].filter(el => el && el.id && el.id !== 0);
        } else {
            this.data['performers'] = [];
        }

        this.data['responsible'] = this.data['performers'] && this.data['performers'].length === 1 ?
            this.data['performers'][0] : null;

        if (filters) {
            this.data['observers'] = filters.observers.map(id => {
                const user = this.filtersService.getObserversById(id);
                if (user) {
                    user['selected'] = true;
                    return user;
                }
            });
        } else {
            this.data['observers'] = [];
        }

        this.data['observers'] = this.data['observers'].filter(el => el && el.id && el.id !== 0);

        if (this.data['performers'] && this.data['performers']?.length &&
            this.data['observers']?.length && this.data['observers'] ) {
            this.data['observers'] = this.data['observers'].filter(el => !this.data['performers']?.some(p => p.id == el.id));
        }

        if (filters && filters.importancePriority && filters.importancePriority.length) {
            this.data['importancePriority'] = [];
            filters.importancePriority.forEach(el => {
                this.data['importancePriority'].push(this.config.getImportanceById(el));
            });

            this.data['importancePriority'][0].selected = true;
        }

        this.data['groups'] = [];
        if (filters && filters.groups.length) {
            const board = this.boardsService.boardSelected$.value;
            const groups = board.tags.filter(g => g.taskGroup);
            this.data['groups'] = filters.groups.map((id, index) => {
                const g = groups.find(el => el.id === id);
                if (g) {
                    g['selected'] = index === 0;
                }
                return g;
            });

            this.data['groups'] = this.data['groups'].filter(el => el && el.id !== 0);

            this.data['group'] = this.data['groups'][0];
            this.data['groups'].forEach(s => s['selected'] = false);
            this.data['groups'][0]['selected'] = true;
        }

        this.data['sprints'] = [];
        if (filters && filters.sprints.length) {
            this.data['sprints'] = filters.sprints.map((id, index) => {
                const s = this.filtersService.getSprintById(id);
                s['selected'] = index === 0;

                return s;
            });

            this.data['sprint'] = this.data['sprints'][0];
        }

        this.data['tags'] = [];
        if (filters && filters.tags.length) {
            this.data['tags'] = filters.tags.map(id => {
                const tag = this.filtersService.getTagById(id);
                tag['selected'] = true;
                return tag;
            });

            this.data['tags'] = this.data['tags'].filter(el => el.id !== 0);
        }

        if (this.dataMore) {
            if (this.dataMore['performers']) {
                this.dataMore['performers'].forEach(el => {
                    if (!this.data['performers']?.some(p => p.id === el.id)) {
                        el['selected'] = true;
                        this.data['performers'].push(el);
                    }
                });
            }

            if (this.dataMore['observers']) {
                this.dataMore['observers'].forEach(el => {
                    if (!this.data['observers']?.some(p => p.id === el.id)) {
                        el['selected'] = true;
                        this.data['observers'].push(el);
                    }
                });
            }

            if (this.dataMore['observers']) {
                this.dataMore['observers'].forEach(el => {
                    if (!this.data['observers']?.some(p => p.id === el.id)) {
                        el['selected'] = true;
                        this.data['observers'].push(el);
                    }
                });
            }

            if (this.dataMore['sprint'] && !this.data['sprints']?.some(el => el.id === this.dataMore['sprint']['id'])) {
                this.data['sprints'].push(this.dataMore['sprint']);
                this.dataMore['sprint']['selected']= !this.data['sprints']?.length;
                this.data['sprint'] = this.data['sprints'][0];
            }

            const b = this.dataMore['boards'].find(el => el.id > 0);
            const group = b?.tags.find(el => el.taskGroup);
            const tags = b?.tags.filter(el => !el.taskGroup);
            if (group && !this.data['groups']?.some(el => el.id === group['id'])) {
                group['selected'] = !this.data['groups']?.length;
                this.data['groups'].push(group);
                this.data['group'] = this.data['groups'][0];
            }

            if (tags && tags.length) {
                tags.forEach(t => {
                    if (!this.data['tags']?.some(el => el.id === t.id)) {
                        t['selected'] = true;
                        this.data['tags'].push(t);
                    }
                });
            }

            if (this.dataMore['sprint'] && !this.data['sprints']?.some(el => el.id === this.dataMore['sprint']['id'])) {
                this.dataMore['sprint']['selected'] = !this.data['sprints']?.length;
                this.data['sprints'].push(this.dataMore['sprint']);
            }

            if (this.dataMore.links.length || this.dataMore.files.length) {
                this.data['adds'] = {
                    selected: true,
                    links: this.dataMore.links,
                    files: this.dataMore.files,
                };
            }
        }

        if (!this.data['groups'].length &&
            !this.data['sprints'].length &&
            !this.data['tags'].length &&
            !this.data['observers'].length &&
            !this.data['performers'].length &&
            !this.data['responsible'] && !this.dataMore) {
            this.close();
        }
    }

    changeSprint(evt, sprint) {
        this.data['sprints'].forEach(s => s['selected'] = false);
        if (evt.checked) {
            sprint['selected'] = true;
            this.data['sprint'] = sprint;
        } else {
            this.data['group'] = null;
        }
    }

    changeImportance(evt, item) {
        this.data['importancePriority'].forEach(s => s['selected'] = false);
        if (evt.checked) {
            item['selected'] = true;
        }
    }

    changeGroup(evt, group) {
        this.data['groups'].forEach(s => s['selected'] = false);
        if (evt.checked) {
            group['selected'] = true;
            this.data['group'] = group;
        } else {
            this.data['group'] = null;
        }
    }

    close() {
        this.dialogRef.close();
    }

    reset() {
        this.close();
    }

    get isSelected() {
        const arr = Object.values(this.data);
        let val = false;

        arr.forEach(el => {
            if (el && el['selected']) {
                val = true;
            }

            if (Array.isArray(el) && el.some(i => i['selected'])) {
                val = true;
            }
        });

        return val;
    }

    onSubmit() {
        if (this.isSelected) {
            this.dialogRef.close(this.data);
        }
    }

    onUsersChange(evt, user) {
        if (!user['selected'] && this.data['responsible'] && this.data['responsible'].id === user.id ) {
            this.data['responsible'] = null;
        }
    }

    onResponsibleClick(evt, user) {
        if (!user['selected']) {
            return false;
        }

        evt.stopPropagation();
        evt.preventDefault();

        this.data['responsible'] = this.data['responsible']?.id ===  user.id ? null: user;
    }

}
