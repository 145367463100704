import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class ConfirmActionButton {
    action: string;
    name: string;
    color: string;
    autofocus: boolean;
    inputRequired: boolean;
}
@Component({
    selector: 'app-prompt-action',
    templateUrl: './prompt-smart.component.html',
    styleUrls: ['./prompt-smart.component.scss']
})

export class PromptSmartComponent implements OnInit {

    input: any = null; // TODO add interface
    buttons: ConfirmActionButton[] = null;
    link: string = null;
    title = '';
    checkbox = null;
    checked = false;
    description = null;
    subtitle = null;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.buttons = data.buttons;
        this.input = data.input;
        this.title = data.title;
        this.checkbox = data.checkbox ? data.checkbox : null;
        this.link = data.link ? data.link : null;
        this.description = data?.description;
        this.subtitle = data?.subtitle;
    }

    ngOnInit() {
    }

    close() {
        this.dialogRef.close();
    }

    onLinkClick(evt) {
        evt.preventDefault();
        const action = 'link';
        this.dialogRef.close(action);
    }

    action(buttonAction) {
        if (buttonAction === 'save' && !this.input.value.trimEnd().length) {
            return false;
        }

        const dataActions = {
            text: this.input.value.trimEnd(),
            buttonAction
        };
        this.dialogRef.close(dataActions);
    }

}

