import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-file-edit-form',
  templateUrl: './file-edit-form.component.html',
  styleUrls: ['../link-edit-form/link-edit-form.component.scss']
})
export class FileEditFormComponent implements OnInit {

    public formGroup: UntypedFormGroup = null;
    public title = 'Переименовать вложение';
    public dot = null;
    public type = null;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data
    ) { }

    ngOnInit(): void {
        this.dot = this.data.name.lastIndexOf('.');
        const name = this.data.name.slice(0, this.dot);
        this.type = this.data.name.slice(this.dot, this.data.name.length);
        this.formGroup = new UntypedFormGroup({
            name: new UntypedFormControl(name)
        });
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        if (this.formGroup.controls['name'].value.trim() !== '') {
            this.dialogRef.close({name:this.formGroup.controls['name'].value + this.type});
        }
    }

}
