import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {Board} from '../../../../task-manager/models/board';
import {TasksService} from '../../../../task-manager/services/tasks.service';
import {BoardsService} from '../../../../task-manager/services/boards.service';
import {TagsSelectComponent} from '../../tags/tags-select/tags-select.component';
import {MatDialog} from '@angular/material/dialog';
import {TagsService} from '../../../../task-manager/services/tags.service';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from '../../../../../services/auth.service';
import {GroupSelectComponent} from '../../groups/group-select/group-select.component';
import {Group} from '../../../../task-manager/models/group';
import {BoardsAddComponent} from '../../boards/boards-add/boards-add.component';
import {SharedService} from '../../../services/shared.service';

@Component({
    selector: 'app-boards-for-meet-list',
    templateUrl: './boards-list.component.html',
    styleUrls: ['./boards-list.component.scss']
})
export class BoardsForMeetListComponent implements OnInit, OnDestroy {
    @Input() public disabled = false;
    @Input() public tagsDisabled = false;
    @Input() public boards: Board[] = [];
    @Input() public isTemplate = false;
    @Input() public onlyOne = false;
    // Триггер смерти компонента
    private destroyed = new Subject<void>();
    public boardId = null;

    @Output() public groupAdd = new EventEmitter<any>();
    @Output() public groupDel = new EventEmitter<any>();
    @Output() public boardAdd = new EventEmitter<any>();
    @Output() public boardDel = new EventEmitter<any>();
    @Output() public boardsUpdate = new EventEmitter<any>();

    constructor(
        private taskService: TasksService,
        private tagsService: TagsService,
        private boardsService: BoardsService,
        private dialog: MatDialog,
        private auth: AuthService,
        private sharedService: SharedService,
    ) {
    }

    getTags(board: Board) {
        return board.tags.filter(tag => !tag.taskGroup);
    }

    getGroup(board: Board) {
        return board.tags.find(tag => tag.taskGroup);
    }

    onBoardGroupRemove(group: Group, boardId) {
        const param = {
            boardId,
            tag: group
        };
        this.groupDel.emit(param);
    }

    get canDelete() {
        const countOfBoards = this.boards.filter(el => el.id > 0).length;
        const personalBoard = this.boards.find(el => el.id === -this.auth.auth.id);
        return countOfBoards > 1 || personalBoard || this.isTemplate;
    }

    ngOnInit() {

    }

    // isTagsDisabled(board) {
    //     const b = this.boardsService.boards$.getValue().find(el => el.id === board.id);
    //     return b ? !b.view.tags : false;
    // }

    onBoardMainChange(b: Board) {
        if (this.disabled) {
            return false;
        }

        this.boards.forEach(el => el.is_main = false);
        b.is_main = true;

        this.boardsUpdate.emit(this.boards);
        //this.taskService.onBoardsUpdate$.next(this.boards);
    }

    getboardName(board) {
        return this.boardsService.getBoardName(board, this.auth.currentUser$.getValue());
    }

    isBoardSelected(board: Board) {
        const sel = this.boardsService.boardSelected$.getValue();

        return sel && (board.id === sel.id);
    }

    deleteBoard(board) {
        this.boardDel.emit(board);
    }

    getBoardColor(colorName) {
        return this.boardsService.getBoardColor(colorName);
    }

    openGroupAdd(board) {
        const currentBoard = this.boardsService.boards$.value.find(b => b.id == board.id);
        const dialogForm = this.dialog.open(GroupSelectComponent, {
            width: '500px',
            data: {
                groups: currentBoard.tags.filter(el => el.taskGroup),
                groupSelected: board.tags ? board.tags[0] : null,
                currentBoard: currentBoard,
                disableClose: this.sharedService.openTaskLearning$.value
            }
        });

        dialogForm.afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe(result => {
                this.groupAdd.emit(result);
            });
    }

    openFormAdd(boardId, selectedTags) {
        this.boardId = boardId;
        this.boardsService.openedBoardId$.next(this.boardId);
        const board = this.boardsService.boards$.getValue().find(el => el.id === this.boardId);
        const tags = board ? board.tags.filter(tag => !tag.taskGroup) : [];
        const dialogForm = this.dialog.open(TagsSelectComponent, {
            data: {
                tags,
                selectedTags,
                isCanCreate: this.boardsService.checkMembershipOfBoard(board, this.auth.currentUser$.getValue())}
        });

        dialogForm.afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe(result => {
                this.boardsService.openedBoardId$.next(null);
            });
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    editBoard(item: Board) {
        const dialogForm = this.dialog.open(BoardsAddComponent, {
            data: {
                selected: this.boards
            }
        });

        dialogForm.afterClosed().pipe(takeUntil(this.destroyed))
            .subscribe(board => {
                if (board) {
                    this.boardAdd.emit(board);
                }
            });
    }
}
