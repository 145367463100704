<section class="dialog">
    <header class="dialog__header">
        <div class="title-block">
            <mat-icon class="icon prev" *ngIf="step > 1" (click)="prevStep()">arrow_back</mat-icon>
            <div class="title">
                <h3 class="dialog__title">{{ title }}</h3>
                <p>Шаг {{step}}</p>
            </div>
        </div>

        <mat-icon class="close" (click)="close()">close</mat-icon>
    </header>


    <div class="content kit" [ngClass]="{'_step1': step === 1}" *ngIf="isLoaded">
        <div class="col__inner" *ngIf="step === 1">
            <app-search-mat #search
                             (textChange)="onSearchInput($event)"
                             [kitView]="true"
                             placeholderTxt="Поиск подразделений"
                             class="search"
            ></app-search-mat>

            <app-subdivision-list class="list"
                                  *ngIf="subdivisionsTree"
                                  [searchStr]='searchStr'
                                  [selectedSubdivisions]='selectedSubdivisions'
                                  [disabled]="true"
                                  [subdivisions]="subdivisionsTree"
                                  [sortedSubdivisions]="sortedSubdivisions"
                                  (onSelectedChanges)="nextStep($event)"
            ></app-subdivision-list>
        </div>

        <div class="col__inner" *ngIf="step === 2">
            <app-subdivision-list-item
                    class="selected-subdivision"
                    [rating]="false"
                    [selectable]="false"
                    [data]="selectedSubdivision">
            </app-subdivision-list-item>

            <div class="box">
                <h4 class="sidebar-card__section-subtitle">Руководитель подразделения</h4>

                <ng-container *ngIf="managers.length">
                    <div class="slot" *ngFor="let slot of managers, let index = index">
                        <app-slot [position]="slot.position"
                                  [user]="slot.user"
                                  [isRole]="slot.is_role"
                                  (click)="chooseSlot(slot)"
                                  [ngClass]="{'_same': managers[index-1] && slot.position.id === managers[index-1].position.id,
                               '_selected': slot === chosenSlot}"></app-slot>
                    </div>

                    <a class="link" (click)="addMaster()">
                        <mat-icon class="icon">add</mat-icon>
                        Добавить штатную единицу</a>
                </ng-container>

                <app-empty-list-helper *ngIf="!managers.length"
                                       (onClick)="addMaster()"
                                       icon="person_add"
                                       text="Пока нет свободных штатных единиц"
                                       linkText="Добавить штатную единицу"></app-empty-list-helper>

            </div>

            <div class="box">
                <h4 class="sidebar-card__section-subtitle">Сотрудники подразделения</h4>
                <ng-container *ngIf="employees.length">
                    <div class="slot" *ngFor="let slot of employees, let index = index">
                        <app-slot [position]="slot.position"
                                  [user]="slot.user"
                                  [isRole]="slot.is_role"
                                  (click)="chooseSlot(slot)"
                                  [ngClass]="{'_same': employees[index-1] && slot.position.id === employees[index-1].position.id,
                                            '_selected': slot === chosenSlot}"></app-slot>
                    </div>
                    <a class="link" (click)="addSlave()">
                        <mat-icon class="icon">add</mat-icon>
                        Добавить штатную единицу</a>
                </ng-container>

                <app-empty-list-helper *ngIf="!employees.length"
                                       (onClick)="addSlave()"
                                       icon="person_add"
                                       text="Пока нет свободных штатных единиц"
                                       linkText="Добавить штатную единицу"></app-empty-list-helper>
            </div>
        </div>
    </div>

    <footer class="dialog__footer" *ngIf="step === 2">
        <app-button class="button" type="reset" buttonType="outlined" title="Отменить" (click)="close()"></app-button>
        <app-button class="button" type="submit" (click)="submit()" title="Сохранить"></app-button>
    </footer>

    <footer class="dialog__footer" *ngIf="step === 1">
        <button class="add-subdivision" (click)="addSubdivision()">
            <mat-icon class="icon">add</mat-icon>
            Добавить подразделение
        </button>
    </footer>

    <app-spinner class="loader" [isSpinnerOn]="true" *ngIf="!isLoaded"></app-spinner>
</section>