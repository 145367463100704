import {EmojiData} from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {BehaviorSubject, Subject, SubscriptionLike} from 'rxjs';
import {JSON} from 'ta-json';

import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';

import {debounceTime, takeUntil} from 'rxjs/operators';

import {ChatRoomsItem} from '../../../../models/ChatRoomsItem';
import {ChatMessage} from '../../../../models/chat';
import {File} from '../../../../models/file';
import {ApiService} from '../../../../services/api.service';
import {AppsService} from '../../../../services/apps.service';
import {AuthService} from '../../../../services/auth.service';
import {ChatsService} from '../../../../services/chats.service';
import {ConfigService} from '../../../../services/config.service';
import {WebsocketService,} from '../../../../services/webSocket.api';
import {hashCode} from '../../../../utils/commons';
import EMOJI_I18N from '../../../../utils/emoji';
import {NotificationCenterService} from '../../../notification-center/services/notification-center.service';
import {SubtasksFormComponent} from '../../../task-manager/components/task/task-form-edit/subtasks-form/subtasks-form.component';
import {NavService} from '../../../task-manager/services/nav.service';
import {TasksService} from '../../../task-manager/services/tasks.service';
import {ConfirmSmartComponent} from '../confirm-smart/confirm-smart.component';
import {ChatMembersComponent} from './chat-members/chat-members.component';
import moment from 'moment-timezone';

@Directive({
    selector: '[focus]',
})
export class FocusDirective implements AfterViewInit {
    constructor(private element: ElementRef) {}

    ngAfterViewInit() {
        this.element.nativeElement.focus();
    }
}

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
})
export class ChatComponent
    implements OnInit, AfterViewChecked, OnDestroy, OnChanges
{
    protected readonly EMOJI_I18N = EMOJI_I18N;

    @Output() messageInput = new EventEmitter<string>();

    /** Строка списка чатов */
    @Input() data: ChatRoomsItem;
    @Input() itemId: string;
    @Input() entity: string;
    @Input() isMembersAddNeed = true;
    @Input() isChatroomMenu = false;
    @Output() addImageToTask: EventEmitter<ChatMessage> = new EventEmitter();
    /** Список всех сообщений чата */
    public chatData: ChatMessage[];
    /** id текущего пользователя */
    public myId = -1;
    /** данные загружены */
    public isLoaded = false;
    public isShowLoader = true;
    public message = '';
    /** Элемент списка сообщений в чате */
    @ViewChild('chatList') chatList: ElementRef;
    @ViewChild('input') input: ElementRef;
    /** Отображаемые сообщения в чатах с.. по.. */
    public firstMsg = -1;
    public lastMsg = 0;
    /** id контракта, внутри которого запущен чат */
    public documentId: string;
    public documentEntity: string;
    public isConnected = false;
    public isOnline = false;
    public isNeedRestart = false;
    /** триггер завершения подписок */
    private destroyed = new Subject<void>();
    private checkFullConnected$ = new BehaviorSubject(null);
    /** Нужно ли соскролить вниз */
    private doScroll = false;
    /** ЗАжат ли шифт */
    public isShiftKeyDown = false;
    public isControlKeyDown = false;
    /** Редактируемое сообщение */
    public editableMessage = null;
    /** Сообщение на удаление */
    public deleteMsg = null;
    /** Происходит ли редактирование */
    public isEdit = false;
    public websocketSub: SubscriptionLike;

    readonly PDF_MIME = 'application/pdf';
    readonly PDF_FORMAT = '.pdf';

    public offset = 0;
    public scrollAvailable = true;

    fileToUpload = null;
    msgInUpload: ChatMessage = null;

    isEmojiPickerShown = false;
    public enterDisabled = false;

    public reply = null;
    // Тут храним сообщение куда надо скролить
    public messageToScroll = null;

    /*
     * debounce загрузки истории, чтоб несколько запросов одновременно не стучались
     */
    private isMsgFetching = false;

    @ViewChild('fileInput') fileInput: ElementRef;
    @ViewChild('fotoInput') fotoInput: ElementRef;
    floatDate = null;

    yesterday = moment().subtract(1, 'day');
    today = moment();
    private animationTimer = null;

    previewQueue = [];

    constructor(
        private chatService: ChatsService,
        public notiService: NotificationCenterService,
        public config: ConfigService,
        private currentUser: AuthService,
        private wsService: WebsocketService,
        private chatsApi: ChatsService,
        private router: Router,
        private appsService: AppsService,
        private api: ApiService,
        private chatsService: ChatsService,
        public dialog: MatDialog,
        private nav: NavService,
        private task: TasksService,
        private renderer: Renderer2,
        private taskService: TasksService,
    ) {}

    ngOnInit() {
        this.isNeedRestart = false;
        this.isConnected = true;
        this.isOnline = true;
        this.wsService.connected$
            .pipe(takeUntil(this.destroyed))
            .subscribe(connected => {
                this.isConnected = connected;

                this.checkFullConnected$.next(true);
            });

        this.wsService.onLine$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.isOnline = res;

                this.checkFullConnected$.next(true);
            });

        this.chatService.makeFakeMsg$
            .pipe(takeUntil(this.destroyed))
            .subscribe((chatData) => {
                if (
                    chatData['documentEntity'] == this.documentEntity &&
                    chatData['documentId']
                ) {
                    this.makeFakeMsg(chatData['msg']);
                    this.getScrollBarWidth();
                }
            });

        this.chatService.replyMsg$
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                this.reply = res;
                 if (res) {
                     this.editableMessage = null;
                     setTimeout(() => {
                         this.input.nativeElement.focus();
                         this.textareaResize();
                         this.getScrollBarWidth();
                     }, 0);
                 }
            });

        this.chatService.splicePlaceholderMsg$
            .pipe(takeUntil(this.destroyed))
            .subscribe((chatDataObj: ChatMessage[]) => {
                const srcMsg = chatDataObj[0];
                const destMsg = chatDataObj[1];
                if (
                    srcMsg['documentEntity'] == this.documentEntity &&
                    srcMsg['documentId']
                ) {
                    // находим заглушку
                    const idx = this.chatData.findIndex(
                        (el) => el.fileUploading && el.msgUid == srcMsg.msgUid,
                    );

                    if (idx >= 0) {
                        // удаляем её
                        this.chatData.splice(idx, 1);
                        //this.chatData[idx].message = 'deleted';
                    }

                    this.fotoInput.nativeElement.value = '';
                    this.fileInput.nativeElement.value = '';

                    this.textareaResize();
                    this.getScrollBarWidth();
                }
            });

        this.chatService.resetUpload$
            .pipe(takeUntil(this.destroyed))
            .subscribe((chatData) => {
                if (
                    chatData['documentEntity'] == this.documentEntity &&
                    chatData['documentId']
                ) {
                    this.chatData.splice(0, 1);
                    this.getScrollBarWidth();
                }
            });

        this.chatService.updateNotify$
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                if (res && res['objectId'] == this.itemId) {
                    this.getChatHistory();
                }
            });

        this.task.saveMessage$
            .pipe(debounceTime(500))
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                if (this.input) {
                    localStorage.setItem(
                        'savedChatMessage' +
                            +this.currentUser.currentUser$.getValue().id +
                            this.documentId +
                            this.documentEntity,
                        JSON.stringify(this.input.nativeElement.value),
                    );
                }
            });

        this.checkFullConnected$
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                if (res) {
                    this.offset = 0;
                    this.checkFullConnected();
                    this.checkFullConnected$.next(null);
                }
            });

        this.chatsService.onAddUserClick$
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                this.onMembersDialogOpen();
            });

        this.chatsService.onLeaveChatClick$
            .pipe(takeUntil(this.destroyed), debounceTime(100))
            .subscribe((res) => {
                this.chatsApi
                    .deleteChatRoom(
                        this.documentId,
                        this.documentEntity,
                        this.currentUser.auth.apiKey,
                    )
                    .pipe(takeUntil(this.destroyed))
                    .subscribe((res) => {
                        this.notiService.handleSuccess('Вы покинули чат');
                        this.chatsService.deleteChat$.next(this.documentId);
                        this.chatsService.closeChat$.next(true);
                    });
            });

        this.chatsService.onChatNotifyClick$
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                this.changeChatNotify(res);
            });

        this.textareaResize();

        const messageJSON = localStorage.getItem(
            'savedChatMessage' +
            +this.currentUser.currentUser$.getValue().id +
            this.documentId +
            this.documentEntity,
        );

        if (messageJSON) {
            this.message = JSON.parse<string>(messageJSON);
        }
    }

    onChangeMembersLink(param) {
        this.enterDisabled = !!param;
    }

    isPdf(file) {
        return (
            file.mime === this.PDF_MIME &&
            file.fileName.search(this.PDF_FORMAT) > 0
        );
    }

    changeChatNotify(param) {
        const data = {
            entity_type: this.entity,
            entity_id: this.itemId,
            notify: param,
        };

        this.api
            .changeChatNotify(data)
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                res['objectId'] = this.documentId;
                this.chatsApi.updateNotify$.next(res);
            });
    }

    onAddUserLink(txt) {
        this.message = txt;
        this.restoreFocus(this.input.nativeElement);
    }

    onAddTaskClick() {
        const dialogData = {
            boardId: null,
            taskId: null,
            selected: null,
            title: 'Выбрать задачу',
            multiple: false,
            allowCreate: false,
        };

        const dialogForm = this.dialog.open(SubtasksFormComponent, {
            disableClose: true,
            data: dialogData,
            restoreFocus: false,
        });

        dialogForm
            .afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe((result) => {
                if (result && result.length) {
                    this.sendTaskToChat(result[0]['vid']);
                }
            });
    }

    checkFullConnected() {
        const saved = localStorage.getItem(
            'savedChatData' +
                +this.currentUser.currentUser$.getValue().id +
                this.documentId +
                this.documentEntity,
        );

        if (saved) {
            this.isShowLoader = false;
            this.myId = this.currentUser.auth.id;
            const arr = JSON.parse(saved);
            if (arr && typeof arr == 'string') {
                const messages = Array.from(arr);
                this.chatData = [];
                messages.forEach((msg) =>
                    this.chatData.unshift(
                        JSON.deserialize<ChatMessage>(msg, ChatMessage),
                    ),
                );
                ChatMessage.transformChatData(this.chatData);

                // При загрузке истории устанавливает первое и последнее сообщение
                this.firstMsg = this.chatData.length - 20;
                if (this.firstMsg < 0) {
                    this.firstMsg = 0;
                }

                this.lastMsg = this.chatData.length;

                this.doScroll = true;
                this.isShowLoader = false;
            }
        }

        if (this.isConnected && this.isOnline) {
            this.getChatHistory();
        }

        this.getSavedMessages();
    }
    checkTaskLinkInMessage(str) {
        let plainText = str;
        let newStr;
        // URLs starting with http://, https://, or ftp://
        let replacePattern1 = /(\b(https?|ftp):\/\/([-A-Za-z.]?)+myradius\.ru\/apps\/\b(boards|dashboard|boards\/noticeable)\/tasks\/[0-9]+)/gim;
        let replacePattern2 = /(\b(https?|ftp):\/\/([-A-Za-z.]?)+myradius\.ru\/apps\/\b(boards|dashboard|boards\/noticeable)\/tasks\/)/gim;
        newStr = plainText.replace(replacePattern1, '')

        const array = [...str.matchAll(replacePattern1)];

        if (array && array.length) {
            array.forEach(el => {
                const taskId = el[0].replace(replacePattern2, '');

                if (taskId) {
                    this.sendTaskToChat(taskId);
                }
            })
        }

        return newStr.trim();
    }

    onSend() {
        if (this.previewQueue.length > 0) {

            if (this.previewQueue.length > 1) {
                this.sendChatMessage(this.message);
            }
            
            for (let i = 0; i < this.previewQueue.length; i++) {
                const previewMsg = this.previewQueue[i];

                const file = previewMsg.uploadData.get('upload');
                let msg = '';
                if (this.previewQueue.length === 1) {
                    msg = this.message;
                }
                const fakeMsg = this.generateUploadMsg(file, msg);
                previewMsg.msgUid = fakeMsg.msg_uid;
                previewMsg.message = msg;

                this.chatService.uploadFile(previewMsg);
            }

            // очищаем всё
            this.message = '';
            this.previewQueue = [];
        } else {
            this.sendChatMessage(this.message);
        }
    }

    /**Отправка или редактирование сообщения */
    sendChatMessage(message) {
        message = this.checkTaskLinkInMessage(message);

        if (!message.length) {
            this.message = '';
            return false;
        }

        if (!this.isEdit) {
            this.sendMessage(message);
        } else {
            const tags = this.generateTags(message);

            this.chatsApi
                .editChatMessage(
                    this.editableMessage.id,
                    this.currentUser.auth.apiKey,
                    message,
                    tags,
                    this.editableMessage
                )
                .subscribe(
                    (res) => {},
                    (err) => {
                        this.notiService.handleFullError(err);
                    },
                );
            this.closeEditMessage();
        }
    }

    /** Подтверждение удаления */
    confirmForDelete(msg) {
        if (msg.isSent === false) {
            this.deleteMsg = msg;
            const inx = this.chatData.findIndex(
                (el) => el.fileUploading,
            );
            this.chatData.splice(inx, 1);
            this.fileToUpload = null;
            this.fileInput.nativeElement.value = '';
            this.fotoInput.nativeElement.value = '';
            return false;
            }
            this.deleteMsg = msg;
            this.deleteConfirm();
    }

    deleteConfirm() {
        const data = {
            title: 'Удалить сообщение?',
            buttons: [
                {
                    color: '_grey',
                    name: 'Отмена',
                    action: 'exit',
                    autofocus: false,
                },
                {
                    color: '_blue',
                    name: 'Удалить',
                    action: 'send',
                    autofocus: true,
                },
            ],
        };
        const dialogForm = this.dialog.open(ConfirmSmartComponent, { data });
        dialogForm
            .afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe((result) => {
                if (result === 'send') {
                    this.deleteChatMsg();
                }
            });
    }

    confirmForCancel() {
        const data = {
            title: 'Отменить отправку сообщения?',
            buttons: [
                {
                    color: '_grey',
                    name: 'Продолжить',
                    action: 'exit',
                    autofocus: false,
                },
                {
                    color: '_blue',
                    name: 'Удалить',
                    action: 'send',
                    autofocus: true,
                },
            ],
        };
        const dialogForm = this.dialog.open(ConfirmSmartComponent, { data });
        dialogForm
            .afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe((result) => {
                if (result === 'send') {
                    this.deleteChatMsg();
                }
            });
    }
    /** Удаление сообщения */
    deleteChatMsg() {
        this.chatsApi
            .deleteChatMessage(this.deleteMsg.id, this.currentUser.auth.apiKey)
            .subscribe(
                (res) => {},
                (err) => {
                    this.notiService.handleFullError(err);
                },
            );
        this.deleteMsg = null;
    }

    prepareLinks(list) {
        if (!list || !list.length) {
            return false;
        }

        list.forEach((el) => {
            el['link'] = '@' + el.last_name + '_' + el.first_name;
            el.link = el.link.replace(' ', '_');
        });

        return list;
    }

    // История чата запрашивается с апишки
    getChatHistory(pageSize = 40) {

        if (this.isMsgFetching) {
            return;
        }

        this.isMsgFetching = true;

        this.chatsApi
            .getChatHistory(
                this.documentId,
                this.currentUser.auth.apiKey,
                pageSize,
                this.documentEntity,
                this.offset,
            )
            .pipe(takeUntil(this.destroyed))
            .subscribe(
                (res) => {
                    this.isMsgFetching = false;

                    const messages = res.messages;
                    let users = res['chat_users'];
                    const notifty = res['notify'];

                    users = this.prepareLinks(users);

                    this.chatsService.members$.next(users);
                    this.chatsService.notify$.next(notifty);

                    if(this.messageToScroll) {
                        this.showReplyMessage(this.messageToScroll)
                    };

                    if (messages) {
                        if (!messages.length && this.offset > 0) {
                            this.scrollAvailable = false;
                            return false;
                        }
                        this.myId = this.currentUser.auth.id;
                        if (this.offset == 0) {
                            // новые вначале списка, старые в конце
                            this.chatData = [];
                            messages.forEach((msg) =>
                                this.chatData.unshift(
                                    JSON.deserialize<ChatMessage>(
                                        msg,
                                        ChatMessage,
                                    ),
                                ),
                            );
                            ChatMessage.transformChatData(this.chatData);
                            this.doScroll = true;
                            this.getScrollBarWidth();
                            this.saveLocalMessages(this.chatData);
                        } else {
                            // новые вначале списка, старые в конце
                            const newArr = [];
                            messages.forEach((msg) =>
                                newArr.unshift(
                                    JSON.deserialize<ChatMessage>(
                                        msg,
                                        ChatMessage,
                                    ),
                                ),
                            );
                            this.chatData = [...this.chatData, ...newArr];
                            ChatMessage.transformChatData(this.chatData);
                        }

                        this.offset += pageSize;

                        const data = {
                            id: this.documentId,
                            entity: this.documentEntity,
                            chat_id: this.data?.chat_id,
                        };

                        this.chatsService.sendHistoryMessages$.next(data);
                        this.chatsService.gotChatMessage$.next(null);

                        if (!this.isLoaded) {
                            this.initChatListener();
                        }

                        this.isLoaded = true;
                        this.isShowLoader = false;
                    }

                    if (!this.isConnected) {
                        this.isNeedRestart = true;

                        setTimeout(() => {
                            if (this.isConnected) {
                                this.isNeedRestart = false;
                                this.getChatHistory();
                            }
                        }, 2000);
                    }
                },
                (err) => {
                    this.notiService.handleFullError(err);
                    this.isLoaded = true;
                    this.isShowLoader = false;
                    this.isMsgFetching = false;
                },
            );
    }

    saveLocalMessages(messages) {
        localStorage.setItem(
            'savedChatData' +
                +this.currentUser.currentUser$.getValue().id +
                this.documentId +
                this.documentEntity,
            JSON.stringify(messages),
        );
    }

    getSavedMessages() {
        const saved = localStorage.getItem(
            'savedMessages' +
                this.currentUser.currentUser$.getValue().id +
                this.documentId,
        );
        let messages = [];
        this.offset = 0;
        if (saved) {
            messages = JSON.parse(saved);
        }

        if (
            this.isConnected &&
            messages &&
            messages.length &&
            window.navigator.onLine
        ) {
            this.clearSavedMessages();
            messages.forEach((el) => {
                this.chatsService.sendChatMessage$.next(el);
            });
            this.getScrollBarWidth();
        } else {
            messages.forEach((el) => {
                this.makeFakeMsg(el);
            });
        }
    }

    clearSavedMessages() {
        localStorage.removeItem(
            'savedMessages' +
                +this.currentUser.currentUser$.getValue().id +
                this.documentId,
        );
    }

    saveMessage(msg) {
        const saved = localStorage.getItem(
            'savedMessages' +
                this.currentUser.currentUser$.getValue().id +
                this.documentId,
        );
        let messages = [];
        if (saved) {
            messages = JSON.parse(saved);
        }

        messages.push(msg);

        localStorage.setItem(
            'savedMessages' +
                this.currentUser.currentUser$.getValue().id +
                this.documentId,
            JSON.stringify(messages),
        );
    }

    clearReply() {
        this.chatService.replyMsg$.next(null);
    }

    /*
     * Инит чат listener'a
     */
    initChatListener() {
        this.chatService.replyMsg$.next(null);

        /** Подписывается на единичное сообщение */
        this.chatsService.gotChatMessage$
            .pipe(takeUntil(this.destroyed))
            .subscribe((message: ChatMessage) => {
                if (
                    message &&
                    message.entity ===
                        this.documentEntity + '=' + this.documentId
                ) {
                    const msg = JSON.deserialize<ChatMessage>(
                        message,
                        ChatMessage,
                    );

                    const data = {
                        id: this.documentId,
                        entity: this.documentEntity,
                        chat_id: this.data?.chat_id,
                    };
                    this.chatsService.sendLastRead$.next(data);

                    if (
                        +message['user_id'] !==
                        +this.currentUser.currentUser$.value.id
                    ) {
                        this.chatData.forEach((el) => (el.isRead = true));
                    }

                    if (message['created_at']) {
                        message.createdAt = message['created_at'];
                    }

                    ChatMessage.addNewMsg(msg, this.chatData);
                    this.saveLocalMessages(this.chatData);
                    setTimeout(() => {});
                    this.textareaResize(true);

                    this.chatsService.gotChatMessage$.next(null);
                    this.doScroll = true;

                    if (!msg.userId) {
                        this.getChatHistory();
                    }
                }
            });

        /** Подписка на прочтение сообщения */
        this.chatsService.chatMessageIsRead$
            .pipe(takeUntil(this.destroyed))
            .subscribe((message: ChatMessage) => {
                if (message) {
                    if (message && +message.id === +this.documentId) {
                        this.chatData.forEach((el) => (el.isRead = true));
                    }
                }
            });

        /** Подписка на редактирование сообщения */
        this.chatsService.editedChatMessage$
            .pipe(takeUntil(this.destroyed))
            .subscribe((message: ChatMessage) => {
                if (
                    message &&
                    message.entity ===
                        this.documentEntity + '=' + this.documentId
                ) {
                    const msg = JSON.deserialize<ChatMessage>(
                        message,
                        ChatMessage,
                    );
                    this.chatData = this.chatData.map((elMsg) => {
                        if (elMsg.id === msg.id) {
                            msg.showAvatar = elMsg.showAvatar;
                            msg.showDate = elMsg.showDate;
                            return msg;
                        }
                        return elMsg;
                    });
                }
            });
        /** Подписка на удаление сообщения */
        this.chatsService.deletedChatMessage$
            .pipe(takeUntil(this.destroyed))
            .subscribe((message: ChatMessage) => {
                if (message && message.id) {
                    let idx = this.chatData.findIndex(
                        (elMsg) => elMsg.id === message.id,
                    );
                    if (idx === -1) {
                        return;
                    }
                    this.chatData.splice(idx, 1);

                    if (idx > 0) {
                        idx -= 1;
                    }

                    let prevMsg = null;
                    let nextMsg = null;
                    if (idx > 0) {
                        prevMsg = this.chatData[idx - 1];
                    }

                    if (idx < this.chatData.length) {
                        nextMsg = this.chatData[idx + 1];
                        ChatMessage.transformMsg(
                            prevMsg,
                            this.chatData[idx],
                            nextMsg,
                        );
                    }
                }
            });
    }

    ngAfterViewChecked(): void {
        if (this.chatList && this.doScroll) {
            this.chatList.nativeElement.scrollTop = 0; //this.chatList.nativeElement.scrollHeight;
            this.doScroll = false;
        }
    }

    checkKeyUp(event) {
        this.messageInput.emit(this.message);
        if (event.key === 'Shift') {
            this.isShiftKeyDown = false;
        }

        if (event.key === 'Control') {
            this.isControlKeyDown = false;
        }
    }

    submitOnEnter(event, form: NgForm) {
        if (event.key === 'Shift') {
            this.isShiftKeyDown = true;
        }

        if (event.key === 'Control') {
            this.isControlKeyDown = true;
        }

        if (event.key === 'Enter' && this.isControlKeyDown) {
            this.message = this.message + '\r\n';
        }

        if (
            event.key === 'Enter' &&
            !this.isShiftKeyDown &&
            !this.isControlKeyDown
        ) {
            event.preventDefault();
            if (form.valid) {
                if (this.enterDisabled) {
                    return false;
                }
                form.ngSubmit.emit();
            }
        }
    }

    generateTags(message) {
        const regexp = new RegExp(/(@\S+)|(\S+)'/g);
        const array = [...message.matchAll(regexp)];
        let tags = [];

        array.forEach((el) => {
            if (this.chatService.testMemberLink(el[0])) {
                tags.push(this.chatService.testMemberLink(el[0]));
            }
        });

        return tags;
    }

    /** Создает из текста сообщение нужного для веб сокета формата */
    createMessage(message: string, file?): any {
        const user = this.currentUser.currentUser$.getValue();
        const tags = this.generateTags(message);

        const date = new Date;

        return {
            type: this.entity,
            entity: this.documentEntity + '=' + this.documentId,
            user_id: this.myId,
            user_photo: user.photo,
            first_name: user.firstName,
            middle_name: user.middleName,
            last_name: user.lastName,
            message,
            tags: tags,
            msg_uid: hashCode(
                this.documentEntity +
                    '=' +
                    this.documentId +
                    'user id' +
                    this.myId +
                    'message' +
                    message +
                    'date' +
                    date +
                    'ms' +
                    date.getMilliseconds(),
            ),
            file,
        };
    }

    onReplyClick(msg) {
        this.chatService.replyMsg$.next(msg)
    }

    /** Отправляет link сообщение с задачей */
    sendTaskToChat(id) {
        const msg = this.createMessage('');
        msg['links'] = [
            {
                link_type: 'task',
                entity_id: id,
            },
        ];

        const send = {
            message: '',
            links: msg['links'],
        };

        if (this.reply) {
            send['reply_to'] = this.reply.id
        }

        if (this.isConnected && window.navigator.onLine) {
            this.chatsService
                .sendChatSpecialMessage(
                    this.documentEntity,
                    this.documentId,
                    this.currentUser.auth.apiKey,
                    send,
                )
                .pipe(takeUntil(this.destroyed))
                .subscribe((message: ChatMessage) => {
                    this.clearReply();

                    this.textareaResize();
                });
        } else {
            this.saveMessage(msg);
            this.makeFakeMsg(msg);
        }
    }

    getFileUrlPdf(file) {
        if (this.isPdf(file)) {
            return `${this.config.fileStorageURL}files/${file.entityId}/${file.id}/${file.name}`;
        }

        return '';
    }

    generatePreviewMsgImage(file) {

        // show in preview wnd
        const reader  = new FileReader();
        reader.onload = (e) =>  {
            const additionalFields = {
                imgSrc: e.target.result,
                type: 'image',
            };

            this.generatePreviewMsg(file, additionalFields);
        };
        // you have to declare the file loading
        reader.readAsDataURL(file);
    }

    generatePreviewMsg(file, additionalFields = {}) {
        const uploadData = new FormData();
        uploadData.append('upload', file);
        uploadData.append('entity_id', 'chats');

        const date = new Date();

       const previewMsg = {
            ...additionalFields,
            uploadData,
            documentEntity: this.documentEntity,
            documentId: this.documentId,
            message: this.message,
            id: date.getTime(),
            fileName: file.name,
        };

        this.previewQueue.push(previewMsg);
    }

    generateUploadMsg(file, message = '') {
        const msg = this.createMessage(message, file);
        msg['fileUploading'] = true;
        this.chatService.attachmentsDraft$.next(file); // вроде больше это не надо, можно убрать
        this.msgInUpload = msg;
        this.makeFakeMsg(msg);

        return msg;
    }

    public onPaste($event: any) {
        if (this.chatService.attachmentsDraft$.value) {
            return false;
        }
        let items = ($event.clipboardData || $event.originalEvent.clipboardData).items;
        items = [items[0].getAsFile()];
        
        if (items && items[0]) {
            this.onAttachPhoto(items);
        }
    }

    async onChangePhoto($event) {
        if ($event.target.files && !this.chatService.attachmentsDraft$.value) {
            this.onAttachPhoto($event.target.files);
        }
    }

    async onChangeFile($event) {
        if ($event.target.files && !this.chatService.attachmentsDraft$.value) {
            this.onAttachFile($event.target.files);
        }
    }

    onAttachPhoto(files) {

        for (const file of files) {
            if (file.type && file.type.search('image') < 0) {
                if (file.type && file.type.search('video') === 0) {
                    this.onAttachFile([file]);
                }
                return false;
            }
            if (file.size === 0) {
                this.notiService.handleError('Файл не должен быть пустым');
            } else if (file.size > 104857600) {
                this.notiService.handleError(
                    'Размер файла превышает допустимые 100 МБ',
                );
            } else {
                this.fileToUpload = file;

                this.generatePreviewMsgImage(this.fileToUpload);
            }
        }
    }

    public onAttachFile(files) {
        for (let file of files) {
            if (file.size === 0) {
                this.notiService.handleError('Файл не должен быть пустым');
            } else if (file.size > 104857600) {
                this.notiService.handleError(
                    'Размер файла превышает допустимые 100 МБ',
                );
            } else {
                if (file) {
                    this.fileToUpload = file;
                    this.generatePreviewMsg(this.fileToUpload);
                }
            }
        }
    }

    /** Отправляет сообщение */
    sendMessage(message) {
        if (message.trim().length < 1) {
            return false;
        }

        let nextMessage = null;

        if (message.length > 1500) {
            const i = message.lastIndexOf(" ", 1500);
            if (i) {
                nextMessage = message.slice(i, message.length);

                message = message.slice(0, i);
            }
        }

        const msg = this.createMessage(message);

        if (this.isConnected && window.navigator.onLine) {
            this.chatsService
                .sendChatMessage(
                    this.documentEntity,
                    this.documentId,
                    this.currentUser.auth.apiKey,
                    message,
                    msg.tags,
                    this.reply?.id
                )
                .pipe(takeUntil(this.destroyed))
                .subscribe(
                    (message: ChatMessage) => {
                        if (nextMessage) {
                            this.sendMessage(nextMessage);
                        }
                    },
                    (err) => {
                        this.notiService.handleFullError(err);
                        this.isLoaded = true;
                        this.isShowLoader = false;
                    },
                );
            this.message = '';
            this.clearReply();
        } else {
            this.saveMessage(msg);
            this.makeFakeMsg(msg);
            this.clearReply();
        }
    }

    makeFakeMsg(msg) {
        msg = JSON.deserialize<ChatMessage>(msg, ChatMessage);
        msg.createdAt = new Date();
        msg.isSent = false;
        ChatMessage.addNewMsg(msg, this.chatData);
        this.lastMsg = this.chatData.length;
        if (!msg.fileUploading) {
            this.message = '';
        }
        this.textareaResize(true);
        this.doScroll = true;
    }

    textareaResize(resizeToDefault = false) {
        this.taskService.saveMessage$.next(true);

        setTimeout(() => {
            this.messageInput.emit(this.message);
            if (this.input) {
                const textarea = this.input.nativeElement;
                textarea.style.height = '';
                let newHeight = 36;
                if (!resizeToDefault) {
                    newHeight = Math.min(textarea.scrollHeight, 300);
                }

                textarea.style.height = Math.max(newHeight, 36) + 'px';
            }
        });
    }

    getScrollBarWidth() {
        const el = this.chatList?.nativeElement;
        let scrollbarWidth = 0;
        if (el) {
            scrollbarWidth = el.offsetWidth - el.clientWidth;
            el.style.cssText = `--scrollbar-width: ${scrollbarWidth}px`;
        }

    }

    onScroll(evt) {
        // теперь скролл крутится в отрицательную сторону (от новых к старынм), надо тэо учитывать
        const pos = this.chatList.nativeElement.scrollTop;
        const total = this.chatList.nativeElement.scrollHeight;
        const visible = this.chatList.nativeElement.clientHeight;
        const max = total - visible;
        const threshold = 20;

        if (max + pos < threshold && this.scrollAvailable) {
            this.getChatHistory();
        }

        this.isFloatDateVisible = true;
        if (this.animationTimer) {
            clearTimeout(this.animationTimer);
        }
        this.animationTimer = setTimeout(() => {
            this.isFloatDateVisible = false;
        }, 2000);
    }

    onMembersDialogOpen() {
        const members = this.chatsService.members$.value;
        const dialogRef = this.dialog.open(ChatMembersComponent, {
            minWidth: '500px',
            maxWidth: '500px',
            data: {
                id: this.documentId,
                type: this.documentEntity,
                selected: members,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe((result) => {
                if (result) {
                    this.saveMembers(result);
                }
            });

        dialogRef
            .backdropClick()
            .pipe(takeUntil(this.destroyed))
            .subscribe(() => {
                // Для выбора учатников доски не спрашиваем
                if (dialogRef.componentInstance.changedMembers) {
                    this.confirmSaveChange();
                } else {
                    this.dialog.closeAll();
                }
            });
    }

    saveMembers(users) {
        const data = {
            entity_type: this.entity,
            entity_id: this.itemId,
            users: JSON.serialize(users),
        };

        this.api
            .updateChatUsers(data)
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                this.dialog.closeAll();
                this.getChatHistory();
            });
    }

    confirmSaveChange() {
        const data = {
            title: 'Сохранить изменения?',
            buttons: [
                {
                    color: '_grey',
                    name: 'Нет',
                    action: 'exit',
                    autofocus: false,
                },
                {
                    color: '_blue',
                    name: 'Да',
                    action: 'send',
                    autofocus: true,
                },
            ],
        };
        const dialogForm = this.dialog.open(ConfirmSmartComponent, { data });
        dialogForm
            .afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe((result) => {
                if (result === 'send') {
                }
                if (result === 'exit') {
                    this.dialog.closeAll();
                }
            });
    }

    /** Открыть редактирования */
    editChatMsg(editableMessage) {
        this.clearReply();
        this.editableMessage = editableMessage;
        this.message = editableMessage.message;
        this.isEdit = true;
        setTimeout(() => {
            this.input.nativeElement.focus();
            this.textareaResize();
        }, 0);
    }

    /** Закрыть редактирование */
    closeEditMessage() {
        this.editableMessage = null;
        this.isEdit = false;
        this.message = '';
        this.textareaResize(true);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            this.data = changes.data.currentValue;
            this.documentId = this.itemId ? this.itemId : this.data.objectId;
            this.documentEntity = this.entity
                ? this.entity
                : this.data.objectType;
            this.checkFullConnected$.next(true);
        }

        if (changes.entity && this.entity) {
            this.entity = changes.entity.currentValue;
            this.documentEntity = this.entity
                ? this.entity
                : this.data.objectType;
            this.checkFullConnected$.next(true);
        }

        if (changes.itemId && this.itemId) {
            this.itemId = changes.itemId.currentValue;
            this.documentId = this.itemId ? this.itemId : this.data.objectId;
            this.checkFullConnected$.next(true);
        }
    }

    showReplyMessage(msg) {
        setTimeout(() => {
            const el = document.getElementById('id' + msg.id);

            if (el) {
                el?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                this.messageToScroll = null;

                el.classList.add('_show');

                setTimeout(() => [
                    el.classList.remove('_show')
                ], 2000);

            } else {
                const size = (msg.offset + msg.limit) - this.offset + 10;
                this.messageToScroll = msg;
                this.getChatHistory(size);
            }
        }, 100);
    }

    ngOnDestroy() {
        const data = {
            id: this.documentId,
            entity: this.documentEntity,
        };
        this.chatsService.chatUnsubscribe$.next(data);
        this.destroyed.next();
        this.destroyed.complete();
        this.chatService.attachmentsDraft$.next(null);
    }

    onEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.toggleEmojiPicker(this.input?.nativeElement);
        } else {
        }
    };

    isFloatDateVisible = false;

    toggleEmojiPicker(input: HTMLTextAreaElement) {
        if (this.isEmojiPickerShown) {
            this.isEmojiPickerShown = false;
            window.removeEventListener('keypress', this.onEnterKeyPress, false);
            this.restoreFocus(input);
        } else {
            this.isEmojiPickerShown = true;
            window.addEventListener('keypress', this.onEnterKeyPress, false);
        }
    }

    addEmoji({ emoji, $event }: { emoji: EmojiData; $event: PointerEvent }) {
        this.message += emoji.native;
    }

    restoreFocus(input: HTMLTextAreaElement) {
        input.focus();
    }

    public onIntersection({
        target,
        visible,
    }: {
        target: Element;
        visible: boolean;
    }): void {
        const h = window.innerHeight - 100;
        const pos = target.getBoundingClientRect().top;

        this.renderer.addClass(target, visible ? 'active' : 'inactive');
        this.renderer.removeClass(target, visible ? 'inactive' : 'active');

        this.renderer.addClass(target, h > pos ? 'top' : 'low');
        this.renderer.removeClass(target, h > pos ? 'low' : 'top');
    }

    isMsgToday(chatMsg) {
        return moment(chatMsg).isSame(this.today, 'day');
    }

    isMsgYesterday(chatMsg) {
        return moment(chatMsg).isSame(this.yesterday, 'day');
    }

    removePreview(id) {
        const idx = this.previewQueue.findIndex(m => m.id === id);
        if (idx >= 0) {
            this.previewQueue.splice(idx, 1);
        }
    }

    isSendBtnActive() {
        return this.message.length || this.previewQueue.length > 0;
    }
}
