import {Component, Inject, OnInit,} from '@angular/core';
import {AuthService} from '../../../../services/auth.service';
import {ChatsService} from '../../../../services/chats.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfigService} from '../../../../services/config.service';
import {User} from '../../../../models/user';

@Component({
    selector: 'app-admins-dialog',
    templateUrl: './admins-dialog.component.html',
    styleUrls: ['./admins-dialog.component.scss'],
})
export class AdminsDialogComponent implements OnInit {
    admins: User[] = [];
    title = 'Администраторы компании';
    description;

    constructor(
        private auth: AuthService,
        private chats: ChatsService,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data,
        private config: ConfigService,
    ) {
        this.admins = this.auth.company$.value?.admins;
        if (data.admins) {
            this.admins = data.admins;
        }
        if (data.title) {
            this.title = data.title;
        }
        if (data.description) {
            this.description = data.description;
        }

    }

    ngOnInit() {
    }

    openChat(id, name) {
        const data = {
            id: id,
            name: name
        };
        this.chats.openChat$.next(data);
        this.close();
    }

    onClick(admin) {
        this.openChat(admin.id, admin.shortName);
    }
    close() {
        this.dialogRef.close();
    }

    getAvatar(u) {
        return this.config.getUserAvatarByUser(u);
    }

    getPosition(adm: User) {
        return adm?.slots?.find(s => !s.isRole)?.position?.title || 'Администратор';
    }
}
