import { NotifierService } from 'gramli-angular-notifier';

import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ConfigService } from '../../../services/config.service';

@Injectable({
    providedIn: 'root',
})
export class ConfigQuestionsService extends ConfigService {
    constructor(notifierService: NotifierService) {
        super(notifierService);
    }

    readonly questions = environment.questions;
    readonly fileStoragePicsUrl = environment.fileStoragePicsUrl;
}
