import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'striphtml'
})

export class StripHtmlPipe implements PipeTransform {
    transform(value: string): any {
        return value.replace(/<.*?>/g, ' ') // replace tags to spaces
            .replace(/&nbsp;/g, ' ')
            .replace(/\s\s+/g, ' ').trim(); // reduce multiple spaces to one
    }
}
