import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NotifierService} from 'gramli-angular-notifier';
import {ConfigService} from '../../../../services/config.service';
import {AuthService} from '../../../../services/auth.service';
import {User} from '../../../../models/user';
import {ApiPhonebookService} from '../../../phonebook/services/api-phonebook.service';
import {GlobalNotificationCenterService} from '../../../../services/global-notification-center.service';
import {SearchMatComponent} from '../search-mat/search-mat.component';
import {StorageService} from '../../../../services/storage.service';
import {sortUsers} from '../../../../utils/users-sort';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-people-add',
    templateUrl: './people-add.component.html',
    styleUrls: ['./people-add.component.scss'],
})
export class PeopleAddComponent implements OnInit, OnDestroy {

    public title = '';
    // Список выбранных людей
    public selected: User[] = [];
    public selectedSorted: User[] = [];
    // Список потенциальных возможных
    public users: User[] = [];
    public availableUsersCurrent: User[] = [];
    public availableUsersCurrentSorted: User[] = [];
    public availableUsersCurrentMeetSorted: User[] = [];

    public searchParams = '';
    public isLoaded = false;
    public showAll = false;
    @ViewChild('search') searchLine: SearchMatComponent;
    // Триггер смерти компонента
    private destroyed = new Subject();
    public mono = false;
    private excluded: number[] = [];

    // Флаг фильтра по тарифам
    public tariffsFilter: string = null;
    // Список платных юзеров по id
    public tariffsUsers = null;
    tariffsText: string = null;

    meetUsers: number[] = null;
    private noHidden = false;
    private noBlocked = false;

    sortUsers = sortUsers;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data,
        private notifierService: NotifierService,
        public config: ConfigService,
        private auth: AuthService,
        private phonebookApi: ApiPhonebookService,
        private notiService: GlobalNotificationCenterService,
        private storageServ: StorageService,
    ) {
    }

    get OnlyUserCondition() {
        return ((this.data.flag === 'addExecutive' || this.data.flag === 'addDeputy') && this.selectedSorted.length > 1);
    }

    ngOnInit() {
        if (this.data) {
            this.mono = !!this.data.mono;
        }

        if (this.data.excludedUsers) {
            this.excluded = this.data.excludedUsers;
        }

        if (this.data.noHidden) {
            this.noHidden = this.data.noHidden;
        }

        if (this.data.noBlocked) {
            this.noBlocked = this.data.noBlocked;
        }

        if (this.data.tariffsFilter) {
            this.tariffsFilter = this.data.tariffsFilter;
            this.tariffsText = this.data.tariffsText;
        }

        if (this.data.meetUsers) {
            this.meetUsers= this.data.meetUsers;
        }

        if (this.data.users && this.data.users.length) {
            this.title = this.data.title;
            if (this.data.users) {
                this.users = [].concat(this.data.users);
            }
            if (this.data.selected) {
                this.selected = [].concat(this.data.selected);
            }
            this.filterAvailables();
        } else {
            this.isLoaded = false;
            this.title = this.data.title;
            if (this.data.users) {
                this.users = [].concat(this.data.users);
            }
            if (this.data.selected) {
                this.selected = [].concat(this.data.selected);
            }
            this.getUsers();
        }
    }

    // Возможно вытаскивать всех пользователей нужно где-то еще, но не придумала где
    getUsers() {
        this.isLoaded = false;

        // this.users = this.storageServ.users$.getValue();
        // this.filterAvailables();
        // this.isLoaded = true;

        this.phonebookApi.getUsersShort(this.data.current_position)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.users = res['users'];
                console.log(this.users);
                this.filterAvailables();
                this.isLoaded = true;
            }, (err) => {
                this.notiService.handleFullError(err);
            });
    }

    closeForm() {
        this.dialogRef.close();
    }

    saveForm() {
        if ((this.data.flag === 'addExecutive' || this.data.flag === 'addDeputy') && this.selectedSorted.length > 1) {
            return;
        }
        this.dialogRef.close(this.selected);
    }

    searchChanges(param) {
        this.searchParams = param;
        this.selectedSorted = this.filterValues<User>(this.searchParams, this.selected);
        this.availableUsersCurrentSorted = this.filterValues<User>(this.searchParams, this.availableUsersCurrent).sort(this.sortUsers);
    }

    filterValues<Type>(searchTerm, baseList: Type[]): Type[] {
        let newList;

        if (searchTerm) {
            const terms_str = searchTerm.toLowerCase()
                .split(' ')
                .map(i => i.trim())
                .filter(i => i);
            newList = baseList.filter(
                item => terms_str.every(
                    term => this.testItem<Type>(item, term)
                )
            );
        } else {
            newList = baseList;
        }

        return newList;
    }

    testItem<Type>(item: Type, term: string) {
        return item && (this.testString(item['shortName'], term));
    }

    testString(value: string, term: string) {
        if (!!value) {
            return value.toString().toLowerCase().includes(term);
        }
        return false;
    }

    filterAvailables() {
        if (this.tariffsFilter) {
            const tariffsUsersAll = this.auth.getTariffsUsers();
            this.tariffsUsers = tariffsUsersAll[this.tariffsFilter] || [];
            this.users = this.users.filter(u => {
                if (this.tariffsUsers?.some(el => el === u.id)) {
                    return u
                }
            })
        }

        this.availableUsersCurrent = this.users.filter(el => !this.selected?.find(member => member.id === el.id) && !this.excluded.includes(el.id));

        if (this.noHidden) {
            this.availableUsersCurrent = this.availableUsersCurrent.filter(el => !el.hidden);
        }

        if (this.noBlocked) {
            this.availableUsersCurrent = this.availableUsersCurrent.filter(el => !el.isBlocked);
        }


        this.selectedSorted = this.filterValues<User>(this.searchParams, this.selected);

        if (this.meetUsers) {
            const meet = this.availableUsersCurrent.filter(u => this.meetUsers.includes(u.id));
            const notMeet = this.availableUsersCurrent.filter(u => !this.meetUsers.includes(u.id));
            this.availableUsersCurrentMeetSorted = this.filterValues<User>(this.searchParams, meet).sort(this.sortUsers);
            this.availableUsersCurrentSorted = this.filterValues<User>(this.searchParams, notMeet).sort(this.sortUsers);
        } else {
            this.availableUsersCurrentSorted = this.filterValues<User>(this.searchParams, this.availableUsersCurrent).sort(this.sortUsers);
        }
    }

    addUser(item: User) {
        this.mono ? this.selected = [item] : this.selected.push(item);
        this.searchLine.onClear();
        this.filterAvailables();
    }

    removeUser(item: User) {
        if (item['disabled']) {
            return false;
        }
        this.selected = this.selected.filter(el => el.id !== item.id);
        this.filterAvailables();
    }

    isSelf(user) {
        return user.id === this.auth.auth.id;
    }

    ngOnDestroy() {
        this.destroyed.next(true);
        this.destroyed.complete();
    }

    handleError(errMsg) {
        if (errMsg) {
            this.notifierService.notify('error', errMsg);
        }
    }
}
