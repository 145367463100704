<div class="wrapper" *ngIf="task" [ngClass]="{'_short' : shortView, '_embed': embed}">
    <div class="parent">
        <mat-icon svgIcon="radius_parent" class="icon"></mat-icon>
        <ng-container *ngIf="task.type == 'task'">Основная задача</ng-container>
        <ng-container *ngIf="task.type == 'template'">Шаблон</ng-container>
    </div>

    <div class="cover_box" *ngIf="task.cover">
        <img class="cover"  [src]="cover">
    </div>

    <mat-card appearance="outline" class="task"
              *ngIf="task && viewSettingsTask"
              [id]="task.id"
              [ngClass]="{
               '_opened': task.id === openedId,
               '_new': task.isNew,
               '_expired': task.isTaskExpired,
               '_rejected': task.isTaskRejected,
               '_archive': task.isTaskArchive,
               '_rejected_archive': task.isTaskRejectedArchive,
               '_done': task.isTaskDone,
               '_disabled': disabled === true,
               '_membership' : !task.membership(auth.auth.id)
               }">
        <div class="task__description">
            <mat-icon
                    class="icon-another-board"
                    *ngIf="task.onCurrentBoard === false"
                    svgIcon="radius_bookmark-remove"></mat-icon>

            <app-small-group class="task__group"
                             [group]="boardGroup"
                             *ngIf="boardGroup && viewSettingsTask.isGroupsVisible"></app-small-group>


            <div class="task__description-main">
                <div class="task__title">
                    <span [innerHTML]="task.title | highlight : searchTerm"></span>

                    <div class="match-inside" *ngIf="task.isMatchInside"><mat-icon class="icon">double_arrow</mat-icon></div>
                </div>

                <div class="task__details">
                    <div class="task__number"
                            *ngIf="viewSettingsTask.isNumbersVisible"
                            [innerHTML]="task.vid.toString() | highlight : searchTerm"></div>

                    <div class="task__favorites"
                         *ngIf="task.flagImportance">

                        <mat-icon svgIcon="radius_star-blue-filled"
                                  class="icon"
                                  matTooltip="Добавлена в избранное"></mat-icon>
                    </div>

                    <app-task-manager-status class="task__status"
                                             [disabled]="disabled"
                                             [isText]="viewSettingsTask.isTextStatus"
                                             [data]="task"></app-task-manager-status>

                    <div class="task__important" *ngIf="task.importance">
                        <mat-icon class="icon"
                                  [matTooltip]="importanceText"
                                  [svgIcon]="importanceIcon"></mat-icon>
                    </div>

                    <div class="task__matrix" *ngIf="task.matrixId">
                        <mat-icon svgIcon="radius_matrix" class="icon" matTooltip="Задача находится в Матрице"></mat-icon>
                    </div>

                    <div class="task__toolbar-attachments" *ngIf="task.files && task.files.length || task.links && task.links.length">
                        <mat-icon class="attach">attach_file</mat-icon>
                        {{ task.files.length + task.links.length }}
                    </div>

                    <app-assessment class="task__assessment"
                                    *ngIf="task.assessment && viewSettingsTask.isAssessmentVisible"
                                    [assessment_type]="task.assessmentType"
                                    [data]="task.assessment"></app-assessment>

                    <mat-icon class="icon _template_turn"
                              svgIcon="radius_turn"
                              matTooltip="Повторяющаяся задача"
                              *ngIf="task.type === 'task' &&  task.template"></mat-icon>

                    <div class="task__date" *ngIf="isDateStartVisible && isDateVisible && (task.dateStart || task.dateExpiration)">
                        {{ task.dateStart ?  (task.dateStart | date: 'd MMM')?.replace('.','') : '...'}} -
                        {{ task.dateExpiration ? (task.dateExpiration | date: 'd MMM')?.replace('.','') : '...'}}
                    </div>

                    <div class="task__date" *ngIf="isDateStartVisible && isDateFinishVisible && (task.dateStart || task.dateFinish)">
                        {{ task.dateStart ?  (task.dateStart | date: 'd MMM')?.replace('.','') : '...'}} -
                        {{ task.dateFinish ? (task.dateFinish | date: 'd MMM')?.replace('.','') : '...'}}
                    </div>

                    <div class="task__date" *ngIf="!isDateVisible && !isDateFinishVisible && isDateStartVisible && task.dateStart">
                        {{ (task.dateStart | date: 'd MMM')?.replace('.','')}}
                    </div>

                    <div class="task__date" *ngIf="task.dateExpiration && isDateVisible && !isDateStartVisible">
                        {{ (task.dateExpiration | date: 'd MMM')?.replace('.','')}}
                    </div>

                    <div class="task__date" *ngIf="task.dateFinish && isDateFinishVisible && !isDateStartVisible">
                        {{ (task.dateFinish | date: 'd MMM')?.replace('.','')}}
                    </div>

                    <div class="task__toolbar-messages" *ngIf="task.messages" (click)="openChat()">
                        <div class="messages">
                            <img src="/assets/icons/chat_mobile.svg" alt="" class="chat-icon" />
                            <span>{{ task.messages }}</span>
                        </div>
                    </div>

                    <ul class="task__description-attachments"
                        *ngIf="viewSettingsTask.isBoardsVisible || viewSettingsTask.isTagsVisible">
                        <ng-container *ngFor="let board of task.boards">
                            <ng-container *ngIf="viewSettingsTask.isTagsVisible && isBoardSelected(board)">
                                <li class="task__description-tag" *ngFor="let tag of getBoardTags(board)"
                                    [ngClass]="{'_selected' : isBoardSelected(board)}"
                                    [ngStyle]="{'background-color' : getTagBackground(tag.color)}"
                                    [innerHTML]="tag.name | highlight : searchTerm">
                                </li>
                            </ng-container>
                        </ng-container>
                    </ul>
                </div>
            </div>

        </div>

        <div class="task__toolbar" *ngIf="isToolbarVisible">

            <ng-container>
                    <span class="task__description-sprint"
                          *ngIf="task.sprint && viewSettingsTask && viewSettingsTask.isSprintVisible"
                          [ngStyle]="{'background-color' : '#e9eff4'}">
                        {{task.sprint.name}}
                    </span>
            </ng-container>

            <div class="spacer"></div>

            <div class="users">
                <ng-container *ngIf="viewSettingsTask.isResponsibleVisible">
                    <ul class="task__toolbar-performers">
                        <ng-container *ngIf="task.responsible">
                            <li class="task__toolbar-performer" [matTooltip]=" task.responsible.shortName">
                                <app-user [data]="task.responsible" [responsive]="task.activeUsers[0]?.id === task.responsible?.id"></app-user>
                            </li>
                        </ng-container>
                    </ul>
                </ng-container>
                <ng-container *ngIf="viewSettingsTask.isPerformersVisible">
                    <ul class="task__toolbar-performers">
                        <ng-container *ngFor="let performer of task.activeUsers; let i = index">
                            <li class="task__toolbar-performer" *ngIf="i < 2 && (performer.id !== task.responsible?.id)" [matTooltip]="performer.shortName">
                                <app-user [data]="performer" [responsive]="performer.id === task.responsible?.id"></app-user>
                            </li>
                        </ng-container>

                        <li class="task__toolbar-performer" *ngIf="task.activeUsers.length > 2">
                            <span class="oversize">+{{task.activeUsers.length - 2}}</span>
                        </li>
                    </ul>
                </ng-container>
            </div>

        </div>
    </mat-card>
</div>

<div class="selected-cnt">
    <ng-content></ng-content>
</div>


