import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppsService {

    public currentFrame: HTMLIFrameElement;
    closeObj = new Subject<void>();

    constructor(
        private router: Router,
    ) {

    }

    public openObj(params) {
        let baseUrl = 'tasks';
        let target = '';

        if (!params.task) {
            baseUrl = 'apps/finance-1c';
            if (environment.production) {
                target = 'https://transfert-1c.myradius.ru';
            } else {
                target = 'https://transfert-1c.development.myradius.ru';
            }

            if (this.router.url.includes(baseUrl)) {
                document.querySelector('iframe').contentWindow.postMessage({call: 'sendValue', value: params}, target);
} else {
                this.router.navigate([baseUrl], {queryParams: params});
            }
        } else {
            this.router.navigate([baseUrl], {queryParams: params});
        }


    }
}
