import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {AuthService} from '../../../services/auth.service';
import {map} from 'rxjs/operators';
import {JSON} from 'ta-json';
import {MobilePhone, Rights, User} from '../../../models/user';
import {ConfigPhonebookService} from './config-phonebook.service';
import {ApiService} from '../../../services/api.service';
import {Subdivision} from '../models/subdivision';
import {ConfigService} from '../../../services/config.service';
import {UserInfoService} from '../../../services/user-info.service';
import {NotifierService} from 'gramli-angular-notifier';
import {Member} from '../../task-manager/models/member';
import {PositionShort} from '../models/position-short';
import {HistoryPhonebook} from '../models/history-phonebook';

@Injectable({
  providedIn: 'root'
})
export class ApiPhonebookService extends ApiService {

    constructor(
        readonly http: HttpClient,
        public configGlobal: ConfigService,
        public userInfo: UserInfoService,
        public auth: AuthService,
        public notifierService: NotifierService,
        public config: ConfigPhonebookService) {
        super(http, configGlobal, userInfo, auth, notifierService);
    }

    createSubdivision(data) {
        return this.http.post(
            `${this.config.subdivisions}`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<Subdivision>(res['payload'], Subdivision))
        );
    }

    updateSubdivision(data, id) {
        return this.http.put(
            `${this.config.subdivisions}${id}/`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<Subdivision>(res['payload'], Subdivision))
        );
    }

    sendMultipleAdd(data) {
        return this.http.post(
            `${this.config.users}add_group_users/`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => res)
        );
    }

    createUser(data) {
        return this.http.post(
            `${this.config.users}`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                        user: JSON.deserialize<User>(res['payload'], User)
                    })
            )
        );
    }

    deleteUser(userId: number) {
        return this.http.delete(
            `${this.config.users}${userId}/`,
            this.auth.authorize(),
        ).pipe(
            map(res => userId)
        );
    }

    editUser(data: any, userId: number) {
        return this.http.put(
            `${this.config.users}${userId}/`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                        user: JSON.deserialize<User>(res['payload'], User),
                        rights: JSON.deserialize<Rights>(res['rights'], Rights)
                    })
            )
        );
    }

    recoverUser(userId) {
        return this.http.delete(
            `${this.config.archiveUsers}${userId}`,
            this.auth.authorize(),
            ).pipe(
                map(res => ({
                        user: JSON.deserialize<User>(res['payload'], User),
                    })
            )
            );
    }

    hideUser(data: any, userId: number) {
        return this.http.put(
            `${this.config.users}${userId}/hide`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<User>(res['payload'], User))
        );
    }

    // TODO: проверить когда будет бек, но что-то подобное
    favoriteUser(data: any, userId: number) {
        return this.http.put(
            `${this.config.users}${userId}/set_favorite/`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<User>(res['payload'], User))
        );
    }

    sendUserTo1c(userId: string) {
        return this.http.get(
            `${this.config.users}send_user_to_one_c/${userId}/`,
            this.auth.authorize(),
        ).pipe(
            map(res => res)
        );
    };

    getUserById(userId: string | number) {
        return this.http.get(
            `${this.config.users}${userId}/`,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                        user: JSON.deserialize<User>(res['payload'], User),
                        rights: JSON.deserialize<Rights>(res['rights'], Rights),
                        button: res['button']
                    })
            )
        );
    };

    getArchiveUsers(search?, from?, to?) {
        return this.http.get(
            `${this.config.archiveUsers}?search=${search}&from=${from}&to=${to}`,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                        users: JSON.deserialize<User[]>(res['payload'], User),
                        count: res['count'],
                        //rights: JSON.deserialize<Rights>(res['rights'], Rights)
                    })
            )
        );
    }

    getUsersShort(position = null) {
        let pos = '';
        if (position) {
            pos = '_with_position/';
        }

        return this.http.get(
            `${this.config.users}short${pos}`,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                        users: JSON.deserialize<User[]>(res['payload'], User),
                        rights: JSON.deserialize<Rights>(res['rights'], Rights)
                    })
            )
        );
    }


    getUsersShortMembers() {
        return this.http.get(
            `${this.config.users}short`,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<Member[]>(res['payload'], Member))
        );
    }

    getPositions() {
        return this.http.get(
            `${this.config.structPositions}?template=lite`,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<PositionShort[]>(res['payload'], PositionShort))
        );
    }

    public sendInvite(params) {
        return this.http.post(
            `${this.config.authorizationInvites}send/`,
            params,
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }

    public uploadFile(file: FormData) {
        return this.http.post(
            `${this.config.fileStorageURL}files/`,
            file,
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }

    addPhone(phone: string, userId: number) {
        return this.http.post(
            `${this.configGlobal.authUpdateEmail}add_phone/`,
            {
                user: {
                    id: userId
                },
                account: phone,
            },
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }

    deletePhone(phone: MobilePhone, userId: number) {
        // DELETE /login/invite/ -- удаляет неподтверждённый
        // DELETE /login -- удаляет подтвержденный
        const url = phone.isVerified ? '' : 'invite/';

        return this.http.delete(
            `${this.configGlobal.authUpdateEmail}${url}?user_id=${userId}&account=${phone.phone}`,
            this.auth.authorize()
        ).pipe(
            map(res => res)
        );
    }

    checkPhone(number: string, cipher: string) {
        return this.http.post(
            `${this.configGlobal.authUpdateEmail}accept_phone/`,
            {
                cipher,
                account: number,
            },
            this.auth.authorize()
        ).pipe(
            map(res => res['payload'])
        );
    }



    // GET https://api.development.myradius.ru/platform-authorization/api/v1/history/1715/
    getHistory(userId: string | number) {
        return this.http.get(
            `${this.configGlobal.authService}history/${userId}/`,
            this.auth.authorize()
        ).pipe(
            map(res => JSON.deserialize<HistoryPhonebook[]>(res['payload'], HistoryPhonebook))
        );
    }
}
