import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {PositionFolder} from '../../../models/position-folder';
import {Position} from '../../../models/position';
import {ConfigFunctionsService} from '../../../services/config-functions.service';
import {ApiCompanyService} from '../../../services/api-company.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GlobalNotificationCenterService} from '../../../../../services/global-notification-center.service';
import {takeUntil} from 'rxjs/operators';
import {PositionsService} from '../../../services/positions.service';
import {CompanyService} from '../../../services/company.service';

@Component({
  selector: 'app-positions-dialog',
  templateUrl: './positions-dialog.component.html',
  styleUrls: ['./positions-dialog.component.scss']
})
export class PositionsDialogComponent implements OnInit, OnDestroy {
    public title = '';
    public isLoaded = false;
    private destroyed = new Subject<void>();

    public flatPositionFolders: PositionFolder[] = [];
    public positionFolders: PositionFolder[] = [];
    public positions: Position[] = [];
    public sortedPositions: Position[] = null;
    public sortedFolders: PositionFolder[] = null;
    public selectedPositions = [];
    public selectedIndex = 0;
    public searchStr = '';
    public folders = false;
    public single = false;
    public parentId = null;

    constructor(
        public config: ConfigFunctionsService,
        private api: ApiCompanyService,
        private company: CompanyService,
        public dialogRef: MatDialogRef<any>,
        private positionsService: PositionsService,
        private notiService: GlobalNotificationCenterService,
        @Inject(MAT_DIALOG_DATA) public data = null,
    ) {
        this.folders = this.data && this.data.folders;
        this.single = this.data && this.data.single;
        this.title = this.data && this.data.title ? this.data.title : 'Добавить должность (роль)';
        this.parentId = this.data && this.data.parentId ? this.data.parentId : null;
    }

    ngOnInit(): void {
        this.selectedPositions = [];

        this.selectedIndex = this.selectedPositions && this.selectedPositions.length ? 1 : 0;

        this.getPositions();

        this.positionsService.positionCreated$
            .pipe(takeUntil(this.destroyed))
            .subscribe((data) => {
                if (this.data?.createFromPhonebook) {
                    this.selectedPositions = [data];
                    this.submit();
                } else {
                    this.getPositions();
                }
            });
    }

    getPositions() {
        this.api.getFolderPositionsTree()
            .pipe(takeUntil(this.destroyed))
            .subscribe((data) => {
                // Отсортируем все по названиям
                data.folders.forEach(folder => {
                    Position.prototype.sortPositions(folder.positions);
                });

                if (this.folders) {
                    data.folders.forEach(el => el.positions = []);
                    this.flatPositionFolders = [...data.folders];
                    if (this.parentId) {
                        const index = data.folders.findIndex(el => +el.id === +this.parentId);
                        this.selectedPositions = index >= 0 ? [data.folders[index]] : [];
                    }

                }
                this.positions = [].concat(...data.folders.map(el => el.positions));
                const tree = Position.prototype.makeTreeNode(data.folders);
                this.positionFolders = tree;
                this.isLoaded = true;
            });
    }


    onCreateClick() {
        this.company.onCreatePositionClick$.next(true);
    }

    onSearchInputPosition(res) {
        this.searchStr = res;
        if (res && res.length) {
            if (this.folders) {
                this.sortedFolders = this.filterValues<PositionFolder>(res, this.flatPositionFolders);
            } else {
                this.sortedFolders = this.filterValues<PositionFolder>(res, this.positionFolders);
                this.sortedPositions = this.filterValues<Position>(res, this.positions);

                this.sortedFolders.sort((a, b) => {
                    return this.strPos(a.title, res) - this.strPos(b.title, res);
                });

                this.sortedPositions.sort((a, b) => {
                    return this.strPos(a.title, res) - this.strPos(b.title, res);
                });

                const tree = Position.prototype.makeTreeNode(this.sortedFolders);
                this.sortedFolders = tree;
            }
        } else {
            this.sortedFolders = null;
            this.sortedPositions = null;
        }
    }

    close() {
        this.dialogRef.close();
    }

    submit() {
        this.dialogRef.close(this.selectedPositions);
    }

    onDeletePosition(item: Position) {
        this.selectedPositions = this.selectedPositions.filter(el => el.id !== item.id);
    }

    onSelectedChanges(res) {
        if (this.folders) {
            this.selectedPositions = res ? [res] : [];
        } else {
            this.selectedPositions = res;
        }
    }

    filterValues<Type>(searchTerm, baseList: Type[]): Type[] {
        let newList;

        if (searchTerm) {
            const terms_str = searchTerm.toLowerCase()
                .split(' ')
                .map(i => i.trim())
                .filter(i => i);
            newList = baseList.filter(
                item => terms_str.every(
                    term => this.testItem<Type>(item, term)
                )
            );
        } else {
            newList = baseList;
        }

        return newList;
    }


    testItem<Type>(item: Type, term: string) {
        return item && (this.testString(item['title'], term));
    }

    testString(value: string, term: string) {
        if (!!value) {
            return value.toString().toLowerCase().includes(term);
        }
        return false;
    }


    strPos(str, term) {
        return str.toLowerCase().indexOf(term.toLowerCase());
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

}
