import {Component, Inject, OnInit} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef
} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from 'src/app/services/auth.service';
import {GlobalNotificationCenterService} from 'src/app/services/global-notification-center.service';

@Component({
    selector: 'app-dialog-invite-success',
    templateUrl: './dialog-invite-success.component.html',
    styleUrls: ['./dialog-invite-success.component.scss']
})
export class DialogInviteSuccessComponent implements OnInit {

    public title = '';
    public isSuccessful = false;
    public isLoaded = false;
    public errorMessage;
    readonly SUCCESS = 'Запрос на повторное подтвержение почты отправлен';
    private destroyed = new Subject<void>();

    constructor(
        private dialog: MatDialog,
        private auth: AuthService,
        private notiService: GlobalNotificationCenterService,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    ngOnInit(): void {
        this.auth.createInviteEmail({user:{id:this.data.userId}, account:this.data.account.account})
                .pipe(takeUntil(this.destroyed))
                .subscribe(res => {
                    this.isSuccessful = true;
                    this.isLoaded = true;
                }, (err) => {
                    this.errorMessage = err.error.message;
                    this.isLoaded = true;
                });
    }

    get content() {
        return this.isSuccessful ? this.SUCCESS : this.errorMessage;
    }
    close() {
        this.dialogRef.close();
    }

}
