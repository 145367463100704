import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {AuthService} from './auth.service';
import {JSON} from 'ta-json';
import {forkJoin, Observable, Subject} from 'rxjs';
import {OrgUnitShort} from '../models/orgUnit';
import {environment} from '../../environments/environment';
import {User} from '../models/user';
import {ConfigService} from './config.service';

@Injectable()
export class UserInfoService {

    readonly authApiUrl: string = environment.authApiUrl;
    readonly circlesApiUrl: string = environment.circlesApi;
    readonly usersApiAuthUrl: string = environment.authApiUrl + 'users/';

    readonly filesStorageApiUrl: string = environment.fileStorageURL;
    public allOrgUnitsExceptMembers;
    public allOrgUnits;
    public divisionsHashMap = {};
    public dataLoaded$ = new Subject<void>();

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private config: ConfigService
    ) {
    }

    authorize(options: {
        headers?: HttpHeaders;
        params?: HttpParams;
    } = {}) {
        if (!options.headers) {
            options.headers = new HttpHeaders();
        }
        const auth = this.auth.auth;
        if (auth && auth.apiKey) {
            options.headers = options.headers.set('Authorization', auth.apiKey);
        }
        return options;
    }

    /**
     * Запрос на проверку существования такого же мэйла (подтвержденного) у другого пользователя
     */
    public isMailTaken(mail: string) {
        return this.http.post(
            this.authApiUrl + '/emails/info',
            {email: mail},
            this.authorize()
        );
    }

    /** Получает текущего пользователя с бэка, а также данные по подразделениям
     * Также обновляет auth
     */
    public getPersonForAboutMe() {
        forkJoin(
            this.auth.fetchUserInfo(),
            this.getCircles('00000000-0000-0000-0000-000000000001'), // круг верхнего уровня
    )
            .subscribe(data => {
                const person = JSON.deserialize<User>(data[0]['payload'], User);
                this.saveAllOrgUnits(data[1]);

                this.dataLoaded$.next();
            });
    }

    /**
     * Запрос на получение полного списка организаций
     * Получение кругов по упрощенной модели
     */
    public getCircles(id): Observable<OrgUnitShort> {
        return this.http.get<OrgUnitShort>(
            this.circlesApiUrl + `/${id}/`,
            this.authorize()
        );
    }

    /** Сохраняет данные по всем орг-юнитам */
    public saveAllOrgUnits(res: OrgUnitShort) {
        if (!res) {
            res = new OrgUnitShort();
        }
        this.allOrgUnits = res.subordinates.map(el => {
            const desEl = JSON.deserialize<OrgUnitShort>(el, OrgUnitShort);
            // Убираем пользователей, они тут не нужны
            if (desEl.type === 'Member') {
                return null;
            }
            this.addDivisionsChildren(desEl);
            return desEl;
        });
        this.allOrgUnitsExceptMembers = this.getCompaniesAndDivisions();
    }

    /** Получает орг юниты, за исключением chat-members */
    public getCompaniesAndDivisions() {
        return this.allOrgUnits.map(el => this.excludeMember(el)).filter(el => !!el);
    }


    private excludeMember(orgUnit: OrgUnitShort) {
        if (orgUnit.type === 'Member') {
            return null;
        }
        orgUnit.subordinates = orgUnit.subordinates.map(el => this.excludeMember(el)).filter(el => !!el);
        return orgUnit;
    }

    /**
     * Записывает в хэшмэп переданное подразделение и все активные подчиненные подразделения
     *
     * @param division - orgUnit для которого ищем подчиненные
     * @param orgId - id организации
     */
    private addDivisionsChildren(division: OrgUnitShort, orgId?: string) {
        // Убираем пользователей, они тут не нужны
        if (division.type === 'Member') {
            return;
        }
        this.divisionsHashMap[division.id] = division;
        if (orgId) {
            division.orgId = orgId;
        } else {
            /** Значит это верхнего уровня группа, надо сохранить для потомков орг-айди
             * Ответ от держателя информации: организация, если она организация, может быть только верхнего уровня
             */
            orgId = division.id;
        }
        if (division.subordinates) {
            division.subordinates.forEach(el => this.addDivisionsChildren(el, orgId));
        }
    }
}
