import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Board} from '../../../task-manager/models/board';

import {MatDialog} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BoardsAddComponent} from '../boards/boards-add/boards-add.component';
import {TagsService} from '../../../task-manager/services/tags.service';

@Component({
    selector: 'app-boards-for-meet',
    templateUrl: './boards.component.html',
    styleUrls: ['./boards.component.scss']
})
export class BoardsForMeetComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public boards: Board[] = [];
    @Input() public disabled = false;
    @Input() public tagsDisabled = false;
    @Input() public isEditMode = true;
    @Input() public isTemplate = false;
    @Input() public title = 'Доски';

    @Output() public boardAdd = new EventEmitter<Board>();
    @Output() public boardDel = new EventEmitter<Board>();
    @Output() public groupAdd = new EventEmitter<any>();
    @Output() public groupDel = new EventEmitter<any>();
    @Output() public boardsUpdate = new EventEmitter<any>();

    public isOpened = false;
    // Триггер смерти компонента
    private destroyed = new Subject<void>();
    public isPersonalBoardIsset = false;
    @Input() onlyOne: boolean = false;


    constructor(
        private dialog: MatDialog,
        private tagsService: TagsService,
    ) {
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.boards) {
            this.boards = changes.boards.currentValue;
            this.boards.forEach(b => {
                if (this.isTemplate && ((b.sharedBoard && b.template_board) || b.id < 0)) {
                    b.visible = false;
                }
            });

            setTimeout(() => {
                this.isPersonalBoardIsset =  this.boards.some(el => !el.visible);
            });
        }
    }

    boardsLength() {
        return this.boards.filter(el => el?.visible)?.length;
    }

    openBoardsAdd() {
        const dialogForm = this.dialog.open(BoardsAddComponent, {
            data: {selected: this.boards},
        });

        dialogForm.afterClosed().pipe(takeUntil(this.destroyed))
            .subscribe(board => {
                if (board) {
                    this.boardAdd.emit(board);
                }
            });
    }


    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    onGroupAdd($event) {
        this.groupAdd.emit($event);
    }

    onBoardDel($event) {
        this.boardDel.emit($event);
    }

    onGroupDel($event: any) {
        this.groupDel.emit($event);
    }

    onBoardsUpdate($event: any) {
        this.boardsUpdate.emit($event);
    }

    onBoardAdd($event: any) {
        this.boardAdd.emit($event);
    }
}
