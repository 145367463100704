import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ApiBoardsService} from '../../../../task-manager/services/api-boards.service';
import {GlobalNotificationCenterService} from '../../../../../services/global-notification-center.service';
import {TagsService} from '../../../../task-manager/services/tags.service';
import {BoardsService} from '../../../../task-manager/services/boards.service';
import {GroupsService} from '../../../../task-manager/services/groups.service';

@Component({
  selector: 'app-group-list-item',
  templateUrl: './group-list-item.component.html',
  styleUrls: ['./group-list-item.component.scss']
})
export class GroupListItemComponent implements OnInit {
    @Input() public group = null;
    @Output() public readonly onEdit: EventEmitter<any> = new EventEmitter();
    @Output() public readonly onDelete: EventEmitter<any> = new EventEmitter();

    constructor(
        private dialog: MatDialog,
        private api: ApiBoardsService,
        private notiService: GlobalNotificationCenterService,
        private tagsService: TagsService,
        private boardsService: BoardsService,
        private groupsService: GroupsService
    ) {
    }

    ngOnInit() {
    }

    getItemColorFromBoard() {
        return this.groupsService.getGroupBackground(this.group.color);
    }

}
