import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs';
import moment from 'moment-timezone';
import {AuthService} from '../../../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {
    settings = {
        'place': true,
        'protocol': true,
        'tasks': true,
        'tasksMode': 'one-day',
        'protocolApprove': true,
        'protocolPrint': true,
    };

    settingsChanged = new Subject();
    updateAttention = new BehaviorSubject(null);
    attentionCount = new BehaviorSubject(0);
    attentionList = new BehaviorSubject([]);
    closeNoticeable = new BehaviorSubject(false);

    hasPaidAccess = new BehaviorSubject(false);
    meetUsers = new BehaviorSubject([]);
    updateProtocolBoards$= new Subject();
    meet$= new BehaviorSubject(null);
    viewDate = new BehaviorSubject(new Date());
    forceUpdateCard$ = new Subject<string>();
    closeChat = new Subject<boolean>();

     constructor(private auth: AuthService) {
     }

    hasPaidAccessToCalendar(): boolean {
        return !!this.hasPaidAccess.getValue();
    }

    saveSettings() {
        this.settings = {...this.settings};
        localStorage.setItem('calendar-settings', JSON.stringify(this.settings));
        this.settingsChanged.next(this.settings);
    }

    loadSettings() {
        const loadedStr = localStorage.getItem('calendar-settings');
        if (loadedStr) {
            const loadedObj = JSON.parse(loadedStr);

            // this.settings = {
            //     ...loadedObj,
            // }

            Object.keys(this.settings).map(key => {
                if (loadedObj[key] !== undefined && loadedObj[key] !== null) {
                    this.settings[key] = loadedObj[key];
                }
            });

            this.settingsChanged.next(this.settings);
        }
    }

    setPaidAccess(arr: string[]) {
        this.hasPaidAccess.next(arr?.includes('calendar'));
    }

    getUserTimezone() {
        return this.auth.timeZone$.getValue();
    }

    getDSTOffset(tz) {
        // const dec = moment.tz('2018-12-04', tz).utcOffset();
        // const jun = moment.tz('2018-06-04', tz).utcOffset();
        // return Math.max(dec, jun);
        return 0;
    }

    getUserTimezoneOffset() {
        const tz = this.getUserTimezone();
        const offsetUTC = moment.tz(tz).utcOffset();

        let offsetDST = 0;

        const today = moment();

        if (moment.tz(today, tz).isDST()) {
            offsetDST = this.getDSTOffset(tz);
        }

        const result = offsetUTC + offsetDST;

        return result;
    }

    fromUTCToLocal(srcDate: any) {
        const offset = this.getUserTimezoneOffset();

        const utc = moment.utc(srcDate);
        const local = utc.add(offset, 'minutes');

        return local.local(true);
    }

    fromLocalToUTC(srcDate: any) {
        const offset = this.getUserTimezoneOffset();

        const local = moment(srcDate);
        const utc = local.subtract(offset, 'minutes');

        return utc;
    }
}
