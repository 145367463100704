import {Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard  {
    constructor(
        public dialog: MatDialog,
    ) {
    }


    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        // if there are no pending changes, just allow deactivation; else confirm first
        const resp = component.canDeactivate();

        return resp;
    }
}
