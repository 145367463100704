import {JsonProperty, JsonType} from 'ta-json';

export class WorkdaySegment {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('date_slot_id')
    @JsonType(Number)
    date_slot_id: number = null;

    @JsonProperty('time_start')
    @JsonType(String)
    time_start: string = null;

    @JsonProperty('time_end')
    @JsonType(String)
    time_end: string = null;

    @JsonProperty('comment')
    @JsonType(String)
    comment: string = null;

    @JsonProperty('title')
    @JsonType(String)
    title: string = null;

    @JsonProperty('order')
    @JsonType(Number)
    order: number = null;

    @JsonProperty('function_id')
    @JsonType(Number)
    function_id: number = null;

    totalWorkTime() {
        const start = this.time_start;
        const end = this.time_end;

        if (!start || !end) {
            return null;
        }

        let arrStart = start.split(':');
        let arrEnd = end.split(':');

        let startMinutes = parseFloat(arrStart[0]) * 60 + parseFloat(arrStart[1]);
        let endMinutes = parseFloat(arrEnd[0]) * 60 + parseFloat(arrEnd[1]);
        return endMinutes - startMinutes;
    }
}
