<span class="user" *ngIf="user"
      [matTooltip]="user ? user.shortName : title">

    <app-user-avatar class="user-img"
                     [ngClass]="{'_small': small}"
                     [user]="user"></app-user-avatar>

    <mat-icon *ngIf="more && !responsive" class="icon users-more">more_horiz</mat-icon>
    <mat-icon *ngIf='responsive' class="responsive" svgIcon="radius_star-border"></mat-icon>
</span>
