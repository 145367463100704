import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {ConfigService} from '../../../services/config.service';
import {NotifierService} from 'gramli-angular-notifier';
import {Task} from '../models/task';

@Injectable({
    providedIn: 'root'
})
export class ConfigBoardsService extends ConfigService {
    readonly MATRIX_URL = '/apps/phonebook/users/motivations/matrix/';
    readonly DASHBOARD_URL = '/apps/dashboard';
    readonly CABINET_MATRIX_URL = '/cabinet/matrix/self-matrix/cabinet-matrix/';
    constructor(notifierService: NotifierService) {
        super(notifierService);
    }


    readonly taskManagerUrl = environment.taskManagerUrl;
    readonly taskManagerTaskUrl = environment.taskManagerTaskUrl;
    readonly taskManagerBoardUrl = environment.taskManagerBoardUrl;
    readonly taskManagerTagUrl = environment.taskManagerTagUrl;
    readonly knowledgeBaseFiles = environment.knowledgeBaseFiles;
    readonly SUCCESS_TASK_CREATE = 'Задача создана';
    readonly SUCCESS_BOARD_CREATE = 'Доска создана';
    readonly SUCCESS_TASK_DELETE = 'Задача удалена';
    readonly SUCCESS_SPRINT_DELETE = 'Спринт удален';
    readonly SUCCESS_TASK_TEMPLATE_DELETE = 'Шаблон удален';
    readonly SUCCESS_BOARD_DELETE = 'Доска перенесена в архив';
    readonly SUCCESS_TASK_UPDATE = 'Задача обновлена';
    readonly SUCCESS_BOARD_UPDATE = 'Доска обновлена';
    readonly SUCCESS_TASK_CANCEL = 'Задача отменена';
    readonly SUCCESS_TEMPLATE_CANCEL = 'Шаблон отменен';
    readonly SUCCESS_TASK_CONFIRM_CANCEL = 'Задача на согласовании отмены';
    readonly SUCCESS_TASK_RETURN = 'Задача возвращена в работу';
    readonly SUCCESS_TEMPLATE_RETURN = 'Шаблон возвращен в работу';
    readonly FORM_INVALID = 'Все обязательные поля должны быть заполнены';
    readonly FORM_INVALID_35 = 'Максимальная длина названия не должна содержать более 35 символов';
    readonly FORM_INVALID_150 = 'Максимальная длина названия не должна содержать более 150 символов';
    readonly FORM_INVALID_1500 = 'Максимальная длина названия не должна содержать более 1500 символов';
    readonly FORM_INVALID_MAXLENGTH = 'Максимальная длина не должна превышать'
    readonly FORM_INVALID_100 = 'Максимальная длина названия не должна содержать более 100 символов';
    readonly FORM_OWNERS_INVALID = 'У доски должен быть хотя-бы один владелец';
    readonly FORM_INVALID_ASSESSMENT = 'Суммарная оценка задачи не может быть меньше 0 или больше 999';
    readonly FORM_INVALID_DATE = 'Выставьте дату выполнения, чтобы принять задачу в работу';
    readonly FORM_INVALID_TEXT_MAX_VALUE = 'Превышено количество символов';
    readonly BOARDS_URL = 'apps/boards';
    readonly BOARDS_CABINET_URL = 'cabinet-company/access-rights/';
    readonly NOTICEABLE_URL = 'apps/boards/noticeable/';
    readonly NOTICEABLE_TASK_URL_VID = 'apps/boards/noticeable/tasks/';
    readonly TASK_URL = 'apps/boards/task/';
    readonly TASK_URL_VID = 'apps/boards/tasks/';
    readonly BOARD_URL = 'apps/boards/board/';
    readonly BOARD_CABINET_URL = 'cabinet-company/access-rights/board/';
    readonly NEW_TASK_URL = this.TASK_URL + 'new';
    readonly NEW_BOARD_URL = this.BOARD_URL + 'new';
    readonly IMPORT_URL = this.BOARD_URL + 'import';
    readonly TARIFFS_ERROR_CREATE_BOARD = 'Создание и работа на досках доступны на платном тарифе';
    readonly TARIFFS_ERROR_CREATE_TEMPLATE = 'Создание повторяющейся задачи недоступно на бесплатном тарифе';
    readonly TARIFFS_ERROR_APPROVER_ADD = 'Согласование и приемка задач доступны для сотрудников на платном тарифе';
    readonly TARIFFS_ERROR_CREATE_SUBTASK = 'Создание и преобразование в подзадачи доступно на платном тарифе';
    readonly TARIFFS_ERROR_ANY = 'Функционал доступен на платном тарифе';
    readonly TARIFFS_ERROR_CONTROL = 'Контроль задач доступен на платном тарифе';
    readonly TARIFFS_ERROR_BOARD_VIEW = 'Данный режим отображения доступен на платном тарифе';
    readonly BOARD_OPEN_ERROR = 'Упс, кажется у вас нет прав на редактирование этой доски';
    /** API URL фото юзера */
    readonly userPhotoURL: string = environment.userPhotoURL;
    getUserPhoto(userID) {
        return this.userPhotoURL + `/${userID}/small`;
    }

    public IMPORTANCE_TYPE = [
        // тактический
        {icon: 'radius_importance3', title: 'Высокий', id: 2, group_title: 'Тактическая'},
        {icon: 'radius_importance2', title: 'Средний', id: 3, group_title: 'Тактическая'},
        {icon: '', title: 'Без приоритета', id: 0, group_title: 'Тактическая'},

        // стратегический
        {icon: 'radius_strategic_red', title: 'Высокий', id: 1, group_title: 'Стратегическая'},
        {icon: 'radius_strategic_yellow', title: 'Средний', id: 4, group_title: 'Стратегическая'},
        {icon: 'radius_strategic_grey', title: 'Без приоритета', id: 7, group_title: 'Стратегическая'},

        // качество
        {icon: 'radius_bug_red', title: 'Высокий', id: 6, group_title: 'Баг'},
        {icon: 'radius_bug_yellow', title: 'Средний', id: 8, group_title: 'Баг'},
        {icon: 'radius_bug', title: 'Без приоритета', id: 5, group_title: 'Баг'},
    ];

    public getImportanceById(id) {
        const type = this.IMPORTANCE_TYPE.find(el => el.id === id);
        return type ? type : this.IMPORTANCE_TYPE[2];
    }

    public getImportanceTooltip(id) {
        if (id) {
            const importance = this.getImportanceById(id);
            return importance.group_title + ': ' + importance.title;
        } else {
            return 'Тактическая: Без приоритета';
        }
    }

    public singleSelect(task: Task, selections) {
        const alreadySelected = selections.some(el => el.id === task.id);
        if (alreadySelected) {
            selections = selections.filter(el => el.id !== task.id);
            task['selected'] = false;
        } else {
            selections.push(task);
            task['selected'] = true;
        }
    }

}


