import {Component, Inject, OnInit} from '@angular/core';
import {base64ToFile, ImageCroppedEvent} from 'ngx-image-cropper';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent implements OnInit {
    imgChangeEvt: any = '';
    cropImgPreview: any = '';

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    ngOnInit() {
    }

    cropImg(e: ImageCroppedEvent) {
        this.cropImgPreview = e.base64;
        // this.imgChangeEvt = e;
        this.imgChangeEvt = base64ToFile(e.base64);

    }

    onSubmit() {
        this.dialogRef.close(this.imgChangeEvt);
    }

    onSave() {
        this.dialogRef.close(this.data.target.files[0]);
    }

    close() {
        this.dialogRef.close();
    }
}
