import * as moment from 'moment/moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { AcademyService } from 'src/app/modules/academy/services/academy.service';

import {
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { first, skip, takeUntil } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ApiCabinetService } from '../../modules/cabinet-company/services/api-cabinet.service';
import { CalendarService } from '../../modules/calendar/services/calendar.service';
import { SharedService } from '../../modules/shared/services/shared.service';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { ChatsService } from '../../services/chats.service';
import { ConfigService } from '../../services/config.service';
import { GlobalNotificationCenterService } from '../../services/global-notification-center.service';
import { OnboardingService } from '../../services/onboarding.service';
import { ApiAcademyService } from './../../modules/academy/services/api-academy.service';
import {QuestionShort} from '../../modules/questions/models/question-short';
import {QuestionParamType} from '../../modules/questions/models/question-param';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Output() openChats = new EventEmitter<boolean>();
    @Output() openNotificationCenter = new EventEmitter<boolean>();
    @Output() openQuestions = new EventEmitter<boolean>();
    public newMessage: number = 0;
    public newNotification: number = 0;
    public newQuestions: number = 0;
    public fileStorage = environment.fileStorageURL;
    public learningCounter: number = 0;
    public motivationCounter: number = 0;
    public matrixCounter: number = 0;
    public financeCounter = 0;
    public tusksCounter: number = 0;
    public usersQuestionsCount: number = 0;
    public boardsCounter: number = 0;
    public academyCounter: number = 0;
    private destroyed = new Subject<void>();
    public apps = null;
    public avatar = null;
    isMobile = false;
    prod = false;
    motivationCabinetCounter: number = 0;
    matrixCabinetCounter: number = 0;
    calCounter: number = 99;
    topArrow = false;
    bottomArrow = true;

    @ViewChild('nav', { static: true }) nav!: ElementRef;
    tariffInfo = null;
    hover = false;
    forceShow = false;
    public user = null;

    constructor(
        public auth: AuthService,
        public api: ApiService,
        public chatsService: ChatsService,
        private notificationCenterService: GlobalNotificationCenterService,
        public config: ConfigService,
        private router: Router,
        private onboarding: OnboardingService,
        private shared: SharedService,
        private deviceDetector: DeviceDetectorService,
        private calServ: CalendarService,
        private tariffApi: ApiCabinetService,
        private academyService: AcademyService,
        private apiAcademyService: ApiAcademyService,
    ) {
        this.isMobile = this.deviceDetector.isMobile();
    }

    get isNpm() {
        return this.auth.isNpm$.value;
    }

    get isTasks() {
        return this.router.url.includes('boards');
    }

    get isXmass() {
        const today = new Date();
        return today.getMonth() == 11 || today.getMonth() < 1;
    }

    ngOnInit() {
        this.prod = environment.production;

        this.auth.currentUser$
            .pipe(takeUntil(this.destroyed))
            .subscribe((param) => {
                this.avatar = null;
                this.user = param;
                setTimeout(() => {
                    this.avatar = this.config.getUserAvatarByUser(
                        param,
                        'small',
                    );
                }, 1);
            });

        this.chatsService.chatsHeaderMessageCount$
            .pipe(takeUntil(this.destroyed))
            .subscribe((param) => {
                this.newMessage = param;
            });

        this.notificationCenterService.notificationsHeaderMessageCount$
            .pipe(takeUntil(this.destroyed))
            .subscribe((param) => {
                this.newNotification = param;
            });

        this.notificationCenterService.financeCount$
            .pipe(takeUntil(this.destroyed))
            .subscribe((param) => {
                this.financeCounter = param;
            });

        this.notificationCenterService.learningCount$
            .pipe(takeUntil(this.destroyed))
            .subscribe((param) => {
                this.learningCounter = param;
            });

        this.notificationCenterService.motivationCount$
            .pipe(takeUntil(this.destroyed))
            .subscribe((param) => {
                this.motivationCounter = param;
            });

        this.notificationCenterService.motivationCabinetCount$
            .pipe(takeUntil(this.destroyed))
            .subscribe((param) => {
                this.motivationCabinetCounter = param;
            });

        this.notificationCenterService.matrixCabinetCount$
            .pipe(takeUntil(this.destroyed))
            .subscribe((param) => {
                this.matrixCabinetCounter = param;
            });

        this.notificationCenterService.matrixCount$
            .pipe(takeUntil(this.destroyed))
            .subscribe((param) => {
                this.matrixCounter = param;
            });

        this.notificationCenterService.tasksCount$
            .pipe(takeUntil(this.destroyed))
            .subscribe((param) => {
                this.tusksCounter = param;
            });

        this.notificationCenterService.usersQuestionsCount$
            .pipe(takeUntil(this.destroyed))
            .subscribe((param) => {
                this.usersQuestionsCount = param;
            });

        this.notificationCenterService.boardsCount$
            .pipe(takeUntil(this.destroyed))
            .subscribe((param) => {
                this.boardsCounter = param;
            });

        this.academyService.counterOfNotViewedTests$
            .pipe(takeUntil(this.destroyed))
            .subscribe((counter) => {
                this.academyCounter = counter;
            });

        this.apiAcademyService
            .getQuestionsFromCenter()
            .pipe(takeUntil(this.destroyed))
            .subscribe((questions: QuestionParamType[]) => {
                this.newQuestions = questions.filter(question => !question.user_question_assignment.answer).length;
            });
        this.apiAcademyService
            .getAssignmentTests()
            .pipe(first())
            .subscribe((assignments: any) => {
                this.academyService.setAssignmentsCounter(assignments);
            });

        this.apps = this.api.appList$.getValue();

        if (!this.apps || !this.apps.length) {
            this.api
                .getApplications()
                .pipe(takeUntil(this.destroyed))
                .subscribe((apps) => {
                    this.api.appList$.next(apps);
                    this.apps = apps;
                });
        }

        this.calServ.attentionCount
            .pipe(takeUntil(this.destroyed))
            .subscribe((resp) => {
                this.calCounter = resp;
            });

        this.tariffApi
            .getUserInfo()
            .pipe(takeUntil(this.destroyed))
            .subscribe(
                (info) => {
                    this.tariffApi.tariffInfo$.next(info);
                },
                (err) => {
                    //this.hasErrorApi = true;
                    this.notificationCenterService.handleFullError(err);
                },
            );

        this.tariffApi.tariffInfo$.pipe(takeUntil(this.destroyed)).subscribe(
            (info) => {
                if (info?.is_read == false) {
                    this.forceShow = true;
                }

                this.tariffInfo = info;
            },
            (err) => {
                this.notificationCenterService.handleFullError(err);
            },
        );

        this.hover = false;
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    getIcon(link) {
        return this.fileStorage + 'files' + link.logo;
    }

    onHintClick($event: MouseEvent) {
        $event.preventDefault();

        if (this.router.url.includes('users')) {
            this.onboarding.setViewed('users', false);
        } /*else if (this.router.url.includes('company')) {
            this.onboarding.setViewed('company', false);
        } */ else if (this.router.url.includes('boards')) {
            this.onboarding.setViewed('tasks', false);
        } else if (this.router.url.includes('news')) {
            this.onboarding.setViewed('main', false);
        } else if (this.router.url.includes('dashboard')) {
            this.onboarding.setViewed('main', false);
        } else {
            this.onboarding.setViewed('common', false);
        }
    }

    onLearningClick($event: MouseEvent) {
        $event.preventDefault();

        this.api.closeSidebar();

        if (this.router.url.includes('boards')) {
            this.shared.openTaskLearning$.next(true);
        }
    }

    get isLearning(): boolean {
        return this.shared.openTaskLearning$.value;
    }

    scrollEv() {
        const nav = this.nav.nativeElement;
        this.topArrow = nav.scrollTop > 0;

        this.bottomArrow = nav.scrollHeight > nav.scrollTop + nav.clientHeight;
    }

    scrollDown() {
        const nav = this.nav.nativeElement;
        nav.scrollTop =
            nav.scrollTop + 100 < nav.scrollHeight
                ? nav.scrollTop + 100
                : nav.scrollHeight;
    }

    scrollUp() {
        const nav = this.nav.nativeElement;
        nav.scrollTop = nav.scrollTop - 100 > 0 ? nav.scrollTop - 100 : 0;
    }

    isFreeTariff(tariffInfo) {
        return tariffInfo.is_free && !this.isGiftTariff(tariffInfo);
    }

    isGiftTariff(tariffInfo) {
        return tariffInfo.is_favorite;
    }

    isAdminTariff(tariffInfo) {
        return tariffInfo.is_admin;
    }

    dateFormat(date) {
        const dt = moment(date);

        if (dt.isValid()) {
            return dt.format('DD.MM.YYYY');
        } else return 'Бессрочно';
    }

    getModules(modules) {
        return modules.join(', ');
    }

    onTariffClose() {
        this.forceShow = false;
        this.api
            .setTariffMsgViewed('user_tariff_info')
            .pipe(takeUntil(this.destroyed))
            .subscribe((resp) => {});
    }
}
