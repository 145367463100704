import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';
import {UserInfoService} from '../../../services/user-info.service';
import {AuthService} from '../../../services/auth.service';
import {NotifierService} from 'gramli-angular-notifier';
import {map} from 'rxjs/operators';
import {JSON} from 'ta-json';
import {MotivationsSettings} from '../models/motivations/motivations-settings';
import {MotivationsCustomField} from '../models/motivations/motivations-custom-field';
import {MotivationsSettingsCompany} from '../models/motivations/motivations-settings-company';
import {Rights} from '../../../models/user';
import {MotivationSubdivision, UserCardMotiv} from '../models/motivations/motivation-subdivision';
import {LegalEntity, UsersMotivation} from '../models/motivations/users-motivation';
import {Motivation} from '../models/motivations/motivation';
import {
    MatrixEmployeeNew,
    MatrixEmployment,
    MatrixSubdivision,
    MatrixSubdivisionNew,
    MatrixUserRespElement
} from '../models/matrix/matrix-subdivision';
import {Matrix, MatrixLeadsBonus, MatrixMetrics, MatrixSetting, MetricRange} from '../models/matrix/matrix';
import * as moment from 'moment';
import {StorageService} from '../../../services/storage.service';
import {KPITemplate} from '../components/matrices/components/matrices-card/components/metrics/add-metrics/add-metrics.component';
import {formatDate} from '@angular/common';

@Injectable()
export class ApiMotivationsService extends ApiService {

    constructor(
        readonly http: HttpClient,
        public config: ConfigService,
        public userInfo: UserInfoService,
        public auth: AuthService,
        public notifierService: NotifierService,
        private storage: StorageService,
        ) {
        super(http, config, userInfo, auth, notifierService);
    }

    getMotivationByIdAsText(id) {
        return this.http.get(
            `${this.config.motivations}motivations/${id}`,
            this.auth.authorize(),
        ).pipe(map(res => {
            if (!res['payload']) {
                throw new Error(`Мотивации ${id} не существует`);
            }

            return res['payload'];
        }));
    }

    getMotivationById(id) {
        return this.http.get(
            `${this.config.motivations}motivations/${id}`,
            this.auth.authorize(),
        ).pipe(map(res => {
            if (!res['payload']) {
                throw new Error(`Мотивации ${id} не существует`);
            }

            return {
                payload: JSON.deserialize<Motivation>(res['payload'], Motivation),
                rights: JSON.deserialize<Rights>(res['rights'], Rights),
            };
        }));
    }

    approveMotivationById(id) {
        return this.http.put(
            `${this.config.motivations}motivations/${id}/approve`,
            {},
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<Motivation>(res['payload'], Motivation)));
    }

    readMotivationById(id) {
        return this.http.put(
            `${this.config.motivations}motivations/${id}/read`,
            {},
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<Motivation>(res['payload'], Motivation)));
    }

    cancelApproveMotivationById(id) {
        return this.http.put(
            `${this.config.motivations}motivations/${id}/cancel_approve`,
            {},
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<Motivation>(res['payload'], Motivation)));
    }

    cancelMotivationById(id) {
        return this.http.post(
            `${this.config.motivations}motivations/${id}/cancel`,
            {},
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<Motivation>(res['payload'], Motivation)));
    }

    getMotivations(str = null, subordinatesFilter) {
        let args = [];

        if (str) {
            args.push(`search=${str}`);
        }

        if (subordinatesFilter) {
            args.push(`subordinates_only=True`);
        }

        const query = args.length > 0 ? '?' + args.join('&') : '';

        return this.http.get(
            `${this.config.motivations}users_motivation/staffing/${query}`,
            this.auth.authorize(),
        ).pipe(map(res =>
            ({
                payload: res['payload'] ? JSON.deserialize<MotivationSubdivision[]>(res['payload'], MotivationSubdivision) : [],
                rights: JSON.deserialize<Rights>(res['rights'], Rights)
            })
        ));
    }

    getMotivationsUnApproved(str = null, subordinatesFilter = false) {
        let args = [];

        if (str) {
            args.push(`search=${str}`);
        }

        if (subordinatesFilter) {
            args.push(`subordinates_only=True`);
        }

        const query = args.length > 0 ? '?' + args.join('&') : '';

        return this.http.get(
            `${this.config.motivations}motivations/unapproved${query}`,
            this.auth.authorize(),
        ).pipe(map(res => res['payload'] ? JSON.deserialize<MotivationSubdivision[]>(res['payload'], MotivationSubdivision) : []));
    }

    importCompany() {
        return this.http.post(
            `${this.config.motivations}users_motivation/import_company/`,
            null,
            this.auth.authorize(),
        ).pipe(map(res => res));
    }

    editMotivationById(data, id) {
        return this.http.put(
            `${this.config.motivations}motivations/${id}`,
            data,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<Motivation>(res['payload'], Motivation)));
    }

    createMotivation(data) {
        return this.http.post(
            `${this.config.motivations}motivations/`,
            data,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<Motivation>(res['payload'], Motivation)));
    }

    deleteMotivationById(id) {
        return this.http.delete(
            `${this.config.motivations}motivations/${id}`,
            this.auth.authorize(),
        );
    }

    getUsersMotivationInfo() {
        return this.http.get(
            `${this.config.motivations}users_motivation/info/`,
            this.auth.authorize(),
        ).pipe(map(res => res['payload']))
            .pipe(map(res => res ? JSON.deserialize<UserCardMotiv>(res, UserCardMotiv) : null));
    }

    getLegalEntities() {
        return this.http.get(
            `${this.config.motivations}legal_entity/`,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<LegalEntity[]>(res['payload'], LegalEntity)));
    }

    createLegalEntities(title, company_id) {
        return this.http.post(
            `${this.config.motivations}legal_entity/`,
            {title, company_id},
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<LegalEntity>(res['payload'], LegalEntity)));
    }

    changeLegalEntities(id, title, company_id) {
        return this.http.put(
            `${this.config.motivations}legal_entity/${id}`,
            {id, title, company_id},
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<LegalEntity>(res['payload'], LegalEntity)));
    }

    getUsersCardById(id) {
        return this.http.get(
            `${this.config.motivations}users_motivation/?user_id=${id}`,
            this.auth.authorize(),
        ).pipe(map(res => {
            const card = JSON.deserialize<UserCardMotiv>(res['payload'], UserCardMotiv);

            return card;
        }));
    }

    duplicateUsersMotivationById(id) {
        return this.http.post(
            `${this.config.motivations}users_motivation/${id}`,
            null,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<UsersMotivation>(res['payload'], UsersMotivation)));
    }

    deleteUsersMotivationById(id) {
        return this.http.delete(
            `${this.config.motivations}users_motivation/${id}`,
            this.auth.authorize(),
        );
    }

    createUsersMotivationById(data) {
        return this.http.post(
            `${this.config.motivations}users_motivation/`,
            data,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<UsersMotivation>(res['payload'], UsersMotivation)));
    }

    getUsersMotivationById(id) {
        return this.http.get(
            `${this.config.motivations}users_motivation/${id}`,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<UsersMotivation>(res['payload'], UsersMotivation)));
    }

    editUsersMotivationById(data, id) {
        return this.http.put(
            `${this.config.motivations}users_motivation/${id}`,
            data,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<UsersMotivation>(res['payload'], UsersMotivation)));
    }

    getMotivationsSettings() {
        return this.http.get(
            `${this.config.motivationsSettings}/`,
            this.auth.authorize(),
        ).pipe(map(res => ({
                settings: JSON.deserialize<MotivationsSettings>(res['payload'], MotivationsSettings),
                rights: JSON.deserialize<Rights>(res['rights'], Rights)
            })));
    }

    editMotivationsSettings(data) {
        return this.http.put(
            `${this.config.motivationsSettings}/`,
            data,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<MotivationsSettings>(res['payload'], MotivationsSettings)));
    }

    getSettings(id, userId?) {
        let url = `${this.config.motivationsSettings}/company/subdivision/${id}`;

        if (userId) {
            url += `?for_user=${userId}`;
        }

        return this.http.get(
            url,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<MotivationsSettingsCompany>(res['payload'], MotivationsSettingsCompany)));
    }

    getMotivationsSettingsCompany() {
        return this.http.get(
            `${this.config.motivationsSettings}/company`,
            this.auth.authorize(),
        ).pipe(map(res => {
            if (res['payload']) {
                return JSON.deserialize<MotivationsSettingsCompany[]>(res['payload'], MotivationsSettingsCompany);
            } else {
                return null;
            }
        }));
    }

    editMotivationsSettingsCompany(data) {
        return this.http.put(
            `${this.config.motivationsSettings}/company`,
            data,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<MotivationsSettingsCompany>(res['payload'], MotivationsSettingsCompany)));
    }

    deleteMotivationsSettingsCompany(id) {
        return this.http.delete(
            `${this.config.motivationsSettings}/company/${id}`,
            this.auth.authorize(),
        );
    }

    getCustomFields() {
        return this.http.get(
            `${this.config.motivationsCustomFields}`,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<MotivationsCustomField[]>(res['payload'], MotivationsCustomField)));
    }

    createCustomField(data) {
        return this.http.post(
            `${this.config.motivationsCustomFields}/`,
            data,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<MotivationsCustomField>(res['payload'], MotivationsCustomField)));
    }

    editCustomFieldById(data, id) {
        return this.http.put(
            `${this.config.motivationsCustomFields}/${id}`,
            data,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<MotivationsCustomField>(res['payload'], MotivationsCustomField)));
    }

    deleteCustomFieldById(id) {
        return this.http.delete(
            `${this.config.motivationsCustomFields}/${id}`,
            this.auth.authorize(),
        );
    }

    getSearchMatrix(isOwner = false, date = null, search = null) {
        const dateParams = date ? `?from_date=${date}` : '';
        const searchParams = search ? dateParams.length ? `&search=${search}` : `?search=${search}` : '';
        const ownerParams = `&subordinates_only=${isOwner ? 'True': 'False'}`;
        const searchUrl = '';
        return this.http.get(
            `${this.config.matrixSubdivision}/${dateParams}${ownerParams}${searchParams}`,
            this.auth.authorize(),
        ).pipe(map(res => ({
            payload: JSON.deserialize<MatrixSubdivisionNew[]>(res['payload'], MatrixSubdivisionNew),
            rights: JSON.deserialize<Rights>(res['rights'], Rights),
        })));
    }

    getAllMatrix(date = null) {
        const dateParams = date ? `?from_date=${date}` : '';
        return this.http.get(
            `${this.config.matrixSubdivision}/${dateParams}`,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                    payload: JSON.deserialize<MatrixSubdivisionNew[]>(res['payload'], MatrixSubdivisionNew),
                    rights: JSON.deserialize<Rights>(res['rights'], Rights),
                })
        ));
    }

    getMatrixById(id, dateStart?) {
        const dateParams = dateStart ? `?from_date=${dateStart}` : '';
        return this.http.get(
            `${this.config.matrix}/${id}${dateParams}`,
            this.auth.authorize(),
        ).pipe(map(res => {
            const matrixInfo = JSON.deserialize<Matrix>(res['payload'], Matrix);
            matrixInfo.prev = res['prev'];
            matrixInfo.next = res['next'];

            matrixInfo.nextDot = res['next_attantion'];
            matrixInfo.prevDot = res['prev_attantion'];
            matrixInfo.related_matrix = JSON.deserialize<Matrix[]>(res['related_matrix'], Matrix);

            return matrixInfo;
        }));
    }

    getAllMatrixBySubdivision(subdivisionId, date = null) {
        const dateParams = date ? `?from_date=${date}` : '';
        return this.http.get(
            `${this.config.matrixSubdivision}/${subdivisionId}${dateParams}`,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<Matrix[]>(res['payload'], Matrix)));
    }

    updateDeputy(subdivisionId, data) {
        return this.http.put(
            `${this.config.deputy}/${subdivisionId}`,
            JSON.serialize(data),
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<MatrixSubdivision>(res['payload'], MatrixSubdivision)));
    }

    addTask(matrixId, taskId, isAdditional = false, forceApprover?) {
        const payload = {};

        if (!isAdditional) {
            payload['task'] = taskId;
        } else {
            payload['task'] = {task_id: taskId, starting_price:0};
            payload['smart_tasks_type'] = 'additional';
        }

        if (forceApprover) {
            payload['forced_approvers'] = forceApprover;
        }

        return this.http.post(
            `${this.config.matrix}/${matrixId}/smart_task`,
            JSON.serialize(payload),
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<Matrix>(res['payload'], Matrix)));
    }

    addTasksAdditionalBonus(matrixId, tasks) {
        return this.http.post(
            `${this.config.matrix}/${matrixId}/smart_task`,
            JSON.serialize({
                tasks: tasks.map(id => ({task_id: id, starting_price:0})),
                smart_tasks_type: 'additional',
            }),
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<Matrix>(res['payload'], Matrix)));
    }

    changeSmartTask(motivationId, taskId, data, date = null) {
        const dateParams = date ? `?from_date=${date}` : '';
        return this.http.put(
            `${this.config.matrixSmartTasks}/${motivationId}/task/${taskId}${dateParams}`,
            JSON.serialize(data),
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<Matrix>(res['payload'], Matrix)));
    }

    changeMatrix(motivationId, date, data) {
        const dateParams = date ? `?from_date=${date}` : '';
        return this.http.put(
            `${this.config.matrix}/${motivationId}${dateParams}`,
            JSON.serialize(data),
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<Matrix>(res['payload'], Matrix)));
    }

    deleteSmartTask(matrixId, taskId, date = null) {
        const dateParams = date ? `?from_date=${date}` : '';
        return this.http.delete(
            `${this.config.matrix}/${matrixId}/smart_task/${taskId}${dateParams}`,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<Matrix>(res['payload'], Matrix)));
    }

    approveMatrixEmployee(matrixId, approveFlag, planFlag) {
        const strApprove = approveFlag ? 'approve' : 'decline';
        const strPlan = planFlag ? 'plan' : 'fact';
        return this.http.put(
            `${this.config.matrix}/${matrixId}/${strApprove}/${strPlan}?is_executive_button=false`,
            null,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<Matrix>(res['payload'], Matrix)));
    }

    approveMatrixExecutive(matrixId, approveFlag, planFlag) {
        const strApprove = approveFlag ? 'approve' : 'decline';
        const strPlan = planFlag ? 'plan' : 'fact';
        return this.http.put(
            `${this.config.matrix}/${matrixId}/${strApprove}/${strPlan}?is_executive_button=true`,
            null,
            this.auth.authorize(),
        ).pipe(map(res =>JSON.deserialize<Matrix>(res['payload'], Matrix)));
    }

    getMatrixCabinetInfo(date = null) {
        const dt = moment(date).startOf('month').toDate();
        const dateParams = date ? `?from_date=${dt}` : '';
        return this.http.get(
            `${this.config.matrix}/info/${dateParams}`,
            this.auth.authorize(),
        ).pipe(map(res => res['payload'], MatrixEmployment));
    }

    getSettingMatrix() {
        return this.http.get(
            `${this.config.matrixSetting}/`,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<MatrixSetting>(res['payload'], MatrixSetting)));
    }

    updateSettingMatrix(data) {
        return this.http.put(
            `${this.config.matrixSetting}/`,
            data,
            this.auth.authorize(),
            ).pipe(map(res => JSON.deserialize<MatrixSetting>(res['payload'], MatrixSetting)));
    }

    sendToApprove(id: number) {
        return this.http.post(
            `${this.config.motivations}motivations/${id}/send_for_approve`,
            {},
            this.auth.authorize(),
        );
    }

    addEmployment(userId, companyId) {
        return this.http.post(
            `${this.config.motivations}users_motivation/`,
            {user_id: userId,
                    company_id: companyId,
                employment_type: null,
                employment_date: null,
                rate: null,
                legal_entity: null,
                },
            this.auth.authorize(),
        );
    }

    getMotivationsAccess() {
        return this.http.get(
            `${this.config.motivations}access`,
            this.auth.authorize(),
        );
    }

    getMatrixAccess() {
        return this.http.get(
            `${this.config.matrixAccess}`,
            this.auth.authorize(),
        );
    }

    createMatrix(userId: number, motivId: number, dt) {
        const date = moment(dt).startOf('month').format('YYYY-MM-DD');

        return this.http.post(
            `${this.config.matrix}/`,
            {user_id: userId,
                motivation_id: motivId,
                date_to: date,
            },
            this.auth.authorize(),
        ).pipe(map(resp => resp['payload']))
            .pipe(map(resp => JSON.deserialize<Matrix>(resp, Matrix)))
    }

    getPreviousMatrix(id) {
        return this.http.get(
            `${this.config.matrix}/${id}/previous`,
            this.auth.authorize(),
        ).pipe(map(res => res['payload'] ? JSON.deserialize<Matrix>(res['payload'], Matrix) : null));
    }

    getNextMatrix(id) {
        return this.http.get(
            `${this.config.matrix}/${id}/next`,
            this.auth.authorize(),
        ).pipe(map(res => res['payload'] ? JSON.deserialize<Matrix>(res['payload'], Matrix) : null));
    }

    getCurrentMatrix(motivId: number, curDate: string) {
        return this.http.get(
            `${this.config.matrix}/?motivation_id=${motivId}&date_from=${curDate}`,
            this.auth.authorize(),
        ).pipe(map(res => res['payload']));
    }

    moveSmartTask(taskId, matrixId, fromDate, toDate, penalty, nextMatrix = false, transferType = 2, comment = '') {
        return this.http.post(
            `${this.config.matrixBaseUrl}smart_task/transfer_task?task_id=${taskId}`,
            {
                from_date: moment(fromDate).format('YYYY-MM-DD'),
                to_date: moment(toDate).format('YYYY-MM-DD'),
                new_tax: penalty,
                to_next_matrix: nextMatrix,
                transfer_type: transferType,
                comment,
            },
            this.auth.authorize(),
        ).pipe(map(res => res['payload']));
    }

    getAllMetrics(userId: any) {
        return this.http.get(
            `${this.config.matrixBaseUrl}metrics?user_id=${userId}`,
            this.auth.authorize(),
        ).pipe(map(resp => resp['payload']));
    }

    addMetrics(matrixId, metricIds: number[]) {
        return this.http.post(
            `${this.config.matrix}/${matrixId}/metrics`,
            {metrics: metricIds},
            this.auth.authorize(),
        ).pipe(map(res => res['payload'] ? JSON.deserialize<Matrix>(res['payload'], Matrix) : null));
    }

    deleteMetric(matrixId: number, metricId) {
        return this.http.delete(
            `${this.config.matrixBaseUrl}kpi/${metricId}`,
            this.auth.authorize(),
        ).pipe(map(res => res['payload'] ? JSON.deserialize<Matrix>(res['payload'], Matrix) : null));
    }

    addRange(min: number, max: number, payout: number, proportional: boolean, metricId: number) {
        return this.http.post(
            `${this.config.matrixBaseUrl}ranges`,
            {
                min,
                max,
                payout,
                is_selected: proportional,
                matrix_metric_id: metricId,
            },
            this.auth.authorize(),
        ).pipe(map(resp => resp['payload']));
    }

    deleteRange(id) {
        return this.http.delete(
            `${this.config.matrixBaseUrl}ranges/${id}`,
            this.auth.authorize(),
        ).pipe(map(resp => resp['payload']));
    }

    editRange(id, range) {
        return this.http.put(
            `${this.config.matrixBaseUrl}ranges/${id}`,
            {
                min: range.min,
                id: range.id,
                matrix_metric_id: range.metricId,
                payout: range.payout,
                is_selected: range.proportional,
            },
            this.auth.authorize(),
        ).pipe(map(resp => resp['payload']));
    }

    editMetric(matrixId, metricId, metric) {
        return this.http.put(
            `${this.config.matrix}/${matrixId}/metrics/${metricId}`,
            {
                id: metric.id,
                plan: metric.plan,
                fact: metric.fact,
                weight: metric.weight,
                //ranges: metric.ranges.map(r => r.id),
                matrix_id: matrixId,
                description: metric.description,
            },
            this.auth.authorize(),
        ).pipe(map(resp => resp['payload']));
    }

    getMetric(matrixId, metricId) {
        return this.http.get(
            `${this.config.matrix}/${matrixId}/metrics/${metricId}`,
            this.auth.authorize(),
        ).pipe(map(resp => resp['payload']));
    }

    moveMatrix(id: number, start, finish) {
        return this.http.put(
            `${this.config.matrix}/${id}/change_date`,
            {
                start,
                finish,
            },
            this.auth.authorize(),
        ).pipe(map(resp => resp['payload'] ? JSON.deserialize<Matrix>(resp['payload'], Matrix) : null));
    }

    deleteEmployment(id: number) {
        return this.http.delete(
            `${this.config.motivations}users_motivation/${id}`,
            this.auth.authorize(),
        ).pipe(map(resp => resp));
    }

    getMatrices(empId: number, dateFrom: Date) {
        ///api/v1/matrix/?user_motivation_id=28355&date_from=2022-12-01&date_to=2022-12-31
        const dt = moment(dateFrom).format('YYYY-MM-DD');

        return this.http.get(
            `${this.config.matrix}/?user_motivation_id=${empId}&date_from=${dt}`,
            this.auth.authorize(),
        ).pipe(map(resp => resp['payload'] ? JSON.deserialize<Matrix[]>(resp['payload'], Matrix) : null));
    }

    getKPIList(query: string) {
        const params = query ? `?search=${query}` : '';

        return this.http.get(
            `${this.config.matrixBaseUrl}kpi_templates${params}`,
            this.auth.authorize(),
        ).pipe(map(resp => resp['payload'] ? JSON.deserialize<KPITemplate[]>(resp['payload'], KPITemplate) : null));
    }

    addKpi(matrixId: number, kpi: number[], type, kpiType = 'default') {
        return this.http.post(
            `${this.config.matrixBaseUrl}kpi`,
            {
                matrix_id: matrixId,
                kpi_templates: kpiType === 'default' ? kpi : kpi.map(id => ({template_id: id, starting_price:
                        0})),
                kpi_type: type,
                matrix_kpi_type: kpiType
            },
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<Matrix>(res['payload'], Matrix)));
    }

    editKPI(matrixId: number, metricId, metric) {
        return this.http.put(
            `${this.config.matrixBaseUrl}kpi/${metricId}`,
            {
                id: metric.id,
                plan: parseFloat(metric.plan),
                fact: parseFloat(metric.fact),
                weight: +metric.weight,
                ranges: metric.ranges,
                range_unit: metric.units,
                matrix_id: matrixId,
                description: metric.description,
                matrix_kpi_type: metric.type,
                starting_price: metric.type !== 'default' ? metric.startingPrice : undefined,
            },
            this.auth.authorize(),
        ).pipe(map(resp => JSON.deserialize<Matrix>(resp['payload'], Matrix)));
    }

    addNewKPITemplate(title: string, desc: string, ranges: MetricRange[], units: string) {
        return this.http.post(
            `${this.config.matrixBaseUrl}kpi_templates/`,
            {
                title,
                description: desc,
                range_unit: units,
                range: ranges,
            },
            this.auth.authorize(),
        ).pipe(map(resp => resp['payload'] ? JSON.deserialize<MatrixMetrics>(resp['payload'], MatrixMetrics) : null));
    }

    getReportLink(date: Date, skipEmpty = false) {
        const dt = moment(date).format('YYYY-MM-DD');

        const skipParam = skipEmpty ? '&no_empty=true' : '';

        const headers = this.auth.authorize();

        return this.http.get(
            `${this.config.matrix}/report?date_from=${dt}${skipParam}`,
            {responseType: "blob", headers: headers.headers}
        ).pipe(map(resp => resp));
    }

    deleteKPITemplate(id) {
        return this.http.delete(
            `${this.config.matrixBaseUrl}kpi_templates/${id}`,
            this.auth.authorize(),
        ).pipe(map(resp => resp));
    }

    editKPITemplate(kpi: KPITemplate) {
        return this.http.put(
            `${this.config.matrixBaseUrl}kpi_templates/${kpi.id}`,
            {
                title: kpi.title,
                description: kpi.description,
                range_unit: kpi.rangeUnit,
                range: kpi.ranges,
            },
            this.auth.authorize(),
        ).pipe(map(resp => resp['payload'] ? JSON.deserialize<MatrixMetrics>(resp['payload'], MatrixMetrics) : null));
    }

    changeVisibilitySettingsMatrix(id, settings) {
        return this.http.put(
            `${this.config.matrix}/${id}`,
            {settings},
            this.auth.authorize(),
        ).pipe(map(resp => resp['payload'] ? JSON.deserialize<Matrix>(resp['payload'], Matrix) : null));
    }

    getMatrixByIdForEveryone(matrixId: number, taskId: any) {
        return this.http.get(
            `${this.config.matrixSmartTasks}?task_id=${taskId}&matrix_id=${matrixId}`,
            this.auth.authorize()
        ).pipe(map(resp => resp['payload']));
    }

    getAvailableMatrices(id: number): any {
        return this.http.get(
            `${this.config.matrixSmartTasks}/task/${id}`,
            this.auth.authorize()
        ).pipe(map(resp => resp['payload'] ? MatrixUserRespElement.deserializeDictionary(resp['payload']) : resp));
    }

    changeSmartTaskFromTask(id: number, taskId: number, data: { tax: number; quality: number }, date: string) {
        const dateParams = date ? `?from_date=${date}` : '';
        return this.http.put(
            `${this.config.matrixSmartTasks}/task/${taskId}`,
            JSON.serialize(data),
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<Matrix>(res['payload'], Matrix)));
    }

    getMatrixByEmpAndDate(emp, date) {
        return this.http.get(
            `${this.config.matrix}/employment/${emp}?date_from=${date}`,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<MatrixUserRespElement[]>(res['payload'], MatrixUserRespElement)));
    }

    getMatrixByUserAndDate(userId, date) {
        return this.http.get(
            `${this.config.matrix}/user/${userId}?date_from=${date}`,
            this.auth.authorize(),
        ).pipe(map(res => JSON.deserialize<MatrixEmployeeNew>(res['payload'], MatrixEmployeeNew)));
    }

    getMatrixCounters(date, isOwner) {
        const dateStr = formatDate(date, 'YYYY-MM-dd', 'en');
        return this.http.get(
            `${this.config.matrix}/user/counters?date_from=${dateStr}&is_owner=${isOwner ? 'true' : 'false'}`,
            this.auth.authorize(),
        ).pipe(map(res => res['payload']));
    }

    deleteLeadsBonus(matrixId, id) {
        return this.http.delete(
            `${this.config.matrixBaseUrl}leads_bonus/${id}`,
            this.auth.authorize(),
        );
    }

    addNewLeadsBonus(matrixId, obj) {
        return this.http.post(
            `${this.config.matrixBaseUrl}leads_bonus/`,
            {
                "matrix_id": matrixId,
                "title": obj.title,
                "weight": obj.weight,
                "fact": obj.fact,
            },
            this.auth.authorize(),
        ).pipe(map(res => res['payload'] ? JSON.deserialize<MatrixLeadsBonus>(res['payload'], MatrixLeadsBonus) : null));
    }

    changeLeadsBonus(element) {
        return this.http.put(
            `${this.config.matrixBaseUrl}leads_bonus/${element.id}`,
            element,
            this.auth.authorize(),
        ).pipe(map(res => res['payload'] ? JSON.deserialize<MatrixLeadsBonus>(res['payload'], MatrixLeadsBonus) : null));
    }
}
