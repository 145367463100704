import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Tag} from '../../../../task-manager/models/tag';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ApiBoardsService} from '../../../../task-manager/services/api-boards.service';
import {GlobalNotificationCenterService} from '../../../../../services/global-notification-center.service';
import {TagsService} from '../../../../task-manager/services/tags.service';
import {BoardsService} from '../../../../task-manager/services/boards.service';
import {GroupsFormComponent} from '../groups-form/groups-form.component';
import {GroupsService} from '../../../../task-manager/services/groups.service';
import {Board} from '../../../../task-manager/models/board';
import {Group} from '../../../../task-manager/models/group';

@Component({
    selector: 'app-groups-list',
    templateUrl: './groups-list.component.html',
    styleUrls: ['./groups-list.component.scss']
})
export class GroupListComponent implements OnInit, OnDestroy {

    @Input() public canEditGroup = true;
    @Input() public shortView = false;
    @Input() public collapsed = false;
    @Input() public groups: Tag[] = [];
    @Input() public group: Tag = null;
    @Input() public groupSelected: Tag = null;
    @Input() public boardId = null;
    @Input() public editable = false;
    @Input() public disabled = false;
    @Input() public removable = false;
    @Input() public selected = false;
    @Output() public readonly removeGroup: EventEmitter<any> = new EventEmitter();
    @Output() public readonly onSelect: EventEmitter<any> = new EventEmitter();
    public isDeleteConfirm = false;
    public groupToDelete = null;
    // Триггер смерти компонента
    private destroyed = new Subject<void>();
    private currentBoard: Board = this.boardsService.boardSelected$.getValue();

    constructor(
        private dialog: MatDialog,
        private api: ApiBoardsService,
        private notiService: GlobalNotificationCenterService,
        private tagsService: TagsService,
        private boardsService: BoardsService,
        private groupsService: GroupsService
    ) {
    }

    ngOnInit() {
        if (this.selected && !this.boardId) {
            this.boardId = this.boardsService.openedBoardId$.getValue();
        }

        this.groupsService.onGroupUpdate$
            .pipe(takeUntil(this.destroyed))
            .subscribe(data => {
                if (data && this.groups && data.id) {
                    const g = this.groups.find(gr => gr.id == data.id);
                    if (g) {
                        g.name = data.name;
                        g.color = data.color;
                    }
                }
            });
    }

    edit(tagId) {
        const data = {
            tagId,
            boardId: this.boardId
        };

        const dialogForm = this.dialog.open(GroupsFormComponent, {data});

        dialogForm.afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe(result => {
                this.dialog.ngOnDestroy();
            });
    }

    delete() {
        this.isDeleteConfirm = false;
        this.api.deleteTag(this.groupToDelete)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.tagsService.onTagRemove$.next(this.groupToDelete);
            }, err => {
                this.notiService.handleFullError(err);
            });
    }

    click(group: Tag) {
        if (!this.canEditGroup) {
            return false;
        }

        if (this.selected) {
            this.groups.forEach(tag => tag['selected'] = false);

            if (this.isSelected(group)) {
                group['selected'] = false;
                this.groupSelected = null;
                this.removeSelect(group);
            } else {
                this.groupSelected = group;
                group['selected'] = true;
                //this.groupsService.onGroupSelect$.next(this.groupSelected);
                this.onSelect.emit(group);
            }

        }
    }

    select() {
        this.onSelect.emit(this.groupSelected);
    }

    removeSelect(group) {
        if (this.canEditGroup) {
            this.removeGroup.emit(group);
        }
    }

    isSelected(group: Tag) {
        if (!this.selected) {
            return false;
        }
        return group['selected'] ? true : this.groupSelected ? this.groupSelected.id === group.id : false;
    }

    getItemColor(colorName) {
        return this.groupsService.getGroupBackground(colorName);
    }

    getItemColorFromBoard(group: Group) {
        const boardGroups = this.currentBoard.tags.filter(el => el.taskGroup);
        const clone = boardGroups.find(el => el.name === group.name);
        return this.groupsService.getGroupBackground(clone ? clone.color : !this.disabled ? group.color : Group.colorDisabled);
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

}
