<tour-step-template>
    <ng-template let-step="step">
        <mat-card appearance="outline" class="learning-card"
                (click)="$event.stopPropagation()"
                [style.width]="step.stepDimensions?.width"
                [style.min-width]="step.stepDimensions?.minWidth"
                [style.max-width]="step.stepDimensions?.maxWidth"
        >
                <div class="header-group">
                    <!--<mat-card-title>
                        {{ step.title }}
                    </mat-card-title>-->
                    <div class="progress">Шаг {{ tourService.steps?.indexOf(step) + 1 }}/{{ tourService.steps?.length }}</div>

                    <button
                            mat-button *ngIf="tourService.hasNext(step)"
                            (click)="tourService.pause()"
                            class="close">
                        Пропустить обучение
                    </button>
                </div>

            <mat-card-content
                    class="mat-body"
            >
                <mat-card-title *ngIf="step.title" class="title">
                    {{ step.title }}
                </mat-card-title>

                <div class="content"
                        [innerHTML]="step.content">
                </div>

            </mat-card-content>

            <mat-card-footer class="buttons-group"
                    [class.no-progress]="!step.showProgress"
            >
                <button
                        mat-button
                        class="button prev"
                        [disabled]="!tourService.hasPrev(step)"
                        (click)="tourService.prev()"
                >
                    <mat-icon class="mat-icon prev" [svgIcon]="'radius_arrow_left'"></mat-icon>
                    Назад
                </button>
                <button *ngIf="tourService.hasNext(step) && !step.nextOnAnchorClick"
                        class="button next"
                        (click)="tourService.next()"
                        mat-button
                >
                    Далее
                    <mat-icon class="mat-icon next" [svgIcon]="'radius_arrow_left'" iconPositionEnd></mat-icon>
                </button>
                <button *ngIf="!tourService.hasNext(step)"
                        class="button done-button"
                        mat-button
                        (click)="tourService.end()"
                >
                    Завершить
                </button>
            </mat-card-footer>
        </mat-card>
    </ng-template>
</tour-step-template>


<!--
<tour-step-template>
    <ng-template let-step="step">
        <mat-card class="learning-card"
                (click)="$event.stopPropagation()"
                [style.width]="step.stepDimensions?.width"
                [style.min-width]="step.stepDimensions?.minWidth"
                [style.max-width]="step.stepDimensions?.maxWidth"
        >
            <mat-card-header>
                <div class="header-group">
                    @if (step.showProgress) {
                    <div class="progress">Шаг {{ tourService.steps?.indexOf(step) + 1 }} / {{ tourService.steps?.length }}</div>
                    }
                    <mat-card-title>
                        {{ step.title }}
                    </mat-card-title>
                    <button
                            mat-icon-button
                            (click)="tourService.end()"
                            class="close"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </mat-card-header>

            <mat-card-content
                    class="mat-body"
                    [innerHTML]="step.content"
            ></mat-card-content>

            <mat-card-actions
                    [class.no-progress]="!step.showProgress"
            >
                <button
                        mat-button
                        class="prev"
                        [disabled]="!tourService.hasPrev(step)"
                        (click)="tourService.prev()"
                >
                    <mat-icon>chevron_left</mat-icon>
                    {{ step.prevBtnTitle }}
                </button>
               &lt;!&ndash; @if (step.showProgress) {
                <div class="progress">{{ tourService.steps?.indexOf(step) + 1 }} / {{ tourService.steps?.length }}</div>
                }&ndash;&gt;
                @if (tourService.hasNext(step) && !step.nextOnAnchorClick) {
                <button
                        class="next"
                        (click)="tourService.next()"
                        mat-button
                >
                    {{ step.nextBtnTitle }}
                    <mat-icon iconPositionEnd>chevron_right</mat-icon>
                </button>
                }
                @if (!tourService.hasNext(step)) {
                <button
                        mat-button
                        (click)="tourService.end()"
                >
                    {{ step.endBtnTitle }}
                </button>
                }
            </mat-card-actions>
        </mat-card>
    </ng-template>
</tour-step-template>-->
