import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UserSubdivision} from '../../../phonebook/models/user-subdivision';
import {Subject} from 'rxjs';
import {NavService} from '../../../phonebook/services/nav.service';
import {CompanyService} from '../../../phonebook/services/company.service';
import {PhonebookService} from '../../../phonebook/services/phonebook.service';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {PeopleAddComponent} from '../people-add/people-add.component';

@Component({
  selector: 'app-users-accordion',
  templateUrl: './users-accordion.component.html',
  styleUrls: ['./users-accordion.component.scss']
})
export class UsersAccordionComponent implements OnInit, OnDestroy, OnChanges {

    @Input() public users = null;
    // Нужно ли возвращать должность
    @Input() public position = false;
    @Input() public title: string = null;
    @Input() public buttonTitle: string = null;
    @Input() public disabled = false;
    @Input() public expansive = true;
    @Input() public isExpandedDefault = false;
    @Output() public readonly usersChange: EventEmitter<UserSubdivision[]> = new EventEmitter();
    private destroyed = new Subject<void>();

    constructor(
        private nav: NavService,
        private company: CompanyService,
        private phonebook: PhonebookService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {

    }

    openUsersForm(evt) {
        evt.stopPropagation();

        const dialogData = {
            users: this.users,
            selected: this.users,
            title: 'Выбор сотрудников',
            type: 'userShort',
            position: this.position
        };

        const dialogRef = this.dialog.open(PeopleAddComponent, {
            minWidth: '500px',
            maxWidth: '500px',
            data: dialogData
        });

        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe(result => {
              if (result ) {
                    this.usersChange.emit(result);
                }
            });
    }


    removeUser(user) {
        this.users = this.users.filter(el => el.id !== user.id);
        this.usersChange.emit(this.users);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.position) {
            this.position = changes.position.currentValue;
        }

        if (changes.users) {
            this.users = changes.users.currentValue;
        }

        if (changes.disabled) {
            this.disabled = changes.disabled.currentValue;
        }
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
