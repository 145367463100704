import {JsonProperty, JsonType} from 'ta-json';
import {Task} from './task';
import {UserShort} from '../../../models/user';


export class Paginator {
    @JsonProperty('has_more')
    @JsonType(Boolean)
    hasMore = false;

    @JsonProperty('offset')
    @JsonType(Number)
    offset = 0;

    @JsonProperty('total_count')
    @JsonType(Number)
    totalCount = 0;

    @JsonProperty('items_count')
    @JsonType(Number)
    itemsCount = 0;
}

export class UserSearch {
    /** Уникальный идентификатор */
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('name')
    @JsonType(String)
    name: string;

    @JsonProperty('photo')
    @JsonType(String)
    photo: string;
}

export class MatchedFields {
    @JsonProperty('actual_result')
    @JsonType(String)
    actualResult?: string;

    @JsonProperty('description')
    @JsonType(String)
    description?: string;

    @JsonProperty('dod')
    @JsonType(String)
    dod?: string;

    @JsonProperty('performer')
    @JsonType(UserSearch)
    performer?: UserSearch;

    @JsonProperty('author')
    @JsonType(UserSearch)
    author?: UserSearch;

    @JsonProperty('observer')
    @JsonType(UserSearch)
    observer?: UserSearch[];
}

export class TaskMatch extends Task {
    @JsonProperty('matched_fields')
    @JsonType(MatchedFields)
    matchedFields?: MatchedFields;

    @JsonProperty('is_sprint')
    @JsonType(Boolean)
    isSprint: boolean;
}

export class SearchResult {
    @JsonProperty('items')
    @JsonType(TaskMatch)
    items: TaskMatch[];

    @JsonProperty('pagination')
    @JsonType(Paginator)
    pagination: Paginator;
}

export class SearchResponse {
    @JsonProperty('current_board')
    @JsonType(SearchResult)
    currentBoard?: SearchResult;

    @JsonProperty('other_boards')
    @JsonType(SearchResult)
    otherBoards?: SearchResult;

    @JsonProperty('templates')
    @JsonType(SearchResult)
    templates?: SearchResult;
}
