import { NotifierService } from 'gramli-angular-notifier';
import { Subject } from 'rxjs';
import { JSON } from 'ta-json';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { ApiService } from '../../../services/api.service';
import { AuthService } from '../../../services/auth.service';
import { UserInfoService } from '../../../services/user-info.service';
import { Question } from '../models/question';
import { QuestionShort } from '../models/question-short';
import { ConfigQuestionsService } from './config-questions.service';

@Injectable({
    providedIn: 'root',
})
export class ApiQuestionsService extends ApiService {
    public cardClose$ = new Subject();
    public listClose$ = new Subject();

    constructor(
        readonly http: HttpClient,
        readonly config: ConfigQuestionsService,
        private _auth: AuthService,
        public userInfo: UserInfoService,
        public notifierService: NotifierService,
    ) {
        super(http, config, userInfo, _auth, notifierService);
    }

    getQuestions() {
        return this.http
            .get(
                `${this.config.questions}?template=full`,
                this.auth.authorize(),
            )
            .pipe(
                map((res) =>
                    JSON.deserialize<QuestionShort[]>(res, QuestionShort),
                ),
            );
    }

    getQuestionById(id) {
        return this.http
            .get(`${this.config.questions}${id}`, this.auth.authorize())
            .pipe(map((res) => JSON.deserialize<Question>(res, Question)));
    }

    getUnansweredQuestions() {
        return this.http
            .get(
                `${this.config.questions}?unanswered=true`,
                this.auth.authorize(),
            )
            .pipe(
                map((res) =>
                    JSON.deserialize<QuestionShort[]>(res, QuestionShort),
                ),
            );
    }

    sendResponse(id, data) {
        return this.http
            .put(`${this.config.questions}${id}`, data, this.auth.authorize())
            .pipe(map((res) => JSON.deserialize<Question>(res, Question)));
    }
}
