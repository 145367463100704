import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';

@Component({
  selector: 'app-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss']
})
export class InputTimeComponent implements OnInit, OnChanges {
  @Input() public minutes = null;
  @Output() public readonly onChange: EventEmitter<any> = new EventEmitter();
  @Input() public disabled = false;
  @Input() public large = false;
  @Input() public superactive = false;

  public h = null;
  public m = null;

  public hFocused = false;
  public mFocused = false;

  @ViewChild('hourEl') hourEl: ElementRef;
  @ViewChild('minEl') minEl: ElementRef;

  get focused() {
    return this.hFocused || this.mFocused;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.minutes) {
      this.minutes = changes.minutes.currentValue;
      if (this.minutes) {
        this.m = this.minutes % 60;
        this.h = (this.minutes - this.m) / 60;

        if (this.h < 10) {
          // this.h = (this.large ? '00' : '0') + this.h;
        }

        if (this.m < 10) {
          this.m = '0' + this.m;
        }
      } else {
        this.m = '';
        this.h = '';
      }
    }
  }

  ngOnInit(): void {

  }

  onTab(evt) {
    evt.preventDefault();

    let str = this.h?.toString();
    this.hourEl.nativeElement.value = str;
    this.focusMinutes();
  }

  onChangeHours(evt) {
    let str = this.h?.toString();
    let longCont = this.large ? 3 : 2;

    if (isNaN(str) && str) {
      this.hourEl.nativeElement.value = '';
      return false;
    }

    if (str && str.length > longCont) {
      this.h = str.slice(0, longCont);
    }

    str = this.h?.toString();

    if (str && str.length == longCont && evt.target.selectionStart == longCont) {
      this.hourEl.nativeElement.value = str;
      this.focusMinutes();
    }

    if (this.superactive) {
      this.save()
    }
  }

  onChangeMinutes(evt) {

    let str = this.m?.toString();

    if (isNaN(str) && str) {
      this.minEl.nativeElement.value = '';
      return false;
    }

    if (str && str > 59) {
      this.m = 59;
    }

    str = this.m?.toString();
    if (str && str.length > 2) {
      this.m = str.slice(0, 2);
    }
    
    if (!str && evt.keyCode !== 9) {
      this.focusHours()
    }

    if (this.superactive) {
      this.save()
    }
  }

  focusMinutes() {
    this.minEl.nativeElement.focus();
    this.minEl.nativeElement.selectionStart = 0;
  }

  focusHours() {
    this.hourEl.nativeElement.focus();
  }

  save() {
    setTimeout(() => {
      if (isNaN(+this.m) || isNaN(+this.h)) {
        return false;
      }

      if (!this.focused || this.superactive) {
        const sum = ((+this.h) * 60) + (+this.m);

        if (+sum !== +this.minutes) {
          this.onChange.emit(sum);
        }
      }

      if (!this.focused) {
        if ((this.h || this.h == 0) && this.h.length == 1) {
          // this.h = (this.large ? '00' : '0') + this.h;
        }

        if ((this.m || this.m == 0) && this.m.length == 1) {
          this.m = '0' + this.m;
        }
      }
    })
  }
}
