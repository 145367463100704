import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';
import {UserInfoService} from '../../../services/user-info.service';
import {AuthService} from '../../../services/auth.service';
import {NotifierService} from 'gramli-angular-notifier';
import {map} from 'rxjs/operators';
import {JSON} from 'ta-json';
import {Subdivision} from '../models/subdivision';
import {Position} from '../models/position';
import {Rights} from '../../../models/user';
import {PositionFolder} from '../models/position-folder';
import {PositionShort} from '../models/position-short';
import {Role} from '../models/role';
import {Slot} from '../models/slot';
import {FunctionFull} from '../models/functions/function';
import {UserAnswer} from '../models/functions/function-question';
import {WorkdaySlot} from '../models/workday-slot';
import {WorkdaySegment} from '../models/workday-segment';

@Injectable({
    providedIn: 'root'
})
export class ApiCompanyService extends ApiService {

    constructor(
        readonly http: HttpClient,
        public config: ConfigService,
        public userInfo: UserInfoService,
        public auth: AuthService,
        public notifierService: NotifierService) {
        super(http, config, userInfo, auth, notifierService);
    }

    createSegment(data) {
        return this.http.post(
            `${this.config.workdaySnapshot}segment/`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<WorkdaySegment>(res['data'], WorkdaySegment))
        );
    }

    editSegment(data, id) {
        return this.http.put(
            `${this.config.workdaySnapshot}segment/${id}`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<WorkdaySegment>(res['data'], WorkdaySegment))
        );
    }

    deleteSegment(id) {
        return this.http.delete(
            `${this.config.workdaySnapshot}segment/${id}`,
            this.auth.authorize(),
        ).pipe(
            map(res => res)
        );
    }

    deleteWorkDay(id) {
        return this.http.delete(
            `${this.config.workdaySnapshot}date-slot/${id}`,
            this.auth.authorize(),
        ).pipe(
            map(res => res)
        );
    }

    createWorkDay(data) {
        return this.http.post(
            `${this.config.workdaySnapshot}date-slot/`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<WorkdaySlot>(res['data'], WorkdaySlot))
        );
    }

    editWorkDay(data, id) {
        return this.http.put(
            `${this.config.workdaySnapshot}date-slot/${id}`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<WorkdaySlot>(res['data'], WorkdaySlot))
        );
    }

    getWorkdaysBySlotId(id) {
        return this.http.get(
            `${this.config.workdaySnapshot}date-slot/?struct_adm_slot=${id}`,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<WorkdaySlot[]>(res['data'], WorkdaySlot))
        );
    }

    getWorkdaysSegmentByDayId(id) {
        return this.http.get(
            `${this.config.workdaySnapshot}segment/?segment_id=${id}`,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<WorkdaySegment[]>(res['data'], WorkdaySegment))
        );
    }

    setSettings(data) {
        return this.http.post(
            `${this.config.companySettings}`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => res)
        );
    }

    createSubdivision(data) {
        return this.http.post(
            `${this.config.structAdmin}`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<Subdivision>(res['data'], Subdivision))
        );
    }

    createFunction(data) {
        return this.http.post(
            `${this.config.functions}`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                function: JSON.deserialize<FunctionFull>(res['data'], FunctionFull),
                rights: JSON.deserialize<Rights>(res['rights'], Rights)}))
        );
    }

    createPosition(data) {
        return this.http.post(
            `${this.config.structPosition}`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<Position>(res['payload'], Position))
        );
    }

    editPosition(data: any, id: number) {
        return this.http.put(
            `${this.config.structPosition}${id}/`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<Position>(res['payload'], Position))
        );
    }

    editSubdivision(data: any, id: number) {
        return this.http.put(
            `${this.config.structAdmin}${id}/`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<Subdivision>(res['data'], Subdivision))
        );
    }

    setSubdivisionArchive(id: number, data) {
        return this.http.put(
            `${this.config.structAdmin}${id}/`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<Subdivision>(res['data'], Subdivision))
        );
    }

    setPositionArchive(id: number, data) {
        return this.http.put(
            `${this.config.structPositions}archive/${id}/`,
            {},
            this.auth.authorize(),
        )
    }

    deletePosition(id: number) {
        return this.http.delete(
            `${this.config.structPositions}${id}/`,
            this.auth.authorize(),
        )
    }

    createSlot(data) {
        return this.http.post(
            `${this.config.structSlot}`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<Slot>(res['data'], Slot))
        );
    }

    editSlotById(id, data) {
        return this.http.put(
            `${this.config.structSlot}${id}/`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<Slot>(res['data'], Slot))
        );
    }

    deleteSlotById(id) {
        return this.http.delete(
            `${this.config.structSlot}${id}/`,
            this.auth.authorize(),
        );
    }

    deleteSubdivisionById(id: number) {
        return this.http.delete(
            `${this.config.structAdmin}${id}/`,
            this.auth.authorize(),
        );
    }

    deletePositionById(id: number) {
        return this.http.delete(
            `${this.config.structPosition}${id}/`,
            this.auth.authorize(),
        );
    }

    deletePositionFolderById(id: number) {
        return this.http.delete(
            `${this.config.positionFolders}${id}/`,
            this.auth.authorize(),
        );
    }

    renamePositionFolderById(id: number, title: string) {
        return this.http.put(
            `${this.config.positionFolders}${id}/`,
            {
                title,
            },
            this.auth.authorize(),
        );
    }

    getStat(type, id, model = 'slot') {
        return this.http.get(
            `${this.config.functionalStructure}/fstat/?types[]=${type}&${model}_id=${id}`,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                    chart_data: JSON.deserialize(res['chart_data']),
                    comments: JSON.deserialize<UserAnswer[]>(res['comments'], UserAnswer)
                })
            )
        );
    }

    getSubdivisionById(id) {
        return this.http.get(
            `${this.config.structAdmin}${id}/`,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                        subdivision: JSON.deserialize<Subdivision>(res['data'], Subdivision),
                        rights: JSON.deserialize<Rights>(res['rights'], Rights)
                    })
            )
        );
    }

    getSlotsByUserId(id) {
        return this.http.get(
            `${this.config.structSlot}?user_id=${id}`,
            this.auth.authorize(),
        ).pipe(
            map(res => res['data'])
        );
    }

    getPositionFolders() {
        return this.http.get(
            `${this.config.positionFolders}long/`,
            this.auth.authorize(),
        ).pipe(
            map(res => JSON.deserialize<PositionFolder[]>(res['payload'], PositionFolder))
        );
    }

    getPositions() {
        return this.http.get(
            `${this.config.structPositions}`,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                        positions: JSON.deserialize<PositionShort[]>(res['payload'], PositionShort),
                        rights: JSON.deserialize<Rights>(res['rights'], Rights)
                    })
            )
        );
    }

    getRoleById(id) {
        return this.http.get(
            `${this.config.structPositions}${id}/role`,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                        role: JSON.deserialize<Role>(res['payload'], Role),
                        rights: JSON.deserialize<Rights>(res['rights'], Rights)
                    })
            )
        );
    }

    getPositionById(id) {
        return this.http.get(
            `${this.config.structPosition}${id}/`,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                        position: JSON.deserialize<Position>(res['payload'], Position),
                        rights: JSON.deserialize<Rights>(res['rights'], Rights)
                    })
            )
        );
    }

    createPositionFolder(title: string) {
        return this.http.post(`${this.config.positionFolders}`,{title},this.auth.authorize(),)
            .pipe(map(res => ({position: JSON.deserialize<Position>(res['payload'], Position)}))
        );
    }

    getFolderPositionsTree() {
        return this.http.get(
            `${this.config.positionStructFolder}`,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                        folders: JSON.deserialize<PositionFolder[]>(res['payload'], PositionFolder),
                        rights: JSON.deserialize<Rights>(res['rights'], Rights)
                    })
            )
        );
    }

    getGroupPositionById(id) {
        return this.http.get(
            `${this.config.positionStructFolder}${id}`,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                    group: JSON.deserialize<PositionFolder>(res['payload'], PositionFolder),
                    rights: JSON.deserialize<Rights>(res['rights'], Rights)
                })
            )
        );
    }

    editGroupPosition(data, id) {
        return this.http.put(
            `${this.config.positionStructFolder}${id}`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                        group: JSON.deserialize<PositionFolder>(res['payload'], PositionFolder),
                        rights: JSON.deserialize<Rights>(res['rights'], Rights)
                    })
            )
        );
    }

    createGroupPosition(data) {
        return this.http.post(
            `${this.config.positionStructFolder}`,
            data,
            this.auth.authorize(),
        ).pipe(
            map(res => ({
                        group: JSON.deserialize<PositionFolder>(res['payload'], PositionFolder),
                        rights: JSON.deserialize<Rights>(res['rights'], Rights)
                    })
            )
        );
    }
}
