import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {JSON} from 'ta-json';
import {AuthService} from '../../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public isShortTaskForm$ = new BehaviorSubject(true);
  public onShortTaskFormChange$ = new Subject();
  public settings = {isShortTaskForm: true};
  public groupMenu$ = new BehaviorSubject(false);

  constructor(
      private auth: AuthService,
  ) { }

  toggleShortTaskForm() {
    if (!this.settings) {
      this.settings = {isShortTaskForm: true};
    }
    this.settings['isShortTaskForm'] = !this.isShortTaskForm$.getValue();
    this.applySettings();
    this.saveTaskSettings();
  }

  loadTaskSettings() {
    this.settings = JSON.parse(localStorage.getItem('taskManagerViewSettingsTask#18' + this.auth.auth.id));

    this.applySettings();
  }

  applySettings() {
    if (this.settings && this.settings['isShortTaskForm']) {
      this.isShortTaskForm$.next(this.settings['isShortTaskForm']);
    } else {
      this.isShortTaskForm$.next(false);
    }
  }

  saveTaskSettings() {
    localStorage.setItem('taskManagerViewSettingsTask#18' + this.auth.auth.id, JSON.stringify(this.settings));
  }
}
