<form class="form" [formGroup]="formGroup" [ngClass]="{'kit': kitView}">
  <mat-form-field *ngIf="!kitView" class="form__field">
    <input matInput #searchTerm
           class="input"
           autocomplete="off"
           type="text" [placeholder]="placeholderTxt"
           formControlName="inputValue">
  </mat-form-field>
    <input matInput #searchTerm
           *ngIf="kitView"
           class="input"
           autocomplete="off"
           type="text" [placeholder]="placeholderTxt"
           formControlName="inputValue">
  <button type="submit" class="button _hidden"></button>
  <button *ngIf="formGroup.controls['inputValue'].value"
          (click)="onClear()"
          class="button _clear">
    <mat-icon class="icon _clear">clear</mat-icon>
  </button>
</form>
