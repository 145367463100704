<div class="header">
    <div class="header__toolbar">
        <a class="header__logo"
           routerLinkActive="_active"
           [routerLink]="'apps/dashboard'">
            <img class="logo"
                 matTooltip="Рабочий стол"
                 matTooltipPosition="after"
                 src="/assets/images/logo-white.svg">
            <ng-container *ngIf="isXmass">
<!--                <img class="xmass" src="/assets/images/christmas_tree.png">-->
                <img class="xmass-hat" src="/assets/images/x-hat.svg">
            </ng-container>
        </a>
        <div class="header__nav" #nav (scroll)="scrollEv()">
            <div class="scroll-arrow scroll-arrow__bg" *ngIf="topArrow" (click)="scrollUp()">
                <div class="scroll-arrow__icon">
                    <mat-icon class="icon">expand_less</mat-icon>
                </div>
            </div>
            <ng-container *ngFor="let link of apps">
                <a class="app-link" [ngClass]="{'tasksFirstStep': link.name === 'boards'}"
                   routerLinkActive="_active" [disabled]="link.name === 'boards' && isLearning"
                   [routerLink]="'/apps/'+link.name"
                    *ngIf="!link.is_hidden"
                >
                    <app-badge *ngIf="link.name === 'finance-1c' && financeCounter > 0" [data]="financeCounter"></app-badge>
                    <app-badge *ngIf="link.name === 'learning' && learningCounter > 0" [data]="learningCounter"></app-badge>
                    <app-badge *ngIf="link.name === 'bugtracker' && tusksCounter > 0" [data]="tusksCounter"></app-badge>
                    <app-badge *ngIf="link.name === 'boards' && boardsCounter > 0" [data]="boardsCounter"></app-badge>
                    <app-badge *ngIf="link.name === 'phonebook/users' && (motivationCounter > 0 || matrixCounter > 0)" [data]="motivationCounter + matrixCounter"></app-badge>
                    <app-badge *ngIf="link.name === 'calendar' && calCounter > 0" [data]="calCounter"></app-badge>
                    <app-badge *ngIf="link.name === 'academy' && academyCounter > 0" [data]="academyCounter"></app-badge>

                    <img class="app-img" [src]="getIcon(link)"
                         [matTooltip]="link.view_name"
                         matTooltipPosition="right">
                    <span class="txt app-name">{{link.view_name}}</span>
                </a>
            </ng-container>
            <div class="scroll-arrow scroll-arrow__bg _down"
                 (click)="scrollDown()"
                 *ngIf="bottomArrow || nav.scrollHeight > nav.scrollTop + nav.clientHeight">
                <div class="scroll-arrow__icon">
                    <mat-icon class="icon">expand_less</mat-icon>
                </div>
            </div>
        </div>
    </div>

    <div class="header__user-panel">
        <button class="header__user-panel-link _massage" [ngClass]="{'disabled': isLearning}"
                (click)="!isLearning ? openChats.emit(true) : null"
                matTooltip="Чаты"
                matTooltipPosition="right">
            <mat-icon svgIcon="radius_one_chat"></mat-icon>
            <span *ngIf="newMessage>0" class="new-messages">{{ newMessage > 99 ? '99+' : newMessage }}</span>
        </button>
        <button class="header__user-panel-link _massage" [ngClass]="{'disabled': isLearning}"
                matTooltip="Вопросы"
                matTooltipPosition="right"
                (click)="!isLearning ? openQuestions.emit(true) : null">
            <mat-icon svgIcon="radius_quiz"></mat-icon>
            <span *ngIf="newQuestions > 0" class="new-messages">{{ newQuestions > 99 ? '99+' : newQuestions }}</span>
        </button>

        <button class="header__user-panel-link _massage" [ngClass]="{'disabled': isLearning}"
                (click)="!isLearning ? openNotificationCenter.emit(true) : null"
                matTooltip="Уведомления"
                matTooltipPosition="right">
            <mat-icon svgIcon="radius_bell"></mat-icon>
            <span *ngIf="newNotification>0" class="new-messages">{{ newNotification > 99 ? '99+' : newNotification }}</span>
        </button>

        <button class="header__user-panel-link _massage skipOnboarding" [ngClass]="{'disabled': isLearning}"
                matTooltip="Помощь"  [disabled]="isLearning"
                matTooltipPosition="right"
                [matMenuTriggerFor]="supportMenu">
            <mat-icon svgIcon="radius_question"></mat-icon>
        </button>

        <mat-menu #supportMenu="matMenu" class="support-menu" xPosition="after" yPosition="below">
            <a mat-menu-item href="https://t.me/RadiusHelp_Bot" target="_blank" class="link">
                <mat-icon svgIcon="radius_telegram_color" class="support-menu-icon"></mat-icon>
                Написать в Telegram
            </a>
            <a mat-menu-item href="mailto:help@myradius.ru" class="link">
                <mat-icon svgIcon="radius_mail"  class="support-menu-icon _small"></mat-icon>
                <span>Написать на почту <span class="underlined">help&#64;myradius.ru</span></span>
            </a>
            <a mat-menu-item href="#" (click)="onHintClick($event)" class="link" *ngIf="!isMobile">
                <mat-icon svgIcon="radius_question_small" class="support-menu-icon _small"></mat-icon>
                Помощь по разделу
            </a>
            <a mat-menu-item href="#" (click)="onLearningClick($event)" class="link" *ngIf="!isMobile && isTasks">
                <mat-icon svgIcon="radius_learning_book" class="support-menu-icon _small"></mat-icon>
                Обучение по разделу
            </a>
        </mat-menu>

        <a class="header__user-panel-link _cabinet" *ngIf="avatar"
           [routerLink]="'/cabinet'">

            <app-user-avatar class="user-img" [user]="user"
                             (mouseenter)="motivationCabinetCounter + matrixCabinetCounter > 0 ? undefined : hover = true"
                             (mouseleave)="hover=false"></app-user-avatar>

            <app-badge class="counter" *ngIf="motivationCabinetCounter + matrixCabinetCounter > 0" [data]="motivationCabinetCounter + matrixCabinetCounter"></app-badge>

            <mat-icon
                    (mouseenter)="motivationCabinetCounter + matrixCabinetCounter > 0 ? undefined : hover = true"
                    (mouseleave)="hover=false"
                    class="counter _tariff" *ngIf="tariffInfo && motivationCabinetCounter + matrixCabinetCounter <= 0" [svgIcon]="isFreeTariff(tariffInfo) ? 'radius_tariff_off_small' : 'radius_tariff_on_small'"></mat-icon>
        </a>
    </div>
</div>

<div class="tariff-info-overflow" *ngIf="tariffInfo && (hover || forceShow)">
    <div class="decor"></div>
    <div class="tariff-header" *ngIf="forceShow">
        <div class="tariff-heading">Изменения тарифа</div>
        <mat-icon class="close-icon" (click)="onTariffClose()">close</mat-icon>
    </div>

    <div class="row">
        <strong>Ваша роль в системе:</strong>
        <span>{{isAdminTariff(tariffInfo) ? 'Администратор' : 'Пользователь'}}</span>
    </div>
    <div class="row">
        <strong>Ваш тариф:</strong>
        <span>{{tariffInfo.tariff?.title || '–'}}</span>
    </div>
    <div class="row">
        <strong>Срок действия тарифа:</strong>
        <span>{{isFreeTariff(tariffInfo) || isGiftTariff(tariffInfo) ? 'Бессрочно' : dateFormat(tariffInfo?.tariff?.end)}}</span>
    </div>
</div>
