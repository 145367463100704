import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BoardsService} from '../../../../task-manager/services/boards.service';
import {takeUntil} from 'rxjs/operators';
import {ApiBoardsService} from '../../../../task-manager/services/api-boards.service';
import {GlobalNotificationCenterService} from '../../../../../services/global-notification-center.service';
import {Subject} from 'rxjs';
import {ConfigBoardsService} from '../../../../task-manager/services/config-boards.service';
import {TagsService} from '../../../../task-manager/services/tags.service';
import {groupsMapping} from '../../../../task-manager/models/group';
import {GroupsService} from '../../../../task-manager/services/groups.service';
import {SharedService} from '../../../services/shared.service';

@Component({
    selector: 'app-groups-form',
    templateUrl: './groups-form.component.html',
    styleUrls: ['./groups-form.component.scss']
})
export class GroupsFormComponent implements OnInit, OnDestroy {

    public colors = groupsMapping;
    public formGroup: UntypedFormGroup = null;
    public selected = null;
    public isButtonsDisabled = false;
    public isLoaded = false;
    public tagId: string = null;
    private boardId = null;
    // Триггер смерти компонента
    private destroyed = new Subject<void>();
    public isDeleteConfirm = false;
    public available = null;
    public filtered = null;
    public userSearchLength: number = null;

    constructor(
        public dialogRef: MatDialogRef<any>,
        public boardsService: BoardsService,
        private api: ApiBoardsService,
        private notiService: GlobalNotificationCenterService,
        private config: ConfigBoardsService,
        private tagsService: TagsService,
        private groupsService: GroupsService,
        private sharedService: SharedService,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
    }

    get title() {
        return !this.tagId ? 'Добавить группу' : 'Редактировать группу';
    }

    get isLearningOpen() {
        return this.sharedService.openTaskLearning$.value;
    }

    ngOnInit() {
        this.tagId = this.data && this.data.tagId ? this.data.tagId : null;
        this.boardId = this.data && this.data.boardId ? this.data.boardId : null;

        // noinspection JSAnnotator
        this.formGroup = new UntypedFormGroup({
            board_id: new UntypedFormControl(this.boardId ? this.boardId : this.boardsService.openedBoardId$.getValue()),
            name: new UntypedFormControl(this.tagsService.searchedName$.getValue(),
                [Validators.required, Validators.maxLength(100)]),
            color: new UntypedFormControl('_color1'),
            task_group: new UntypedFormControl(true)
        });

        this.tagsService.searchedName$.next('');

        this.selected = '_color1';

        this.getAvailable();

        if (this.tagId) {
            this.getTagById();
        } else {
            this.isLoaded = true;
        }

        this.formGroup.valueChanges
            .pipe(
                takeUntil(this.destroyed)
            )
            .subscribe(res => {
                this.userSearchLength = res.name.length;
                this.filtered = this._filter(this.formGroup.controls['name'].value);
            });

        this.sharedService.learningBoardName$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res && res === 'Новая группа задач') {
                    this.formGroup.controls['name'].setValue(res);
                }
            });
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        if (!this.available) {
            return [];
        }
        return this.available.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    public select(option) {
        this.selected = option.color;
        this.formGroup.controls['color'].setValue(this.selected);
    }

    getAvailable() {
        this.api.getAvailableTags()
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.available = res;
                this.filtered = this._filter(this.formGroup.controls['name'].value);
            }, (err) => {
                this.notiService.handleFullError(err);
            });
    }

    getTagById() {
        this.api.getTagById(this.tagId)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.formGroup.controls['name'].setValue(res.name);
                this.formGroup.controls['color'].setValue(res.color);
                this.selected = res.color;
                this.isLoaded = true;
            }, (err) => {
                this.notiService.handleFullError(err);
                this.dialogRef.close();
            });
    }

    submit() {
        const name = this.formGroup.controls['name'].value.trim();
        this.formGroup.controls['name'].setValue(name);

        if (this.formGroup.controls['name'].errors?.maxlength) {
            this.notiService.handleError(this.config.FORM_INVALID_100);
            return false;
        }

        if (this.formGroup.valid) {
            this.isButtonsDisabled = true;
            const data = {tag: this.formGroup.getRawValue()};

            if (this.tagId) {
                this.update(data);
            } else {
                this.create(data);
            }
        } else {
            this.notiService.handleError(this.config.FORM_INVALID);
        }
    }

    create(data) {
        this.api.createTag(data)
            .pipe(takeUntil(this.destroyed))
            .subscribe(tag => {
                this.tagsService.onTagCreate$.next(tag);
                this.dialogRef.close(tag);
            }, err => {
                this.notiService.handleFullError(err);
            });
    }

    update(data) {
        this.api.updateTag(data, this.tagId)
            .pipe(takeUntil(this.destroyed))
            .subscribe(tag => {
                this.groupsService.onGroupUpdate$.next(tag);
                this.dialogRef.close();
            }, err => {
                this.notiService.handleFullError(err);
            });
    }

    onDeleteClick() {
        this.isDeleteConfirm = true;
    }

    delete() {
        this.isDeleteConfirm = false;
        this.isLoaded = false;
        this.api.deleteTag(this.tagId)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.groupsService.onGroupRemove$.next(this.tagId);
                this.close();
            }, err => {
                this.isLoaded = true;
                this.notiService.handleFullError(err);
            });
    }

    checkSearchLength() {
        return !(this.userSearchLength > 1);
    }

    close() {
        this.dialogRef.close();
    }

    colorChange(evt) {
        this.selected = evt.target.value;
        this.formGroup.controls['color'].setValue(this.selected);
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

}
