import {Injectable} from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {NotifierService} from 'gramli-angular-notifier';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../services/auth.service';
import {NAME_REGEXP} from '../../../utils/regexps';

@Injectable({
  providedIn: 'root'
})
export class ConfigPhonebookService extends ConfigService {
    constructor(
        notifierService: NotifierService,
        authService: AuthService
    ) {
        super(notifierService);
    }

    readonly users = environment.usersUrl;
    readonly archiveUsers = environment.archiveUsers;
    readonly subdivisions = environment.phonebookSubdivisions;
    readonly PHONEEBOOK_URL = 'apps/phonebook/';
    readonly PHONEBOOK_USERS_URL = this.PHONEEBOOK_URL + 'users/';
    readonly ABOUT_ME = 'cabinet/about-me';
    readonly ABOUT_COMPANY = 'cabinet-company/about-company';
    readonly MATRIX_CABINET = 'cabinet/matrix/self-matrix';
    readonly COMPANY_URL = this.PHONEEBOOK_URL + 'company/';
    readonly FUNCTIONS_URL = this.COMPANY_URL + 'functions/';
    readonly BOARDS_URL = '/apps/boards';
    readonly CALENDAR_URL = '/apps/calendar';
    readonly DASHBOARD_URL = '/apps/dashboard';
    readonly SUBDIVISIONS_URL = this.COMPANY_URL + 'subdivisions/';
    readonly POSITIONS_URL = this.COMPANY_URL + 'positions/';
    readonly USERS_URL = this.PHONEBOOK_USERS_URL + 'info/';
    readonly NEW_USER_URL = this.USERS_URL + 'new-user/';
    readonly NEW_USERS_URL = this.USERS_URL + 'new-users/';
    readonly USER_URL = this.USERS_URL + 'user/';
    readonly FORM_INVALID = 'Все обязательные поля должны быть заполнены';
    readonly FORM_INVALID_BAD = 'Поля содержат недопустимые символы';
    readonly SUBDIVISION_NAME_INVALID = 'Подразделение с таким названием уже существует';
    readonly POSITION_NAME_INVALID = 'Должность с таким названием уже существует';
    readonly NAME_REGEX = NAME_REGEXP;
    readonly MOTIVATIONS_URL = this.PHONEBOOK_USERS_URL + 'motivations/';
}

export enum PhonebookViews {
    LIST = 'LIST',
    BLOCK = 'BLOCK',
}
