import { AcademyConfigService } from './academy-config.service';
import { AcademyService } from './academy.service';
import { AcademySettings } from '../models/settings';
import { ApiService } from '../../../services/api.service';
import { AuthService } from '../../../services/auth.service';
import { catchError, map } from 'rxjs/operators';
import { DataDepartmentResponse } from '../models/data-departments';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IsActive } from '../models/active';
import { JSON } from 'ta-json';
import { NotifierService } from 'gramli-angular-notifier';
import { of } from 'rxjs';
import { Rights } from '../../../models/user';
import { ShortUserAcademy } from '../models/user-academy';
import { Subdivision } from '../../phonebook/models/subdivision';
import { TestAcademyClass } from '../models/test-academy';
import { TopicData } from '../models/topic';
import { UserInfoService } from '../../../services/user-info.service';
import { UsersResponse } from '../models/users-response';
import { TestResponse } from '../models/test-response';


export interface Topic {
    id: number;
    title: string;
}

export interface DivisionsUser {
    id: number;
    user_id: number;
    department_id: number;
    test_id: number;
    is_active: boolean;
    created_at: string; // Можно использовать Date, если хотите сразу парсить в дату
    priority: number;
    test_type: string; // Если test_type может быть строго ограничен несколькими значениями, используйте string union type
    is_completed: boolean;
    completed_at: string | null; // Если данные могут быть null
    evaluation: string | null;
    next_question_at: string | null;
    next_test_repetition_at: string | null;
    is_started: boolean;
    is_viewed: boolean;
    passing_rate: number;
    topic: Topic;
    test_title: string;
    test_description: string;
    questions_count: number;
    assignment_id?: number;
}

@Injectable({
    providedIn: 'root',
})
export class ApiAcademyService extends ApiService {
    constructor(
        readonly http: HttpClient,
        public _config: AcademyConfigService,
        public _auth: AuthService,
        public userInfo: UserInfoService,
        public notifierService: NotifierService,
        private academyService: AcademyService,
    ) {
        super(http, _config, userInfo, _auth, notifierService);
    }

    /** Получить вопросы из центра для */
    getQuestionsFromCenter() {
        return this.http.get(
            `${this._config.platformLearning}/question-hub/my-questions`,
            this._auth.authorize(),
        );
    }

    /** Текущий пользователь */
    getCurrentUser() {
        return this.http.get<ShortUserAcademy>(
            `${this._config.userAcademy}`,
            this._auth.authorize(),
        );
    }

    /** Получение тем */
    getTopics() {
        return this.http.get<TopicData[]>(
            `${this._config.topics}`,
            this._auth.authorize(),
        );
    }

    /** Создание тем */
    createTopics(params) {
        return this.http.post(
            `${this._config.topics}`,
            params,
            this._auth.authorize(),
        );
    }

    /** Обновление темы */
    updateTopics(topic_id, params) {
        return this.http.put(
            `${this._config.topics}${topic_id}`,
            params,
            this._auth.authorize(),
        );
    }

    /** Удаление темы */
    deleteTopic(topic_id) {
        return this.http.delete(
            `${this._config.topics}${topic_id}`,
            this._auth.authorize(),
        );
    }

    updateSharing(testId, isShared) {
        return this.http.put(
            `${this._config.tests + testId}/sharing`,
            {
                is_shared: isShared,
            },
            this._auth.authorize(),
        );
    }

    /** Получение настроек */
    getSetting() {
        return this.http
            .get<AcademySettings>(
                `${this._config.setting}`,
                this._auth.authorize(),
            )
            .pipe(catchError(() => of(null)));
    }

    /** Создание настроек */
    createSetting(params) {
        return this.http.post(
            `${this._config.setting}`,
            params,
            this._auth.authorize(),
        );
    }

    /** Обновление настроек */
    updateSetting(params) {
        return this.http.put(
            `${this._config.setting}`,
            params,
            this._auth.authorize(),
        );
    }

    /** Получение тестов */
    getTests() {
        return this.http.get<TestAcademyClass[]>(
            `${this._config.tests}`,
            this._auth.authorize(),
        );
    }

    /** Получение теста */
    getTest(test_id: number) {
        return this.http.get<TestResponse>(
            `${this._config.tests}${test_id}`,
            this._auth.authorize(),
        );
    }

    /** Получение назначенных тестов */
    getAssignmentTests() {
        return this.http
            .get(
                `${this._config.assignments}my-learning`,
                this._auth.authorize(),
            )
            .pipe(
                map((assignments: any) => {
                    this.academyService.setAssignmentsCounter(assignments);
                    return assignments;
                }),
            );
    }

    updateAssignment(assignmentId: number, updateData: { priority?: number; test_type?: string }) {
        return this.http.put(
            `${this._config.assignments}/${assignmentId}`,
            updateData,
            this._auth.authorize(),
        );
    }

    /**Получение информации о назначенном тесте*/
    getAssignmentTestInfo(test_id: number) {
        return this.http.get(
            `${this._config.assignments + test_id}/statistics`,
            this._auth.authorize(),
        );
    }

    /** Получение списка пользователей для страницы сотрудников */
    getAssignmentsEmployeesPage(user_id) {
        return this.http.get(
            `${this._config.assignments}/users/${user_id}`,
            this._auth.authorize(),
        );
    }

    /** Получение статистики для страницы сотрудников */
    getStatisticsEmployeesPage() {
        return this.http.get(
            `${this._config.statistics}employees`,
            this._auth.authorize(),
        );
    }

    /** Удаление пользователя */
    deleteAssignment(assignment_id) {
        return this.http.delete(
            `${this._config.assignments}${assignment_id}`,
            this._auth.authorize(),
        );
    }

    /** Удаление массива пользователей */
    deleteAssignments(testAssignments) {
        return this.http.delete(
            `${this._config.assignments}${testAssignments}`,
            this._auth.authorize(),
        );
    }

    /** Переназначить тест для отдела */
    reassignTestForDepartment(departmentId, body) {
        return this.http.post(
            `${this._config.tests}departments/${departmentId}/reassign`,
            body,
            this._auth.authorize(),
        );
    }

    /** Переназначить тест для отдела */
    reassignForAllDepartment(body) {
        return this.http.post(
            `${this._config.tests}departments/reassign-departments`,
            body,
            this._auth.authorize(),
        );
    }

    /** Переназначить тест для сотрудника */
    reassignTestForEmployee(assignment_id) {
        return this.http.post(
            `${this._config.assignments}${assignment_id}/reassign`,
            {},
            this._auth.authorize(),
        );
    }

    /** Получить тесты по сотруднику */
    getUserAssignments(user_id: string | number) {
        return this.http.get<DivisionsUser[]>(
            `${this._config.assignments}users/${user_id}`,
            this._auth.authorize(),
        );
    }

    /** Удалить тест для отдела */
    deleteTestForDepartment(testId, departmentId) {
        return this.http.post(
            `${this._config.tests}${testId}/departments/${departmentId}/reassign`,
            this._auth.authorize(),
        );
    }

    getNextQuestion(questionId: number) {
        return this.http.get(
            `${this._config.assignments + questionId}/next-question`,
            this._auth.authorize(),
        );
    }

    getAssignment(assignmentId) {
        return this.http.get(
            `${this._config.assignments + assignmentId}`,
            this._auth.authorize(),
        );
    }

    /** Создание теста */
    createTests(params) {
        return this.http.post(
            `${this._config.tests}`,
            params,
            this._auth.authorize(),
        );
    }

    /** Обновление теста */
    updateTest(test_id: number, params) {
        return this.http.put(
            `${this._config.tests}${test_id}`,
            params,
            this._auth.authorize(),
        );
    }

    /** Удаление теста */
    deleteTests(test_id) {
        return this.http.delete(
            `${this._config.tests}${test_id}`,
            this._auth.authorize(),
        );
    }

    /** Ответ на вопрос в тесте */
    answerOnQuestion(assignmentId, userChoices) {
        return this.http.post(
            `${this._config.answers}`,
            {
                is_rating: false,
                user_question_assignment_id: assignmentId,
                user_test_choices: userChoices,
            },
            this._auth.authorize(),
        );
    }

    /** Активация теста */
    updateTestActivation(test_id: number, params: IsActive) {
        return this.http.put(
            `${this._config.tests}${test_id}/activation`,
            params,
            this._auth.authorize(),
        );
    }

    /** Получение пользователей */
    getAllUsers() {
        return this.http.get<UsersResponse>(
            `${this._config.users}`,
            this._auth.authorize(),
        );
    }

    /** Получение пользователей */
    getSubdivisions(isArchive = false) {
        return this.http
            .get(
                `${this.config.structAdmin}?is_archive=${isArchive}`,
                this.auth.authorize(),
            )
            .pipe(
                map((res) => ({
                    subdivision: JSON.deserialize<Subdivision[]>(
                        res['data'],
                        Subdivision,
                    ),
                    rights: JSON.deserialize<Rights>(res['rights'], Rights),
                })),
            );
    }

    deleteAssignedTest(testId: number, departmentId: number) {
        return this.http.delete(
            `${this._config.tests + testId}/departments/${departmentId}`,
            this._auth.authorize(),
        );
    }

    reassignTest(departmentId, body) {
        return this.http.post(
            `${this._config.tests}departments/${departmentId}/reassign`,
            body,
            this._auth.authorize(),
        );
    }

    reassignUserTest(assignmentId: number) {
        return this.http.post(
            `${this._config.assignments + assignmentId}/reassign`,
            {},
            this._auth.authorize(),
        );
    }

    /** Создание теста */
    createAssignments(params) {
        return this.http.post(
            `${this._config.assignments}`,
            params,
            this._auth.authorize(),
        );
    }

    getQuestionsAnswersStatistics(id: number) {
        return this.http.get(
            `${this._config.questions + id}/answers-statistics`,
            this._auth.authorize(),
        );
    }

    getQuestion(id: number) {
        return this.http.get(
            `${this._config.questions}${id}`,
            this._auth.authorize(),
        );
    }

    userTestAswers(answer) {
        return this.http.post(
            `${this._config.answers}`,
            answer,
            this._auth.authorize(),
        );
    }

    /** Получение списка сотрудников */
    getEmployeesStatistics() {
        return this.http.get(
            `${this._config.statistics}employees`,
            this._auth.authorize(),
        );
    }

    createQuestion(questionData) {
        return this.http.post(
            `${this._config.questions}`,
            questionData,
            this._auth.authorize(),
        );
    }

    updateQuestion(questionId, questionData) {
        return this.http.put(
            `${this._config.questions + questionId}`,
            questionData,
            this._auth.authorize(),
        );
    }

    updateActiveQuestionStatus(questionId, activeStatus) {
        return this.http.put(
            `${this._config.questions + questionId}/activation`,
            {
                is_active: activeStatus,
            },
            this._auth.authorize(),
        );
    }

    deleteQuestion(id: any) {
        return this.http.delete(
            this._config.questions + id,
            this._auth.authorize(),
        );
    }

    testDepartmentsAndCurrentUserRole()  {
        return this.http.get<DataDepartmentResponse>(
            'https://api.development.myradius.ru/struct/adm/?is_archive=false',
            this.auth.authorize(),
        );
    }

    getUserStatistics() {
        return this.http.get(
            `${this._config.statistics}my-statistics`,
            this._auth.authorize(),
        );
    }

    getUserTestAssignments(userId: number) {
        return this.http.get(
            `${this._config.assignments}users/${userId}`,
            this._auth.authorize(),
        );
    }

    getUserRating(startDate, endDate) {
        const params = new HttpParams()
            .set('start_date', startDate)
            .set('end_date', endDate);
        return this.http
            .get(`${this._config.rating}`, {
                params,
                ...this._auth.authorize(),
            })
            .pipe(catchError((error) => of(null)));
    }

    getSubdivisionInfo(id: number) {
        return this.http
            .get(
                `${this._config.tests}departments/${id}`,
                this._auth.authorize(),
            )
            .pipe(catchError(() => of(null)));
    }

    deleteTestAssignment(testId, departmentId) {
        return this.http.delete(
            `${this._config.tests + testId}/departments/${departmentId}`,
            this._auth.authorize(),
        );
    }

    /**
     * Получение рейтинга пользователей за
     * определенный промежуток времени
     * */
    getUsersRating(params) {
        const options = {
            ...this._auth.authorize(),
            params,
        };

        return this.http.get(`${this._config.rating}`, options);
    }

    /**
     * Получение рейтинга 1 пользователя по id за
     * определенный промежуток времени
     * */
    getUsersRatingStatistics(params, id) {
        const options = {
            ...this._auth.authorize(),
            params,
        };
        return this.http.get(`${this._config.rating + id}`, options);
    }

    /** Назначение теста на всех сотрудников повторно */
    reassignDepartments(
        testId: number,
        departmentsData: Array<{ departmentId: number; userIds: number[] }>,
    ) {
        const params = {
            test_id: testId,
            departments_data: departmentsData.map((item) => ({
                department_id: item.departmentId,
                users_ids: item.userIds,
            })),
        };
        return this.http.post(
            `${this._config.reassignDepartments}`,
            params,
            this._auth.authorize(),
        );
    }
}
