import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from '../../services/auth.service';


/**
 * Route guard that ensures that there is a user currently authenticated in the Platform.
 */
@Injectable()
export class IsAuthenticatedGuard  {

    constructor(
        private readonly auth: AuthService,
        readonly router: Router
    ) {
    }

    /** Validates authentication for a specific route. */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.auth.isSignedIn) {
            this.auth.setAuth(null);
            this.router.navigate(['/hello/features'], { queryParams: { returnUrl: state.url }});
            return false;
        } else {
            return true;
        }
    }

    /** Validates authentication for children of a route. */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(childRoute, state);
    }

}
